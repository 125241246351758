import { useMutation, useQueryClient } from 'react-query';
import { API } from 'api';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';

const deleteInsightCategoryData = (id: string) => {
  return API.newDelete(`api/admin/promotionInsightCategory/${id}`);
};

export const useDeleteInsightCategoryData = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(deleteInsightCategoryData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.deletedSuccessful',
        })
      );

      queryClient.invalidateQueries(['promotionInsightCategories']);
    },
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';
import { AppInfoType } from 'common';

export interface IBankConfigurationListData {
  data: {
    data: {
      duitNow: any;
    };
  };
}

const getBankConfigurationListData = () => {
  return API.newGet(`/api/admin/app/info/${AppInfoType.BANK_INFO_LIST}`, {});
};

export const useBankConfigurationListData = () => {
  return useQuery(['bankConfigurations'], (): any => getBankConfigurationListData(), {
    select: (response: IBankConfigurationListData['data']) => {
      return response.data.duitNow;
    },
  });
};

import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { API } from 'api';
import { MerchantRequestStatus } from 'common';
import { toastPush } from 'modules';

export interface IUpdateMerchantRequestData {
  mrid: string;
  status: string;
  reason?: string;
}

const updateMerchantRequestData = (payload: IUpdateMerchantRequestData) => {
  return API.put()(`/api/admin/merchantRequest/${payload.mrid}`, payload);
};

export const useUpdateMerchantRequestData = () => {
  const dispatch = useDispatch();

  return useMutation(updateMerchantRequestData, {
    onSuccess: (_data, payload) => {
      if (payload.status === MerchantRequestStatus.APPROVE) {
        dispatch(
          toastPush({
            code: 200,
            type: 'success',
            title: 'SUCCESS',
            body: 'toastPush.successApproveMerchantRequest',
          })
        );
      } else {
        dispatch(
          toastPush({
            code: 200,
            type: 'success',
            title: 'SUCCESS',
            body: 'toastPush.successRejectedtMerchantRequest',
          })
        );
      }
    },
  });
};

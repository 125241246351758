/* eslint-disable @typescript-eslint/no-explicit-any */
import { API } from 'api';
import { checkAccessPermission, CountryCode, PermissionId } from 'common';
import { RootState, selectCurrentCountry } from 'modules';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

export interface IProductListData {
  data: {
    data: { id: string; name: string }[];
  };
}

const getProductListData = () => {
  return API.get()('/api/admin/product/list');
};

export const useProductListData = () => {
  const countryCode = useSelector((state: RootState) => selectCurrentCountry(state));

  const { permissions } = useSelector((userState: RootState) => userState.user);
  return useQuery(['products'], (): any => getProductListData(), {
    staleTime: Infinity,
    enabled: checkAccessPermission(permissions, [PermissionId.MERCHANT_LIST_READ]) && countryCode === CountryCode.MY,
    select: (response: IProductListData['data']) => {
      return response.data;
    },
  });
};

enum SettlementStatus {
  PENDING = 'pending',
  SENT = 'sent',
  REJECTED = 'rejected',
  CANCELLED = 'cancelled',
}

const settlementStatusSelection: { label: string; value: SettlementStatus }[] = [
  { label: 'status.pending', value: SettlementStatus.PENDING },
  { label: 'status.sent', value: SettlementStatus.SENT },
  { label: 'status.rejected', value: SettlementStatus.REJECTED },
  { label: 'status.cancelled', value: SettlementStatus.CANCELLED },
];

export { SettlementStatus, settlementStatusSelection };

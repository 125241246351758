/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { API } from 'api';
import { getLatestQuery } from 'common';
import { toastPush } from 'modules';

export interface IUpdateFeeAndChargeData {
  payload: {
    level: 'product' | 'outlet' | 'employer';
    eid?: string;
    outletId?: string;
    applyAll: boolean;
    fees: number;
  };
  data: {
    data: any;
    count: number;
  };
}

const updateEmployerOutletData = (payload: IUpdateFeeAndChargeData['payload']) => {
  return API.post()('/api/admin/fees/update', payload);
};

export const useUpdateFeeAndCharge = () => {
  const queryClient = useQueryClient();
  const queryArr = queryClient.getQueriesData('feeAndChargeList');
  const res = getLatestQuery(queryArr, queryClient);
  const dispatch = useDispatch();

  return useMutation(updateEmployerOutletData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.updatedSuccessfulReflectNextDay',
        })
      );
      return queryClient.invalidateQueries('feeAndChargeList', res.queryParams);
    },
  });
};

import { useQuery } from 'react-query';
import { API } from '../../../api';

export interface IEmployeeAttendanceUploadFileNameData {
  data: {
    data: { fileName: string }[];
  };
}

const employeeAttendanceUploadFileName = () => {
  return API.newGet(`/api/admin/employee/attendance/upload/filename`);
};

export const useEmployeeAttendanceUploadFileNameData = () => {
  return useQuery(['employeeAttendanceUploadFileName'], () => employeeAttendanceUploadFileName(), {
    select: (response: IEmployeeAttendanceUploadFileNameData['data']) => {
      return response.data;
    },
  });
};

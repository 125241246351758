import { useQuery } from 'react-query';
import { API } from 'api';
import { IPromotionResponse } from './sharedInterface';

const getPromotionInfo = (id: string) => {
  return API.newGet(`/api/admin/promotion/${id}`);
};

export const usePromotionInfoData = (id: string) => {
  return useQuery(['promotion', id], (): any => getPromotionInfo(id), {
    refetchOnWindowFocus: false,
    select: (response: IPromotionResponse) => {
      return response.data;
    },
  });
};

import React from 'react';
import { CountryCode, PermissionId } from '../common';

export * from './ProtectedRoute';
export * from './PublicRoute';

const Page403 = React.lazy(() => import('../views/Errors/Page403'));
// Dashboard Module
const Dashboard = React.lazy(() => import('../views/Dashboard'));
const WorkStatusDetail = React.lazy(() => import('../views/WorkStatus/WorkStatusDetail'));

// Employer Management Module
// Employer Module
const EmployerAdd = React.lazy(() => import('../views/EmployerManagement/Employer/AddEmployer'));
const EmployerEdit = React.lazy(() => import('../views/EmployerManagement/Employer/EditEmployer'));
const EmployerList = React.lazy(() => import('../views/EmployerManagement/Employer/EmployerList'));
const EmployerView = React.lazy(() => import('../views/EmployerManagement/Employer/ViewEmployer'));
// Employer Request Module
// const EmployerRequestEdit = React.lazy(() => import("../views/EmployerManagement/EmployerRequest/EditEmployerRequest"))
const EmployerRequestList = React.lazy(() => import('../views/EmployerManagement/EmployerRequest/EmployerRequestList'));
const EmployerRequestView = React.lazy(() => import('../views/EmployerManagement/EmployerRequest/ViewEmployerRequest'));
// Outlet Management Module
const EmployerOutletList = React.lazy(() => import('../views/EmployerManagement/EmployerOutlet/EmployerOutletList'));
const ViewEmployerOutletDetail = React.lazy(
  () => import('../views/EmployerManagement/EmployerOutlet/ViewEmployerOutletDetail')
);
const AddEmployerOutlet = React.lazy(() => import('../views/EmployerManagement/EmployerOutlet/AddEmployerOutlet'));
const EditEmployerOutlet = React.lazy(() => import('../views/EmployerManagement/EmployerOutlet/EditEmployerOutlet'));

// Employee Management Module
// Employee Module
const EmployeeEdit = React.lazy(() => import('../views/EmployeeManagement/Employee/EditEmployee'));
const EmployeeList = React.lazy(() => import('../views/EmployeeManagement/Employee/EmployeeList'));
const EmployeeDetails = React.lazy(() => import('../views/EmployeeManagement/Employee/Details'));
// New Employee Request Module
const NewEmployeeRequestEdit = React.lazy(
  () => import('../views/EmployeeManagement/NewEmployeeRequest/EditNewEmployeeRequest')
);
const NewEmployeeRequestList = React.lazy(
  () => import('../views/EmployeeManagement/NewEmployeeRequest/NewEmployeeRequestList')
);
const NewEmployeeRequestView = React.lazy(
  () => import('../views/EmployeeManagement/NewEmployeeRequest/ViewNewEmployeeRequest')
);
// Employee File Upload module
const EmployeeFileUpload = React.lazy(() => import('../views/EmployeeManagement/FileUpload'));
// Employee Bulk Update module
const EmployeeBulkImport = React.lazy(() => import('../views/EmployeeManagement/EmployeeBulkImport'));
const EmployeeBulkImportRequest = React.lazy(() => import('../views/EmployeeManagement/EmployeeBulkImportRequest'));
const EmployeeBulkImportHistory = React.lazy(() => import('../views/EmployeeManagement/BulkImportHistory'));
// Employee Bulk Import Commission module
const EmployeeBulkImportCommission = React.lazy(
  () => import('../views/EmployeeManagement/EmployeeBulkImportCommission')
);
// Employee eKYC Applications
const EmployeeKycApplicationList = React.lazy(
  () => import('../views/EmployeeManagement/EmployeeKycApplication/EmployeeKycApplicationList')
);
const ViewEmployeeKycApplication = React.lazy(
  () => import('../views/EmployeeManagement/EmployeeKycApplication/ViewEmployeeKycApplication')
);

const EmploymentProfileList = React.lazy(
  () => import('../views/EmployeeManagement/EmploymentProfile/EmploymentProfileList')
);

const EmploymentProfileDetail = React.lazy(
  () => import('../views/EmployeeManagement/EmploymentProfile/EmploymentProfileDetail')
);
const EmploymentProfileEdit = React.lazy(
  () => import('../views/EmployeeManagement/EmploymentProfile/EditEmploymentProfile')
);
// Transactions
const TransactionList = React.lazy(() => import('../views/EmployeeManagement/Transactions/TransactionList'));
const TransactionView = React.lazy(() => import('../views/EmployeeManagement/Transactions/TransactionView'));
// Employee Attendance Management
const EmployeeAttendanceUpload = React.lazy(
  () => import('../views/EmployeeManagement/EmployeeAttendanceUpload/EmployeeAttendanceUploadForm')
);

// MERCHANT MANAGEMENT MODULE
// Merchant Module
const MerchantAdd = React.lazy(() => import('../views/MerchantManagement/Merchant/AddMerchant'));
const MerchantList = React.lazy(() => import('../views/MerchantManagement/Merchant/MerchantList'));
const MerchantEdit = React.lazy(() => import('../views/MerchantManagement/Merchant/EditMerchant'));
const MerchantView = React.lazy(() => import('../views/MerchantManagement/Merchant/ViewMerchant'));
// Merchant Request Module
const MerchantRequestList = React.lazy(() => import('../views/MerchantManagement/MerchantRequest/MerchantRequestList'));
const MerchantRequestView = React.lazy(() => import('../views/MerchantManagement/MerchantRequest/ViewMerchantRequest'));

// SETTINGS
// Bank Configurations
const BankConfigurationList = React.lazy(() => import('../views/Settings/BankConfiguration/BankConfigurationList'));
// Fee & Charges Configuration
const SettingConfiguration = React.lazy(() => import('../views/Settings/SettingConfiguration'));
// Setting Configurations
const FeesAndCharges = React.lazy(() => import('../views/Settings/FeesAndCharges/FeesAndChargesList'));
// User Access
const UserAccessList = React.lazy(() => import('../views/Settings/UserAccess/UserAccessList'));
// const AddUserAccess = React.lazy(() => import("../views/Settings/UserAccess/AddUserAccess"));
// const EditUserAccess = React.lazy(() => import("../views/Settings/UserAccess/EditUserAccess"));
// const ViewUserAccess = React.lazy(() => import("../views/Settings/UserAccess/ViewUserAccess"));
// Role Management
const EditRoleManagement = React.lazy(() => import('../views/Settings/RoleManagement/EditRoleManagement'));
const ViewRoleManagement = React.lazy(() => import('../views/Settings/RoleManagement/ViewRoleManagement'));
const RoleManagementList = React.lazy(() => import('../views/Settings/RoleManagement/RoleManagementList'));
const AddRoleManagement = React.lazy(() => import('../views/Settings/RoleManagement/AddRoleManagement'));
// Global Cutoff
const GlobalCutOffList = React.lazy(() => import('../views/Settings/GlobalCutoff/GlobalCutoffList'));
const ViewGlobalCutOff = React.lazy(() => import('../views/Settings/GlobalCutoff/ViewGlobalCutOff'));
const EditGlobalCutOff = React.lazy(() => import('../views/Settings/GlobalCutoff/EditGlobalCutOff'));
const AddGlobalCutOff = React.lazy(() => import('../views/Settings/GlobalCutoff/AddGlobalCutOff'));
// Promotion Module
const BannerInsightsList = React.lazy(() => import('../views/Promotion/PromotionItems/PromotionList'));
const ViewBannerInsightDetail = React.lazy(() => import('../views/Promotion/PromotionItems/ViewPromotionDetail'));
const AddBanner = React.lazy(() => import('../views/Promotion/PromotionItems/Banner/Add'));
const EditBanner = React.lazy(() => import('../views/Promotion/PromotionItems/Banner/Edit'));
const EditInsight = React.lazy(() => import('../views/Promotion/PromotionItems/Insights/Edit'));
const AddInsight = React.lazy(() => import('../views/Promotion/PromotionItems/Insights/Add'));
const AddTicker = React.lazy(() => import('../views/Promotion/PromotionItems/Ticker/Add'));
const EditTicker = React.lazy(() => import('../views/Promotion/PromotionItems/Ticker/Edit'));
const AppHomeBackground = React.lazy(() => import('../views/Promotion/AppHomeBackground'));
const PollList = React.lazy(() => import('../views/Promotion/Poll/PollList'));
const ViewPoll = React.lazy(() => import('../views/Promotion/Poll/ViewPoll'));
const CreatePoll = React.lazy(() => import('../views/Promotion/Poll/CreatePoll'));
const EditPoll = React.lazy(() => import('../views/Promotion/Poll/EditPoll'));

// InsightCategories
const InsightCategoryList = React.lazy(() => import('../views/Promotion/InsightCategories/InsightCategoryList'));

// Report Module
const HLBBReport = React.lazy(() => import('../views/Reports/HLBBReport'));
const PayoutStatus = React.lazy(() => import('../views/Reports/PayoutStatus'));
const PayoutStatusDetail = React.lazy(() => import('../views/Reports/PayoutStatus/Details'));
const ReconciliationReport = React.lazy(() => import('../views/Reports/Reconciliation'));
const LapseRepaymentReport = React.lazy(() => import('../views/Reports/LapseRepayment'));
const EmployeeInfoUpdate = React.lazy(() => import('../views/Reports/EmployeeInfoUpdate'));
const ExceptionalFile = React.lazy(() => import('../views/Reports/ExceptionalFile'));
const EmployerReport = React.lazy(() => import('../views/Reports/EmployerReport'));
const PaymentTransaction = React.lazy(() => import('../views/Reports/PaymentTransaction'));
const EmployerDeductionFiles = React.lazy(() => import('../views/Reports/EmployerDeductionFiles'));

// Finance Module
const IbgEnquiry = React.lazy(() => import('../views/Finance/IBGEnquiry'));
const Settlement = React.lazy(() => import('../views/Finance/Settlement'));
const PaymentToBankList = React.lazy(() => import('../views/Finance/PaymentToBank/PaymentToBankList'));
const PaymentToBankView = React.lazy(() => import('../views/Finance/PaymentToBank/PaymentToBankView'));
const StatementOfAccountView = React.lazy(() => import('../views/Finance/StatementOfAccount'));
const SaasFeeConfigView = React.lazy(() => import('../views/Finance/SaasFeeConfig'));

// HRMS Module
const HRMSPartnerList = React.lazy(() => import('../views/HRMS/Partner/PartnerList'));
const HRMSPartnerDetails = React.lazy(() => import('../views/HRMS/Partner/PartnerDetails'));
const HRMSProcessList = React.lazy(() => import('../views/HRMS/Process/ProcessList'));
const HRMSProcessDetails = React.lazy(() => import('../views/HRMS/Process/ProcessDetails'));
const HRMSSalaryDeductionRequestList = React.lazy(
  () => import('../views/HRMS/SalaryDeductionRequest/SalaryDeductionRequestList')
);
const SalaryDeductionRequestDetails = React.lazy(
  () => import('../views/HRMS/SalaryDeductionRequest/SalaryDeductionRequestDetails')
);

// Deduction File Configuration Module
const ViewDeductionFileConfigurationList = React.lazy(
  () => import('../views/Settings/DeductionFileConfiguration/ViewDeductionFileConfigurationList')
);
const ViewDeductionFileConfiguration = React.lazy(
  () => import('../views/Settings/DeductionFileConfiguration/ViewDeductionFileConfiguration')
);
const AddDeductionFileConfiguration = React.lazy(
  () => import('../views/Settings/DeductionFileConfiguration/AddDeductionFileConfiguration')
);
const EditDeductionFileConfiguration = React.lazy(
  () => import('../views/Settings/DeductionFileConfiguration/EditDeductionFileConfiguration')
);

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/403', name: '403', component: Page403 },
  // Dashboard Module
  {
    path: '/dashboard/work-status/:id',
    name: 'WorkStatusDetail',
    component: WorkStatusDetail,
    permission: [PermissionId.DASHBOARD_STATISTIC_READ],
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    permission: [PermissionId.DASHBOARD_STATISTIC_READ],
    blacklistCountry: [CountryCode.HK],
  },
  // Employer Management Module
  // Employer Module
  {
    path: '/employer/list',
    subMenu: 'employer',
    name: 'EmployerList',
    component: EmployerList,
    exact: true,
    permission: [PermissionId.EMPLOYER_LIST_READ],
  },
  {
    path: '/employer/view/:eid',
    name: 'EmployerView',
    component: EmployerView,
    permission: [PermissionId.EMPLOYER_LIST_READ],
  },
  {
    path: '/employer/add',
    name: 'EmployerAdd',
    component: EmployerAdd,
    permission: [PermissionId.EMPLOYER_REQUEST_WRITE],
  },
  {
    path: '/employer/edit/:eid',
    name: 'EmployerEdit',
    component: EmployerEdit,
    permission: [PermissionId.EMPLOYER_LIST_UPDATE],
  },
  // Employer Request Module
  // { path: "/employer/request/edit/:erid", name: "EmployerRequestEdit", component: EmployerRequestEdit, permission: [PermissionId.EMPLOYER_REQUEST_UPDATE] },
  {
    path: '/employer/request/list',
    name: 'EmployerRequestList',
    component: EmployerRequestList,
    permission: [PermissionId.EMPLOYER_REQUEST_READ],
  },
  {
    path: '/employer/request/view/:erid',
    name: 'EmployerRequestView',
    component: EmployerRequestView,
    permission: [PermissionId.EMPLOYER_REQUEST_READ],
  },
  // Outlet Management Module
  {
    path: '/employer/outlet/list',
    name: 'EmployerOutletList',
    component: EmployerOutletList,
    permission: [PermissionId.EMPLOYER_OUTLET_READ],
  },
  {
    path: '/employer/outlet/add',
    name: 'AddEmployerOutlet',
    component: AddEmployerOutlet,
    permission: [PermissionId.EMPLOYER_OUTLET_WRITE],
  },
  {
    path: '/employer/outlet/edit/:id',
    name: 'EditEmployerOutlet',
    component: EditEmployerOutlet,
    permission: [PermissionId.EMPLOYER_OUTLET_UPDATE],
  },
  {
    path: '/employer/outlet/:id',
    name: 'ViewEmployerOutletDetail',
    component: ViewEmployerOutletDetail,
    permission: [PermissionId.EMPLOYER_OUTLET_READ],
  },

  // Employee Management Module
  // New Employee Request Module
  {
    path: '/employee/:uid/new-employee-request/edit/:ebid/:eid',
    name: 'NewEmployeeRequestEdit',
    component: NewEmployeeRequestEdit,
    permission: [PermissionId.NEW_EMPLOYEE_REQUEST_UPDATE],
  },
  {
    path: '/employee/:uid/new-employee-request/:ebid/:eid',
    name: 'NewEmployeeRequestView',
    component: NewEmployeeRequestView,
    permission: [PermissionId.NEW_EMPLOYEE_REQUEST_READ],
  },
  {
    path: '/employee/new-employee-request',
    name: 'NewEmployeeRequest',
    component: NewEmployeeRequestList,
    permission: [PermissionId.NEW_EMPLOYEE_REQUEST_READ],
  },
  // Employee Bulk Update Commission module
  {
    path: '/employee/bulk-import-commission',
    name: 'EmployeeBulkImportCommission',
    component: EmployeeBulkImportCommission,
    permission: [PermissionId.BULK_UPDATE_AGENT_READ],
  },
  {
    path: '/employee/file-upload',
    name: 'File Upload',
    component: EmployeeFileUpload,
    permission: [PermissionId.TEMP_FILE_UPLOAD_READ],
  },
  // Employee Bulk Update module
  {
    path: '/employee/bulk-import',
    name: 'EmployeeBulkImport',
    component: EmployeeBulkImport,
    permission: [PermissionId.BULK_UPDATE_READ],
  },
  {
    path: '/employee/bulk-import-request',
    name: 'EmployeeBulkImportRequest',
    component: EmployeeBulkImportRequest,
    permission: [PermissionId.BULK_UPDATE_REQUEST_READ],
  },
  {
    path: '/employee/bulk-import-history',
    name: 'EmployeeBulkImportHistory',
    component: EmployeeBulkImportHistory,
    permission: [PermissionId.BULK_IMPORT_HISTORY_READ],
  },
  // Employee eKYC Applications
  {
    path: '/employee/ekyc/:id',
    name: 'ViewEmployeeKycApplication',
    component: ViewEmployeeKycApplication,
    permission: [PermissionId.EKYC_APPLICATION_READ],
  },
  {
    path: '/employee/ekyc',
    name: 'EmployeeKycApplicationList',
    component: EmployeeKycApplicationList,
    permission: [PermissionId.EKYC_APPLICATION_READ],
  },
  // Transactions
  {
    path: '/employee/transaction/list',
    name: 'TransactionList',
    component: TransactionList,
    permission: [PermissionId.EMPLOYEE_TRANSACTION_READ],
  },
  {
    path: '/employee/transaction/:id',
    name: 'TransactionView',
    component: TransactionView,
    permission: [PermissionId.EMPLOYEE_TRANSACTION_READ],
  },
  // Employee Attendance Management Upload Module
  {
    path: '/employee/attendance-upload',
    name: 'EmployeeAttendanceUpload',
    component: EmployeeAttendanceUpload,
    permission: [PermissionId.EMPLOYEE_ATTENDANCE_UPLOAD_READ],
  },
  // employment_profiles
  {
    path: '/employee/employment-profiles/list',
    name: 'EmployentProfiles',
    component: EmploymentProfileList,
    exact: true,
    permission: [PermissionId.EMPLOYEE_EMPLOYMENT_PROFILE_READ],
  },
  {
    path: '/employee/employment-profiles/edit/:id/:eid',
    name: 'EmployentProfiles',
    component: EmploymentProfileEdit,
    permission: [PermissionId.EMPLOYEE_EMPLOYMENT_PROFILE_READ],
  },
  {
    path: '/employee/employment-profiles/:id/:eid',
    name: 'EmployentProfiles',
    component: EmploymentProfileDetail,
    permission: [PermissionId.EMPLOYEE_EMPLOYMENT_PROFILE_READ],
  },

  // Employee Module
  {
    path: '/employee/list',
    name: 'Employee',
    component: EmployeeList,
    exact: true,
    permission: [PermissionId.EMPLOYEE_LIST_READ],
  },
  // { path: "/employee/list", name: "EmployeeList", component: EmployeeList },
  {
    path: '/employee/edit/:id',
    name: 'EmployeeEdit',
    component: EmployeeEdit,
    permission: [PermissionId.EMPLOYEE_LIST_UPDATE],
  },
  {
    path: '/employee/:id',
    name: 'EmployeeDetail',
    component: EmployeeDetails,
    permission: [PermissionId.EMPLOYEE_LIST_READ],
  },
  {
    path: '/employee/',
    name: 'Employee',
    component: EmployeeList,
    exact: true,
    permission: [PermissionId.EMPLOYEE_LIST_READ],
  },
  // Merchant Module
  {
    path: '/merchant/add',
    name: 'MerchantAdd',
    component: MerchantAdd,
    permission: [PermissionId.MERCHANT_LIST_WRITE],
  },
  {
    path: '/merchant/list',
    name: 'MerchantList',
    component: MerchantList,
    permission: [PermissionId.MERCHANT_LIST_READ],
  },
  {
    path: '/merchant/edit/:id',
    name: 'MerchantEdit',
    component: MerchantEdit,
    permission: [PermissionId.MERCHANT_LIST_UPDATE],
  },
  {
    path: '/merchant/view/:id',
    name: 'MerchantView',
    component: MerchantView,
    permission: [PermissionId.MERCHANT_LIST_READ],
  },
  // Employer Request Module
  {
    path: '/merchant/request/list',
    name: 'MerchantRequestList',
    component: MerchantRequestList,
    permission: [PermissionId.MERCHANT_REQUEST_READ],
  },
  {
    path: '/merchant/request/view/:id',
    name: 'MerchantRequestView',
    component: MerchantRequestView,
    permission: [PermissionId.MERCHANT_REQUEST_READ],
  },
  // Promotion Module
  {
    path: '/promotion/appHomeBackground',
    name: 'AppHomeBackground',
    component: AppHomeBackground,
    exact: true,
    permission: [PermissionId.PROMOTION_UPDATE],
  },
  {
    path: '/promotion/banner/:type/:id',
    name: 'EditBanner',
    component: EditBanner,
    exact: true,
    permission: [PermissionId.PROMOTION_UPDATE],
  },
  {
    path: '/promotion/banner/:type',
    name: 'AddBanner',
    component: AddBanner,
    exact: true,
    permission: [PermissionId.PROMOTION_WRITE],
  },
  {
    path: '/promotion/insight/:type/:id',
    name: 'EditInsights',
    component: EditInsight,
    exact: true,
    permission: [PermissionId.PROMOTION_UPDATE],
  },
  {
    path: '/promotion/insight/:type',
    name: 'AddInsights',
    component: AddInsight,
    exact: true,
    permission: [PermissionId.PROMOTION_WRITE],
  },
  {
    path: '/promotion/ticker/:type/:id',
    name: 'EditTicker',
    component: EditTicker,
    exact: true,
    permission: [PermissionId.PROMOTION_UPDATE],
  },
  {
    path: '/promotion/ticker/:type',
    name: 'AddTicker',
    component: AddTicker,
    exact: true,
    permission: [PermissionId.PROMOTION_WRITE],
  },
  {
    path: '/promotion/promotion-item/view/:id',
    name: 'ViewPromotionDetail',
    component: ViewBannerInsightDetail,
    exact: true,
    permission: [PermissionId.PROMOTION_READ],
  },
  {
    path: '/promotion/promotion-items/list',
    name: 'BannerInsightList',
    component: BannerInsightsList,
    exact: true,
    permission: [PermissionId.PROMOTION_READ],
  },
  {
    path: '/promotion/insight-category/list',
    name: 'InsightCategoryList',
    component: InsightCategoryList,
    exact: true,
    permission: [PermissionId.INSIGHT_CATEGORY_READ],
  },
  {
    path: '/promotion/poll/list',
    name: 'PollList',
    component: PollList,
    exact: true,
    permission: [PermissionId.POLL_READ],
  },
  {
    path: '/promotion/poll/create',
    name: 'CreatePoll',
    component: CreatePoll,
    exact: true,
    permission: [PermissionId.POLL_WRITE],
  },
  {
    path: '/promotion/poll/:id',
    name: 'ViewPoll',
    component: ViewPoll,
    exact: true,
    permission: [PermissionId.POLL_READ],
  },
  {
    path: '/promotion/poll/:id/edit',
    name: 'EditPoll',
    component: EditPoll,
    exact: true,
    permission: [PermissionId.POLL_EDIT],
  },

  // Report Module
  {
    path: '/report/hlbb',
    name: 'HLBBReport',
    component: HLBBReport,
    permission: [PermissionId.BANK_REPORTING_READ],
  },
  {
    path: '/report/payout-status/view/:id',
    name: 'PayoutStatusDetail',
    component: PayoutStatusDetail,
    permission: [PermissionId.PAYOUT_STATUS_READ],
  },
  {
    path: '/report/payout-status',
    name: 'PayoutStatys',
    component: PayoutStatus,
    permission: [PermissionId.PAYOUT_STATUS_READ],
  },
  {
    path: '/report/reconciliation',
    name: 'Reconciliation',
    component: ReconciliationReport,
    permission: [PermissionId.RECONCILIATION_READ],
  },
  {
    path: '/report/employer-deduction-files',
    name: 'EmployerDeductionFiles',
    component: EmployerDeductionFiles,
    permission: [PermissionId.EMPLOYER_DEDUCTION_REPORT_READ],
  },
  {
    path: '/report/lapse-repayment',
    name: 'LapseRepayment',
    component: LapseRepaymentReport,
    permission: [PermissionId.LAPSE_REPAYMENT_READ],
  },
  {
    path: '/report/exceptional-file',
    name: 'ExceptionalFile',
    component: ExceptionalFile,
    permission: [PermissionId.EXCEPTIONAL_FILE_READ],
  },
  {
    path: '/report/employee-info-update',
    name: 'EmployeeInfoUpdate',
    component: EmployeeInfoUpdate,
    permission: [PermissionId.EMPLOYEE_INFO_UPDATE_READ],
  },
  {
    path: '/report/employer-report',
    name: 'EmployerReport',
    component: EmployerReport,
    permission: [PermissionId.EMPLOYER_REPORT_READ],
  },
  {
    path: '/report/payment-transaction',
    name: 'PaymentTransaction',
    component: PaymentTransaction,
    permission: [PermissionId.PAYMENT_TRANSACTION_READ],
  },
  // Finance Module
  {
    path: '/finance/ibg-enquiry',
    name: 'IBG Enquiry',
    component: IbgEnquiry,
    permissions: ['EMPLOYEE_INFO_UPDATE_READ'],
  },
  {
    path: '/finance/settlement',
    name: 'Settlement',
    component: Settlement,
    permission: [PermissionId.INVOICE_READ],
  },
  {
    path: '/finance/payment-to-bank/:id',
    name: 'PaymentToBankView',
    component: PaymentToBankView,
    permission: [PermissionId.PAYMENT_TRANSACTION_READ],
  },
  {
    path: '/finance/statement-of-account',
    name: 'StatementOfAccountView',
    component: StatementOfAccountView,
    permission: [PermissionId.STATEMENT_OF_ACCOUNT_READ],
  },
  {
    path: '/finance/saas-fee-config',
    name: 'SaasFeeConfigView',
    component: SaasFeeConfigView,
    permission: [PermissionId.SAAS_FEE_CONFIG_READ],
  },
  {
    path: '/finance/payment-to-bank',
    name: 'PaymentToBankList',
    component: PaymentToBankList,
    permission: [PermissionId.PAYMENT_TRANSACTION_READ],
  },
  // HRMS Module
  {
    path: '/hrms/partners',
    exact: true,
    name: 'HRMSPartnerList',
    component: HRMSPartnerList,
    permission: [PermissionId.HRMS_PARTNERS_READ],
  },
  {
    path: '/hrms/partners/:id',
    name: 'HRMSPartnerDetails',
    component: HRMSPartnerDetails,
    permission: [PermissionId.HRMS_PARTNERS_READ],
  },
  {
    path: '/hrms/processes',
    exact: true,
    name: 'HRMSProcessList',
    component: HRMSProcessList,
    permission: [PermissionId.HRMS_PROCESSES_READ],
  },
  {
    path: '/hrms/processes/:id',
    name: 'HRMSProcessDetails',
    component: HRMSProcessDetails,
    permission: [PermissionId.HRMS_PROCESSES_READ],
  },
  {
    path: '/hrms/salary-deduction-requests',
    exact: true,
    name: 'HRMSSalaryDeductionRequestList',
    component: HRMSSalaryDeductionRequestList,
    permission: [PermissionId.HRMS_SD_REQUESTS_READ],
  },
  {
    path: '/hrms/salary-deduction-requests/:id',
    name: 'SalaryDeductionRequestDetails',
    component: SalaryDeductionRequestDetails,
    permission: [PermissionId.HRMS_SD_REQUESTS_READ],
  },
  // Settings Module
  {
    path: '/settings/bank-configuration',
    name: 'BankConfiguration',
    component: BankConfigurationList,
    permission: [PermissionId.BANK_CONFIG_READ],
  },
  {
    path: '/settings/setting-configuration',
    name: 'SettingConfiguration',
    component: SettingConfiguration,
    permission: [PermissionId.SETTING_CONFIG_READ],
  },
  {
    path: '/settings/fees-charges',
    name: 'FeesAndCharges',
    component: FeesAndCharges,
    permission: [PermissionId.SETTING_CONFIG_READ],
  },
  {
    path: '/settings/user-access',
    name: 'UserAccessList',
    component: UserAccessList,
    permission: [PermissionId.SETTING_USER_ACCESS_READ],
  },
  // Role Management
  {
    path: '/settings/role-management/edit/:id',
    name: 'EditRoleManagement',
    component: EditRoleManagement,
    permission: [PermissionId.SETTING_ROLES_UPDATE],
  },
  {
    path: '/settings/role-management/view/:id',
    name: 'ViewRoleManagementDetail',
    component: ViewRoleManagement,
    permission: [PermissionId.SETTING_ROLES_READ],
  },
  {
    path: '/settings/role-management/add',
    name: 'AddRoleManagement',
    component: AddRoleManagement,
    permission: [PermissionId.SETTING_ROLES_WRITE],
  },
  {
    path: '/settings/role-management',
    name: 'RoleManagementList',
    component: RoleManagementList,
    permission: [PermissionId.SETTING_ROLES_READ],
  },
  {
    path: '/settings/global-cutoff/view/:id',
    name: 'GlobalCutoffDetail',
    component: ViewGlobalCutOff,
    permission: [PermissionId.SETTING_GLOBAL_CUT_OFF_READ],
  },
  {
    path: '/settings/global-cutoff/edit/:id',
    name: 'EditGlobalCutOff',
    component: EditGlobalCutOff,
    permission: [PermissionId.SETTING_GLOBAL_CUT_OFF_UPDATE],
  },
  {
    path: '/settings/global-cutoff/add',
    name: 'AddGlobalCutOff',
    component: AddGlobalCutOff,
    permission: [PermissionId.SETTING_GLOBAL_CUT_OFF_WRITE],
  },
  {
    path: '/settings/global-cutoff',
    name: 'GlobalCutoffList',
    component: GlobalCutOffList,
    permission: [PermissionId.SETTING_GLOBAL_CUT_OFF_READ],
  },
  {
    path: '/settings/deduction-file-configuration/:id/edit',
    name: 'EditDeductionFileConfiguration',
    component: EditDeductionFileConfiguration,
    permission: [PermissionId.SETTING_DEDUCTION_FILE_CONFIGURATION_EDIT],
  },
  {
    path: '/settings/deduction-file-configuration/:id/view',
    name: 'ViewDeductionFileConfiguration',
    component: ViewDeductionFileConfiguration,
    permission: [PermissionId.SETTING_DEDUCTION_FILE_CONFIGURATION_READ],
  },
  {
    path: '/settings/deduction-file-configuration/add',
    name: 'AddDeductionFileConfiguration',
    component: AddDeductionFileConfiguration,
    permission: [PermissionId.SETTING_DEDUCTION_FILE_CONFIGURATION_WRITE],
  },
  {
    path: '/settings/deduction-file-configuration',
    name: 'ViewDeductionFileConfigurationList',
    component: ViewDeductionFileConfigurationList,
    permission: [PermissionId.SETTING_DEDUCTION_FILE_CONFIGURATION_READ],
  },
];

export default routes;

import { useQuery } from 'react-query';
import { API } from 'api';

export interface IPaymentTransactionReportListData {
  payload: {
    page?: number;
    dateFrom?: number;
    dateTo?: number;
    uid?: string;
    aid?: string;
    type: string;
    status?: string;
    trxNum?: string;
  };
  data: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    count: number;
  };
}

export const initPaymentTransactionReportListPayload: IPaymentTransactionReportListData['payload'] = {
  page: 1,
  type: 'payment',
};

const getPaymentTransactionReportListData = (data: IPaymentTransactionReportListData['payload']) => {
  return API.get()('/api/admin/ptb/retrieve/all', data);
};

export const usePaymentTransactionListData = (data: IPaymentTransactionReportListData['payload']) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQuery(['paymentTransactionReports', data], (): any => getPaymentTransactionReportListData(data), {
    select: (response: IPaymentTransactionReportListData['data']) => {
      return response.data;
    },
  });
};

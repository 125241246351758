import { useMutation, useQueryClient } from 'react-query';
import { API } from 'api';
import { getLatestQuery, isUndefinedOrNullOrEmpty } from 'common';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';

export interface IAddManualReconciliationData {
  payload: {
    serviceProvider: string;
    data: string | Blob;
    startAt: string;
    endAt: string;
    fileType: string;
    parentId?: string;
  };
}

const addManualReconciliationData = (payload: IAddManualReconciliationData['payload']) => {
  const formData = new FormData();
  formData.append('fileData', payload.data || '');
  formData.append('providerCode', payload.serviceProvider);
  formData.append('type', payload.fileType || '');
  formData.append('startAt', payload.startAt);
  formData.append('endAt', payload.endAt);
  if (payload.parentId && !isUndefinedOrNullOrEmpty(payload.parentId)) {
    formData.append('parentId', payload.parentId);
  }

  return API.newPost('/api/admin/v1/reconciliation/manual', formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const useAddManualReconciliationData = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const key = 'payoutStatusReconciliation';
  const queryArr = queryClient.getQueriesData(key);
  const res = getLatestQuery(queryArr, queryClient);

  return useMutation(addManualReconciliationData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: ' toastPush.uploadSuccessful',
        })
      );
      return queryClient.invalidateQueries(key, res.queryParams);
    },
  });
};

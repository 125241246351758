import { useMutation } from 'react-query';
import { API } from 'api';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';

export interface IUpdatePaymentTransactionStatusData {
  payload: {
    paidPtbId?: string;
  };
}

const updatePaymentTransactionStatusData = (payload: IUpdatePaymentTransactionStatusData['payload']) => {
  return API.newPut(`api/admin/ptb/update`, payload);
};

export const useUpdatePaymentTransactionStatusData = () => {
  const dispatch = useDispatch();

  return useMutation(updatePaymentTransactionStatusData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.updatedSuccessful',
        })
      );
    },
  });
};

import { useMutation } from 'react-query';
import { API } from 'api';
import { useDispatch } from 'react-redux';
import { toastPush } from 'modules';

export interface ITransactionDownloadData {
  payload: {
    eid?: string;
    uid?: string;
    trxRef?: string;
    trxType?: string;
    mobile?: string;
    dateFrom?: number;
    dateTo?: number;
    download: boolean;
  };
}

const getTransactionDownload = (data: ITransactionDownloadData['payload']) => {
  return API.get()('/api/admin/transactions/retrieve', data);
};

export const useTransactionDownloadData = () => {
  const dispatch = useDispatch();
  return useMutation(getTransactionDownload, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.downloadReportSuccessPleaseCheckMailbox',
        })
      );
    },
  });
};

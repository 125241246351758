/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';

export interface IExceptionalFileListData {
  payload: {
    page?: number;
    from?: number;
    to?: number;
    type?: string;
  };
  data: {
    data: any;
    count: number;
  };
}

export const initExceptionalFileListPayload: IExceptionalFileListData['payload'] = {
  type: 'exceptional',
  page: 1,
};

const getExceptionalFileListData = (data: IExceptionalFileListData['payload']) => {
  return API.get()('/api/admin/financeFiles/HLBB', data);
};

export const useExceptionalFileListData = (data: IExceptionalFileListData['payload']) => {
  return useQuery(['exceptionalFiles', data], (): any => getExceptionalFileListData(data), {
    select: (response: IExceptionalFileListData['data']) => {
      return response.data;
    },
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';
import { IInsightCategoryResponse } from './sharedInterface';

const getInsightCategoryInfo = (id: string) => {
  return API.newGet(`/api/admin/promotionInsightCategory/${id}`);
};

export const useCategoryInsightInfoData = (id: string) => {
  return useQuery(['insightCategory', id], (): any => getInsightCategoryInfo(id), {
    refetchOnWindowFocus: false,
    select: (response: IInsightCategoryResponse) => {
      return response.data;
    },
  });
};

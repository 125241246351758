import { useQuery } from 'react-query';
import { API } from '../../../api';

export interface IGetDeductionFileKeyList {
  payload: {
    seacrh?: string;
  };
  data: {
    data: Array<{
      key: string;
      value: string;
      dataType: string;
    }>;
  };
}

const getDeductionFileKeyList = () => API.newGet('/api/admin/sys/deduction-file/config/key-list');
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// API.newGet('http://localhost:7101/setting/sys/deduction-file/config/key-list');

export const useGetDeductionFileKeyList = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQuery(['getDeductionFileKeyList'], (): any => getDeductionFileKeyList(), {
    select: (response: IGetDeductionFileKeyList) => {
      return {
        data: response.data.data.map((item) => ({
          key: item.key,
          value: item.value,
          dataType: item.dataType,
        })),
      };
    },
  });
};

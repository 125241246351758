import { useQuery } from 'react-query';
import { API } from 'api';
import { AdminStatus, isUndefinedOrNullOrEmpty } from 'common';

interface IAdminUserInfoResponse {
  data: {
    id: string;
    profile: {
      name: string;
      email: string;
    };
    role: string;
    status: AdminStatus;
  };
}

const getAdminUserInfo = (id: string) => {
  return API.get()(`/api/admin/admUser/info/${id}`);
};

export const useAdminUserInfoData = (id: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQuery(['adminUser', id], (): any => getAdminUserInfo(id), {
    enabled: !isUndefinedOrNullOrEmpty(id),
    select: (response: IAdminUserInfoResponse) => {
      return response?.data;
    },
  });
};

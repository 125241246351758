import { useMutation } from 'react-query';
import { API } from 'api';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';

interface ICreateRoleData {
  payload: {
    name: string;
    isEmployerRole: boolean;
    permissions: string[];
  };
}

const createRoleData = (payload: ICreateRoleData['payload']) => {
  return API.newPost(`/api/admin/role`, payload);
};

export const useCreateRoleData = () => {
  const dispatch = useDispatch();

  return useMutation(createRoleData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.updatedSuccessful',
        })
      );
    },
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';

interface IWithdrawalCycleDataResponse {
  data: {
    cycleHistoryData: {
      id: string;
      tag: string;
    }[];
  };
}

const getEmployerPastWithdrawalCycles = (eid: string) => {
  return API.get()(`/api/employer/${eid}`);
};

export const useEmployerPastWithdrawalCycleData = (eid: string) => {
  return useQuery(['employerWithdrawalCycles', eid], (): any => getEmployerPastWithdrawalCycles(eid), {
    enabled: !!eid,
    select: (res: IWithdrawalCycleDataResponse) => {
      return res?.data?.cycleHistoryData;
    },
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';

export interface IGlobalCutOffSelectionData {
  data: {
    data: { id: string; name: string }[];
  };
}

const getGlobalCutOffSelectionData = () => {
  return API.newGet('/api/admin/setting/globalWithdrawalCycle/select');
};

export const useGlobalCutOffSelectionData = () => {
  return useQuery(['globalCutOffsSelection'], (): any => getGlobalCutOffSelectionData(), {
    select: (response: IGlobalCutOffSelectionData['data']) => {
      const result = response?.data || [];
      return result;
    },
  });
};

import { useMutation } from 'react-query';
import { API } from 'api';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';

export interface IUpdateEmployeeCompanyRecordData {
  payload: {
    id: string;
    ebid: string;
  };
}

const updateEmployeeCompanyRecordData = (payload: IUpdateEmployeeCompanyRecordData['payload']) => {
  return API.newPut(`/api/employee/company/${payload.ebid}`, payload);
};

export const useUpdateEmployeeCompanyRecordData = () => {
  const dispatch = useDispatch();

  return useMutation(updateEmployeeCompanyRecordData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.updatedSuccessful',
        })
      );
    },
  });
};

import { useMutation } from 'react-query';
import { API } from 'api';
import { useDispatch } from 'react-redux';
import { toastPush } from 'modules';

export interface IEmployeeListDownloadData {
  payload: {
    name?: string;
    mobile?: string;
    eid?: string;
    staffId?: string;
    userId?: string;
    download?: boolean;
  };
}

const getEmployeeListDownload = (data: IEmployeeListDownloadData['payload']) => {
  return API.get()('/api/employee', data);
};

export const useEmployeeListDownloadData = () => {
  const dispatch = useDispatch();
  return useMutation(getEmployeeListDownload, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.downloadReportSuccessPleaseCheckMailbox',
        })
      );
    },
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';

export interface IEmployerReportListData {
  payload: {
    page?: number;
    from?: number;
    to?: number;
    type?: string;
  };
  data: {
    data: any;
    count: number;
  };
}

export const initEmployerReportListPayload: IEmployerReportListData['payload'] = {
  page: 1,
  type: 'all',
};

const getEmployerReportListData = (data: IEmployerReportListData['payload']) => {
  return API.get()('/api/admin/financeFiles/employerReport', data);
};

export const useEmployerReportListData = (data: IEmployerReportListData['payload']) => {
  return useQuery(['employerReports', data], (): any => getEmployerReportListData(data), {
    select: (response: IEmployerReportListData['data']) => {
      return response.data;
    },
  });
};

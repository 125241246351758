import { API } from 'api';
import { useQuery } from 'react-query';

export interface IAgentBulkImportRequestList {
  payload: {
    id?: string;
    showTable?: boolean;
    from?: moment.Moment;
    to?: moment.Moment;
    eid?: string;
    status?: string;
    remark?: string;
    page: number;
  };
  data: {
    data: {
      createdOn: number;
      eid: string;
      employeeId: string;
      employeeName: string;
      fileName: string;
      id: string;
      mobile: string;
      reason: string;
      role: string;
      salary: string;
      salaryFrequency: string;
      status: string;
      type: string;
    }[];
    count: number;
  };
}

export const initAgentBulkImportRequestListPayload: IAgentBulkImportRequestList['payload'] = {
  page: 1,
  remark: 'agent',
};

const getAgentBulkImportRequestList = async (payload: IAgentBulkImportRequestList['payload']) => {
  return API.newGet('/api/employer/bulkUpdateRequest', payload).then((res) => res.data);
};

export const useGetAgentBulkImportRequestList = (
  payload: IAgentBulkImportRequestList['payload'],
  showTable: boolean
) => {
  return useQuery(['agentBulkImportRequestList', payload], () => getAgentBulkImportRequestList(payload), {
    enabled: showTable,
    select: (response: IAgentBulkImportRequestList['data']) => {
      return response;
    },
  });
};

import { useMutation } from 'react-query';
import { API } from 'api';
import { useDispatch } from 'react-redux';
import { toastPush } from 'modules';
import { useTranslation } from 'react-i18next';

export interface IEmployeeConsentDownloadData {
  payload: {
    eid: string;
    from?: number;
    to?: number;
  };
}

const getEmployeeConsentDownload = (data: IEmployeeConsentDownloadData['payload']) => {
  return API.post()('/api/employee/download/consent', data);
};

export const useEmployeeConsentDownloadData = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useMutation(getEmployeeConsentDownload, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.downloadReportSuccessPleaseCheckMailbox',
        })
      );
    },
  });
};

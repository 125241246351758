/* eslint-disable no-console */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment';
import { getCookie, getCurrentCountryCode, isDev, isUndefinedOrNullOrEmpty, setCookie } from 'common';
import { toastPush } from 'modules/public/toast';
import { store } from 'store';
import { apiError, logout, RootState, updateUserCredential } from 'modules';

let countRefreshToken = 0;

const disableApiErrorNotification = [
  '/api/admin/pub/authorisation', 
  '/api/admin/pub/validate/otp', 
  '/api/admin/pub/ticket/admin/unusual-activity'
];

const getUserCredential = (state: RootState) => {
  return state.user;
};

const service = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}` || 'http://localhost:7071',
  timeout: 30000,
});

const refreshTokenService = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}` || 'http://localhost:7071',
  timeout: 30000,
});

const requestHandler = async (request: AxiosRequestConfig) => {
  // const queryClient = useQueryClient();
  // const langCode = queryClient.getQueryData('langaugeCode');
  // console.log('langCode 123123', queryClient);
  const countryCode = getCurrentCountryCode(store.getState());
  let needRefreshToken = false;
  const accessToken = getCookie('accessToken');
  const refreshToken = getCookie('refreshToken');
  const expiredOn = Number(getCookie('expiredOn'));
  const isImpersonate = getCookie('isImpersonate') === 'true';
  request.headers['Country-Code'] = countryCode;
  if (
    ['/api/admin/pub/authorisation', '/api/admin/pub/reset/pwd', '/api/admin/switch/employer/authorisation'].indexOf(
      request.url as string
    ) !== -1
  ) {
    request.headers['Country-Code'] = 'admin';
  }
  if (
    ['application/json; charset=utf-8', 'application/json', undefined].indexOf(request.headers['Content-Type']) !== -1
  ) {
    if (isDev()) {
      console.group('API REQUEST URL-', request.url);
      console.log('REQUEST: ', request.data);
      console.log('REQUEST (JSON): ', JSON.stringify(request.data));
      console.groupEnd();
    }
  }
  request.headers['Api-Version'] = process.env.REACT_APP_API_VERSION;
  request.headers.Authorization = `Bearer ${accessToken}`;
  request.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_SUBSCRIPTION_KEY;
  request.headers['Lang-Code'] = localStorage.getItem('i18nextLng');
  // }
  // Determine that whether the token needs to be refreshed.
  if (expiredOn) {
    const timeNow = moment().unix();
    // check expireOn is around current time or not
    if (expiredOn > timeNow) {
      const activeTime = moment.unix(expiredOn).subtract(8, 'minutes').unix();
      // check expireOn is around active time 15min or not
      if (timeNow >= activeTime) {
        needRefreshToken = true;
      }
    }
  }

  // Renew token
  if (needRefreshToken && countRefreshToken === 0) {
    countRefreshToken += 1;
    const { dispatch } = store;
    const config = {
      headers: {
        'Ocp-Apim-Subscription-Key': request.headers['Ocp-Apim-Subscription-Key'],
        'Api-Version': process.env.REACT_APP_API_VERSION ?? '',
        Authorization: `Bearer ${accessToken}`,
        'Country-Code': 'admin',
      },
    };
    try {
      const refreshResult = await refreshTokenService.put('/api/admin/token/refresh', { refreshToken, refreshCountryCode: countryCode, isImpersonate }, config);
      if (refreshResult?.status === 200) {
        const dResult = refreshResult.data;
        const newCredentials = {
          accessToken: dResult.accessToken,
          refreshToken: dResult.refreshToken,
          expiredOn: dResult.expiredOn,
        };
        const interval = setInterval(() => {
          countRefreshToken = 0;
          clearInterval(interval);
        }, 5000);
        // update refreshed token
        request.headers.Authorization = `Bearer ${dResult.accessToken}`;
        // newCredentials.expiredOn = moment().add(2, 'hours').unix()
        // Update new token
       
        setCookie('accessToken', dResult.accessToken, dResult.expiredOn);
        setCookie('refreshToken', dResult.refreshToken, dResult.expiredOn);
        setCookie('expiredOn', dResult.expiredOn, dResult.expiredOn);
        setCookie('isImpersonate', isImpersonate.toString(), dResult.expiredOn);
        dispatch(updateUserCredential(newCredentials));
      } else {
        // Log out unauthorized user
        dispatch(apiError({ errorCode: 401 }));
      }
    } catch (error) {
      console.log('Error Refresh Token:', JSON.stringify(error));
      dispatch(logout());
    }
  }

  if (request.params) {
    request.params = replace0XInObject(request.params);
  }

  if (request.url?.includes('0X') === true && ['get', 'put'].indexOf(request.method || "") !== -1) {
    request.url = request.url.replace('0X', '%30%58');
  }

  return request;
};

const responseHandler = (response: AxiosResponse) => {
  try {
    if (response.status === 200) {
      if (
        ['text/plain; charset=utf-8', 'application/json; charset=utf-8'].indexOf(response.headers['content-type']) ===
        -1
      ) {
        return response;
      }

      console.group('API RESPONSE URL -', response.config.url);
      if (['/api/admin/download/file'].indexOf(response.config?.url || '') === -1) {
        console.log('response.data', response.data);
      }
      console.groupEnd();
    }
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const errorHandler = async (error: AxiosError) => {
  const { dispatch } = store;
  if (error.response?.data instanceof Blob) {
    error.response.data = await error.response?.data.text();
  }

  if (error.response?.status === 401 || error.response?.status === 406) {
    if (['/api/admin/notification/retrieve'].indexOf(error.response?.config?.url || '') >= 0) {
      dispatch(logout());
    } else {
      dispatch(apiError({ errorCode: error.response?.status }));
    }
  }

  if (error.response?.status === 404) {
    return Promise.reject(error.response.data);
  }

  if (error.response?.status === 400) {
    dispatch(
      toastPush({
        code: 400,
        type: 'error',
        title: 'ERROR',
        body: 'label.somethingWentWrong',
      })
    );
  }

  if (error.response?.status === 403 && disableApiErrorNotification.indexOf(error.response?.config?.url || '') === -1) {
    let errorReturn = error.response?.data?.message || error.response?.data;

    if (!isUndefinedOrNullOrEmpty(error.response?.data?.messageId) && error.response?.data?.messageId !== 'admin.file.alreadySent') {
      errorReturn = error.response?.data?.messageId;
    }

    dispatch(
      toastPush({
        code: 400,
        type: 'error',
        title: 'ERROR',
        body: errorReturn,
        params: error.response?.data?.params
      })
    );
  }

  console.error(error.response?.data);
  return Promise.reject(error.response?.data);
};

service.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

service.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);


const replace0XInObject = (obj: object): object => {
  const newObj: { [key: string]: string } = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (typeof newObj[key] === 'string' && newObj[key].includes('0X')) {
      newObj[key] = newObj[key].replace('X', decodeURI('%58'));
    }
  });
  

  return newObj;
};

export { service };

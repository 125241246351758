/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQueryClient } from 'react-query';
import { API } from 'api';

export interface IUpdateB2BFeesData {
  payload: {
    id?: string;
    enabled?: boolean;
    fee?: number;
    remarks?: string;
    eid: string;
  };
}

const updateB2bFeesData = (payload: IUpdateB2BFeesData['payload']) => {
  return API.put()(`/api/admin/finance/b2bFee`, payload);
};

export const useUpdateB2bFeesData = () => {
  const queryClient = useQueryClient();

  return useMutation(updateB2bFeesData, {
    onSuccess: (_: any, variables: IUpdateB2BFeesData['payload']) => {
      return queryClient.invalidateQueries(['employers-b2bFee', variables.id]);
    },
  });
};

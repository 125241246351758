import { useMutation } from 'react-query';
import { API } from 'api';
import { useDispatch } from 'react-redux';
import { toastPush } from 'modules';

export interface IEmployeeRequestDownloadData {
  payload: {
    from?: number;
    to?: number;
    mobile?: string;
    employeeId?: string;
    name?: string;
    status?: string;
    eid?: string;
  };
}

const getEmployeeRequestDownload = (data: IEmployeeRequestDownloadData['payload']) => {
  return API.get()('/api/employment/download', data);
};

export const useEmployeeRequestDownloadData = () => {
  const dispatch = useDispatch();
  return useMutation(getEmployeeRequestDownload, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.downloadReportSuccessPleaseCheckMailbox',
        })
      );
    },
    onError: (error: { statusCode: number; message: string }) => {
      dispatch(
        toastPush({
          code: error.statusCode,
          type: 'error',
          title: 'ERROR',
          body: error?.message,
        })
      );
    },
  });
};

import { useQuery } from 'react-query';
import { API } from 'api';
import { isUndefinedOrNullOrEmpty } from 'common';

interface IGlobalCutOffInfoResponse {
  data: {
    id: string;
    name: string;
    currentCycleData: {
      id: string;
      tag: string;
      dateFrom: number;
      dateTo: number;
    }[];
    futureCycleData: {
      id: string;
      tag: string;
      dateFrom: number;
      dateTo: number;
    }[];
    withdrawalCycles: {
      id: string;
      tag: string;
      dateFrom: number;
      dateTo: number;
    }[];
  };
}

const getGlobalCutOffInfo = (id: string) => {
  return API.newGet(`/api/admin/setting/globalWithdrawalCycle/${id}`, { page: 1 });
};

export const useGlobalCutOffInfoData = (id: string) => {
  return useQuery(['globalCutOffInfo', id], () => getGlobalCutOffInfo(id), {
    enabled: !isUndefinedOrNullOrEmpty(id) && id !== 'custom',
    select: (response: IGlobalCutOffInfoResponse) => {
      return response.data;
    },
  });
};

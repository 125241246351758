import { useMutation } from 'react-query';
import { API } from 'api';
import { useDispatch } from 'react-redux';
import { toastPush } from 'modules';

export interface IRewardDownloadData {
  payload: {
    uid: string;
    from?: number;
    to?: number;
  };
}

const getEmployeeRewardDownload = (data: IRewardDownloadData['payload']) => {
  return API.newGet(`/api/employee/${data.uid}/rewards/download`, data);
};

export const useEmployeeRewardDownloadData = () => {
  const dispatch = useDispatch();
  return useMutation(getEmployeeRewardDownload, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.downloadSucessful',
        })
      );
    },
  });
};

import { useMutation, useQueryClient } from 'react-query';
import { API } from 'api';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';
import { IInsightCategoryPayload } from './sharedInterface';

const createInsightCategoryData = (payload: IInsightCategoryPayload) => {
  return API.newPost(`api/admin/promotionInsightCategory`, payload);
};

export const useCreateInsightCategoryData = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(createInsightCategoryData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.createdSucessful',
        })
      );

      queryClient.invalidateQueries(['promotionInsightCategories']);
    },
  });
};

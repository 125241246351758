import { useMutation, useQueryClient } from 'react-query';
import { API } from 'api';
import { getLatestQuery } from 'common';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';

export interface IAddInvoiceData {
  payload: {
    eid: string;
    fileName: string;
    data: string;
    type: string;
    withdrawalCycleId: string;
  };
}

const addInvoiceData = (payload: IAddInvoiceData['payload']) => {
  const formData = new FormData();
  formData.append('data', payload.data);
  formData.append('type', payload.type);
  formData.append('eid', payload.eid);
  formData.append('withdrawalCycleId', payload.withdrawalCycleId);
  return API.post()('/api/admin/invoices/upload', formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const useAddInvoiceData = () => {
  const queryClient = useQueryClient();
  const queryArr = queryClient.getQueriesData('invoices');
  const res = getLatestQuery(queryArr, queryClient);
  const dispatch = useDispatch();

  return useMutation(addInvoiceData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.createdSucessful',
        })
      );
      return queryClient.invalidateQueries('invoices', res.queryParams);
    },
  });
};

import { API } from 'api';
import { useQuery } from 'react-query';

export interface IBulkImportRequestList {
  payload: {
    id?: string;
    showTable?: boolean;
    from?: moment.Moment;
    to?: moment.Moment;
    eid?: string;
    status?: string;
    page: number;
  };
  data: {
    data: {
      createdOn: number;
      eid: string;
      employeeId: string;
      employeeName: string;
      fileName: string;
      id: string;
      mobile: string;
      reason: string;
      role: string;
      salary: string;
      salaryFrequency: string;
      status: string;
      type: string;
    }[];
    count: number;
  };
}

export const initBulkImportRequestListPayload: IBulkImportRequestList['payload'] = {
  page: 1,
};

const getBulkImportRequestList = async (payload: IBulkImportRequestList['payload']) => {
  return API.newGet('/api/employer/bulkUpdateRequest', payload).then((res) => res.data);
};

export const useGetBulkImportRequestList = (payload: IBulkImportRequestList['payload'], showTable: boolean) => {
  return useQuery(['bulkImportRequestList', payload], () => getBulkImportRequestList(payload), {
    enabled: showTable,
    select: (response: IBulkImportRequestList['data']) => {
      return response;
    },
  });
};

import { useMutation, useQueryClient } from 'react-query';
import { API } from 'api';
import { getLatestQuery } from 'common';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';

interface IUpdateRoleData {
  payload: {
    id: string;
    permissions: string[];
  };
}

const updateRoleData = (payload: IUpdateRoleData['payload']) => {
  return API.put()(`/api/admin/role/${payload.id}`, payload);
};

export const useUpdateRoleData = () => {
  const queryClient = useQueryClient();
  const queryArr = queryClient.getQueriesData('roles');
  const res = getLatestQuery(queryArr, queryClient);
  const dispatch = useDispatch();

  return useMutation(updateRoleData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.updatedSuccessful',
        })
      );
      return queryClient.invalidateQueries(['roles', res.queryParams]);
    },
  });
};

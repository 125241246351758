/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';

export interface IPromotionListData {
  payload: {
    page?: number;
    to?: number;
    from?: number;
    status?: string;
    type?: string;
  };
  data: {
    data: any;
    count: number;
  };
}

export const initPromotionListPayload: IPromotionListData['payload'] = {
  page: 1,
};

const getPromotionList = (data: IPromotionListData['payload']) => {
  return API.newGet('/api/admin/promotion/all', data);
};

export const usePromotionListData = (data: IPromotionListData['payload']) => {
  return useQuery(['promotions', data], (): any => getPromotionList(data), {
    select: (response: IPromotionListData['data']) => {
      return response.data;
    },
  });
};

import { useQuery } from 'react-query';
import { API } from 'api';

export interface IEmployerSaasFeeData {
  payload: {
    employerName?: string;
    employerId?: string;
    companyNameEid?: string;
    companyName?: string;
    b2bFees?: boolean;
    page: number;
  };
  data: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    data: any;
    count: number;
  };
}
export interface B2BFee {
  id: string;
  enabled?: boolean;
  remark?: string;
  fee: number;
  lastUpdated?: string;
  updatedAt?: string;
  createdAt?: string;
  updateBy?: string;
}

export const initEmployerFeesListPayload: IEmployerSaasFeeData['payload'] = {
  b2bFees: true,
  page: 1,
};

const getEmployerList = (data: IEmployerSaasFeeData['payload']) => {
  return API.get()('/api/admin/b2b/retrieve', data);
};

export const useEmployerB2bFeesData = (data: IEmployerSaasFeeData['payload']) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return useQuery(['employers-b2bFee', data], (): any => getEmployerList(data), {
    select: (response: IEmployerSaasFeeData['data']) => {
      return response.data;
    },
  });
};

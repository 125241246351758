import { useMutation } from 'react-query';
import { API } from 'api';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';
import { IPromotionPayload } from './sharedInterface';

const updatePromotionData = (payload: IPromotionPayload) => {
  return API.newPut(`api/admin/promotion/${payload.pid}`, payload);
};

export const useUpdatePromotionData = () => {
  const dispatch = useDispatch();

  return useMutation(updatePromotionData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.updatedSuccessful',
        })
      );
    },
  });
};

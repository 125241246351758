import { useMutation } from 'react-query';
import { API } from 'api';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';
import { IPromotionPayload } from './sharedInterface';

const createPromotionData = (payload: IPromotionPayload) => {
  return API.newPost(`api/admin/promotion`, payload);
};

export const useCreatePromotionData = () => {
  const dispatch = useDispatch();

  return useMutation(createPromotionData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.createdSucessful',
        })
      );
    },
  });
};

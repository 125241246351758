import { useMutation } from 'react-query';
import { API } from 'api';
import { useDispatch } from 'react-redux';
import { toastPush } from 'modules';
import { useTranslation } from 'react-i18next';

export interface IBulkImportHistoryDownloadData {
  payload: {
    eid?: string;
    buqid?: string;
    fileType?: string;
    actionType?: string;
    employeeId?: string;
    idNumber?: string;
    status?: string;
    dateFrom?: number;
    dateTo?: number;
    download: boolean;
  };
}

const getBulkImportHistoryDownload = (data: IBulkImportHistoryDownloadData['payload']) => {
  return API.get()('/api/admin/bulkImportHistory/retrieve', data);
};

export const useBulkImportHistoryDownloadData = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useMutation(getBulkImportHistoryDownload, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.downloadReportSuccessPleaseCheckMailbox',
        })
      );
    },
  });
};

import { API } from 'api';
import { isUndefinedOrNullOrEmpty } from 'common';
import { useQuery } from 'react-query';

export interface IGetOtpInfo {
  payload: {
    aid: string;
    email: string;
  };
  data: {
    data: { createdOn: string };
  };
}

const getOtpInfo = (payload: IGetOtpInfo['payload']) => {
  return API.newGet('/api/admin/pub/otp/countdown', payload);
};

export const useGetCountdownOtpData = (payload: IGetOtpInfo['payload']) => {
  return useQuery(['otpInfo', payload], (): any => getOtpInfo(payload), {
    enabled: !isUndefinedOrNullOrEmpty(payload.aid),
    select: (response: IGetOtpInfo['data']) => {
      return response.data;
    },
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';

export interface IInsightCategoryListData {
  data: {
    data: any;
    count: number;
  };
}

const getInsightCategoryList = () => {
  return API.newGet('/api/admin/promotionInsightCategory/all', {});
};

export const useInsightCategoryListData = () => {
  return useQuery(['promotionInsightCategories'], (): any => getInsightCategoryList(), {
    select: (response: IInsightCategoryListData['data']) => {
      const res = {
        count: 0,
        data: [],
      };
      res.count = response.data.count;

      res.data = response.data?.data.map((x: any) => {
        return {
          ...x,
          name: x.en?.name,
        };
      });

      return res;
    },
  });
};

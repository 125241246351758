/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { API } from 'api';
import { getLatestQuery } from 'common';
import { toastPush } from 'modules';

export interface IUpdateDeductionFileType {
  payload: {
    id: string;
    name: string;
    mappings: Array<{
      column: string;
      mapping?: string;
      value?: string;
    }>;
  };
  data: {
    data: any;
  };
}

const updateDeductionFileType = (data: IUpdateDeductionFileType['payload']) =>
  API.put()(`/api/admin/sys/deduction-file/config/${data.id}/edit`, data);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// API.put()(`http://localhost:7101/setting/sys/deduction-file/config/${data.id}/edit`, data);

export const useUpdateDeductionFileType = () => {
  const queryClient = useQueryClient();
  const queryArr = queryClient.getQueriesData('getDeductionFileTypeList');
  const request = getLatestQuery(queryArr, queryClient);
  const dispatch = useDispatch();

  return useMutation(updateDeductionFileType, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.updatedSuccessful',
        })
      );
      return queryClient.invalidateQueries('getDeductionFileTypeList', request.queryParams);
    },
  });
};

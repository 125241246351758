import { RcFile } from 'antd/lib/upload/interface';
import { API } from 'api';
import { useMutation } from 'react-query';

export interface IAttendanceUpload {
  payload: {
    eid?: string;
    file?: RcFile;
    type?: string;
  };
}

const employeeAttendanceUpload = (payload: IAttendanceUpload['payload']) => {
  const formData = new FormData();
  formData.append('eid', payload.eid ?? '');
  formData.append('file', payload.file ?? '');
  formData.append('type', payload.type ?? '');
  return API.newPost('/api/admin/employee/attendance/upload', formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const useEmployeeAttendanceUpload = () => {
  return useMutation(employeeAttendanceUpload, {
    onSuccess: (response) => {
      return response.data;
    },
  });
};

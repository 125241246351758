import { useQuery } from 'react-query';
import { API } from 'api';

interface IEmployerOutletInfoResponse {
  data: {
    id: string;
    eid: string;
    name: string;
    address: string;
    email: string;
    companyName: string;
    fees: number;
    location: {
      lat: number;
      long: number;
    };
    qr: {
      qrImage: string;
      qrImageTemplate: string;
    };
    phoneNumber: string;
    employees: {
      id: string;
      name: string;
      actId?: string;
    }[];
    employerOutletId: string;
    status: string;
    isHq: boolean;
    loading: boolean;
    employerOutletArea?: string;
  };
}

const getEmployerOutletInfo = (id: string) => {
  return API.get()(`/api/employer/outlet/${id}`);
};

export const useEmployerOutletInfoData = (id: string) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return useQuery(['employerOutlet', id], (): any => getEmployerOutletInfo(id), {
    enabled: !!id,
    select: (response: IEmployerOutletInfoResponse) => {
      return response.data;
    },
  });
};

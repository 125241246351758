import { useQuery } from 'react-query';
import { API } from 'api';

export interface IEmployerListData {
  payload: {
    employerName?: string;
    employerId?: string;
    companyNameEid?: string;
    companyName?: string;
    page: number;
  };
  data: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    data: any;
    count: number;
  };
}

export const initEmployerListPayload: IEmployerListData['payload'] = {
  page: 1,
};

const getEmployerList = (data: IEmployerListData['payload']) => {
  return API.get()('/api/employer/all', data);
};

export const useEmployerListData = (data: IEmployerListData['payload']) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return useQuery(['employers', data], (): any => getEmployerList(data), {
    select: (response: IEmployerListData['data']) => {
      return response.data;
    },
  });
};

import { useQuery } from 'react-query';
import { API } from 'api';

export interface IPaymentToBankInfoResponse {
  data: {
    uid: string;
    id: string;
    trxId: string;
    createdOn: string;
    repaymentFilename: string;
    masterAccountNo: string;
    beneficiaryBank: string;
    beneficiaryAccount: string;
    name: string;
    mobile: string;
    paymentMode: string;
    withdrawalAmount: number;
    type: string;
    description: string;
    merchantName?: string;
    externalRefNo?: string;
    quotationPayload?: {
      vehicleModel?: string;
      vehicleNo?: string;
      expiryDate?: string;
      effectiveDate?: string;
      ncdPercent?: string;
    };
    fees?: {
      amount: number;
      status: string;
    };
    remark: string;
    status: string;
    amount: number;
    bonusAmount: number;
    bankName: string;
    approvedAmount: number;
    approvedLimit: number;
    maturityDate: string;
    interestRate: number;
    retryPtbId?: string;
    retryPtbIdTo?: string;
    serviceProvider?: string;
    systemErrorMessage?: string;
  };
}

const getPaymentToBankInfo = (id: string) => {
  return API.get()(`/api/admin/ptb/retrieve/${id}`, { page: 1 });
};

export const usePaymentToBankInfoData = (id: string) => {
  return useQuery(['paymentToBankInfo', id], () => getPaymentToBankInfo(id), {
    select: (response: IPaymentToBankInfoResponse) => {
      return response.data;
    },
  });
};

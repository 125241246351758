import { useMutation, useQueryClient } from 'react-query';
import { API } from 'api';
import { getLatestQuery } from 'common';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';
import { IAddUpdateAdminUserData } from './shared/IAddUpdateAdminUserData';

const updateAdminUserData = (payload: IAddUpdateAdminUserData['payload']) => {
  return API.put()(`/api/admin/admUser/${payload.id}`, payload);
};

export const useUpdateAdminUserData = () => {
  const queryClient = useQueryClient();
  const queryArr = queryClient.getQueriesData('adminUsers');
  const res = getLatestQuery(queryArr, queryClient);
  const dispatch = useDispatch();

  return useMutation(updateAdminUserData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.updatedSuccessful',
        })
      );
      return queryClient.invalidateQueries(['adminUsers', res?.queryParams]);
    },
  });
};

import { API } from 'api';
import { toastPush } from 'modules';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

export interface FileUpload {
  id: string;
  eid: string;
  file: string;
  fineName: string;
  type: string;
  status: string;
  createdBy: string;
  createdOn: number;
  updatedBy?: string;
  updatedOn?: number;
  remark?: string;
}
const getFileUploadsData = () => {
  return API.newGet('/api/admin/fileUpload/list');
};

export const useFileUploadsData = () => {
  const dispatch = useDispatch();

  return useQuery('fileUploads', getFileUploadsData, {
    onError: (error: { statusCode: number; message: string }) => {
      dispatch(
        toastPush({
          code: error.statusCode,
          type: 'error',
          title: 'ERROR',
          body: error?.message,
        })
      );
    },
  });
};

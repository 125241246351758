/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';
import { ISharedMerchantInfoOutput } from '../shared';

interface IMerchantRequestInfoResponse {
  data: {
    id: string;
    mid: string;
    createdBy: string;
    createdOn: number;
    status: string;
    type: string;
    fieldUpdated: string[];
    updatedBy?: {
      aid: string;
      name: string;
      email: string;
      role: string;
      createdOn: number;
      status: string;
    }[];
    data: ISharedMerchantInfoOutput;
    reason?: string;
  };
}

const getMerchantRequestInfo = (mrid: string) => {
  return API.get()(`/api/admin/merchantRequest/${mrid}`);
};

export const useMerchantRequestInfoData = (mrid: string) => {
  return useQuery(['merchantRequest', mrid], (): any => getMerchantRequestInfo(mrid), {
    select: (response: IMerchantRequestInfoResponse) => {
      return response.data;
    },
  });
};

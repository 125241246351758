import { useQuery } from 'react-query';
import { API } from 'api';

export interface IInsightCategorySelectListData {
  data: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
  };
}

const getInsightCategorySelectList = () => {
  return API.newGet('/api/admin/promotionInsightCategory/select', {});
};

export const useInsightCategorySelectListData = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQuery(['selectPromotionInsightCategories'], (): any => getInsightCategorySelectList(), {
    select: (response: IInsightCategorySelectListData['data']) => {
      return response?.data;
    },
  });
};

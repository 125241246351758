import { useQuery } from 'react-query';
import { API } from 'api';
import { reconciliationsQuery } from './graphql';

interface Provider {
  code: string;
}

interface Reconciliation {
  id: string;
  createdAt: string;
  startAt: string;
  endAt: string;
  provider: Provider;
  providerAmount: number;
  payoutAmount: number;
  children: Reconciliation[];
  childrenData: Reconciliation[] | null;
  parentId: string;
  matchStatus: boolean;
}

export interface IPayoutReconciliationListData {
  payload: {
    page?: number;
    matched?: boolean;
    serviceProviderCode?: string;
    startFrom?: string;
    startTo?: string;
  };
  response: {
    data: {
      reconciliations: {
        edges: {
          node: Reconciliation;
        }[];
        totalCount: number;
      };
    };
  };
}

export const initPayoutReconciliationListPayload: IPayoutReconciliationListData['payload'] = {
  page: 1,
};

const getReconciliationListData = async (payload: IPayoutReconciliationListData['payload']) => {
  const { matched, startFrom, startTo, serviceProviderCode } = payload;
  const limit = 10;
  const page = Number(payload.page) ? Number(payload.page) : 1;
  const variables = {
    filters: {
      ...(serviceProviderCode && { provider: { code: serviceProviderCode } }),
      ...(startFrom && { startAt: { gte: startFrom } }),
      ...(startTo && { endAt: { lte: startTo } }),
      ...(matched !== undefined && { matched }),
    },
    sorts: { createdAt: 'DESC' },
  };

  const input = {
    query: reconciliationsQuery.loc?.source.body,
    variables: { ...variables, offset: page === 1 ? 0 : (page - 1) * limit },
  };

  return API.newPost('/api/admin/graphql', input).then((res) => res.data);
};

export const usePayoutReconciliationListData = (data: IPayoutReconciliationListData['payload']) => {
  return useQuery(['ReconciliationList', data], () => getReconciliationListData(data), {
    select: (response: IPayoutReconciliationListData['response']) => {
      const reconciliations = response?.data?.reconciliations;
      return {
        data: reconciliations?.edges?.map(
          (edge: IPayoutReconciliationListData['response']['data']['reconciliations']['edges'][0]) => {
            const { children, ...rest } = edge.node;
            const childrenWithoutLatestItem: any = children.length > 0 ? children.slice(1) : children;
            return {
              ...rest,
              parentId: rest.id,
              ...(children.length > 0
                ? {
                    // override the childrenData with the first child
                    id: children[0].id,
                    matchStatus: Boolean(children[0].payoutAmount === children[0].providerAmount),
                    createdAt: children[0].createdAt,
                    providerAmount: children[0].providerAmount,
                    payoutAmount: children[0].payoutAmount,
                    startAt: children[0].startAt,
                    endAt: children[0].endAt,
                    childrenData: [...childrenWithoutLatestItem, { ...rest }],
                  }
                : { matchStatus: Boolean(rest.payoutAmount === rest.providerAmount), childrenData: null }),
            };
          }
        ),
        count: reconciliations?.totalCount,
      };
    },
  });
};

import { useQuery } from 'react-query';
import { API } from '../../../api';

export interface IGetDeductionFileType {
  payload: {
    id: string;
  };
  data: {
    data: {
      id: string;
      name: string;
      type: string | null;
      mappings: Array<{
        index: number;
        column: string;
        mapping?: string;
        value?: string;
      }>;
      createdOn: number;
      createdBy: string;
      updatedOn: number;
      updatedBy: string;
    };
  };
}

const getDeductionFileType = (data: IGetDeductionFileType['payload']) =>
  API.newGet(`/api/admin/sys/deduction-file/config/${data.id}/view`, {});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// API.newGet(`http://localhost:7101/setting/sys/deduction-file/config/${data.id}/view`, {});

export const useGetDeductionFileType = (data: IGetDeductionFileType['payload']) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQuery(['getDeductionFileTypeList', data], (): any => getDeductionFileType(data), {
    select: (response: IGetDeductionFileType) => {
      return {
        data: {
          id: response.data.data.id,
          name: response.data.data.name,
          value: response.data.data.id,
          mappings: response.data.data.mappings,
          createdOn: response.data.data.createdOn,
          createdBy: response.data.data.createdBy,
          updatedOn: response.data.data.updatedOn,
          updatedBy: response.data.data.updatedBy,
        },
      };
    },
  });
};

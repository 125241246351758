/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';

interface IWorkStatusInfoResponse {
  data: {
    id: string;
    eid: string;
    ebid: string;
    uid: string;
    status: string;
    startTime: string;
    endTime: string;
    description: string;
    outlet: {
      id: string;
      name: string;
    };
    taskScheduleId: string;
    createdOn: string;
    name: string;
    employeeName: string;
    type: string;
    tags: {
      id: string;
      name: string;
    }[];
    bgColor: string;
    images: {
      image: string;
      date: string;
    }[];
    products: {
      id?: string;
      name: string;
      category: string;
      subCategory?: string;
      price: number;
      commission?: number;
    }[];
    commission: number;
    amount: number;
    transactionId: string;
    workRecordId: string;
  };
}

const getWorkStatusInfo = (id: string) => {
  return API.get()(`/api/employer/task/details/${id}`);
};

export const useGetWorkStatusInfoData = (id: string) => {
  return useQuery(['workStatusInfo', id], (): any => getWorkStatusInfo(id), {
    select: (response: IWorkStatusInfoResponse) => {
      return response.data;
    },
  });
};

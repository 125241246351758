import { useMutation } from 'react-query';
import { API } from 'api';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';

export interface UpdateFileUploadData {
  payload: {
    status: 'completed' | 'rejected';
    remark?: string;
  };
}

const updateFileUploadData = ({ id, payload }: { id: string; payload: UpdateFileUploadData['payload'] }) => {
  const { ...rest } = payload;
  return API.newPut(`/api/admin/fileUpload/${id}`, {
    ...rest,
  });
};

export const useUpdateFileUploadData = () => {
  const dispatch = useDispatch();

  return useMutation(updateFileUploadData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.createdSucessful',
        })
      );
    },
    onError: (error: { statusCode: number; message: string }) => {
      dispatch(
        toastPush({
          code: error.statusCode,
          type: 'error',
          title: 'ERROR',
          body: error?.message,
        })
      );
    },
  });
};

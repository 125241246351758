/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';

interface IRoleInfoResponse {
  data: {
    id: string;
    name: string;
    permissions: string[];
    isEmployerRole: boolean;
  };
}

const getRoleInfo = (id: string) => {
  return API.get()(`/api/admin/role/${id}`);
};

export const useRoleInfoData = (id: string) => {
  return useQuery(['role', id], (): any => getRoleInfo(id), {
    enabled: !!id,
    select: (response: IRoleInfoResponse) => {
      return response.data;
    },
  });
};

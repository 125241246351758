/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';

export interface IMerchantListData {
  payload: {
    merchantId?: string;
    name?: string;
    productType?: string;
    productCode?: string;
    page: number;
  };
  data: {
    data: any;
    count: number;
  };
}

export const initMerchantListPayload: IMerchantListData['payload'] = {
  page: 1,
};

const getMerchantList = (data: IMerchantListData['payload']) => {
  return API.get()('/api/admin/merchant/all', data);
};

export const useMerchantListData = (data: IMerchantListData['payload']) => {
  return useQuery(['merchants', data], (): any => getMerchantList(data), {
    select: (response: IMerchantListData['data']) => {
      return response.data;
    },
  });
};

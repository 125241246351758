import { useMutation } from 'react-query';
import { API } from 'api';
import { IInsightCategoryPayload } from './sharedInterface';

const bulkUpdateInsightCategoryPositionData = (payload: IInsightCategoryPayload) => {
  return API.newPut(`api/admin/bulkUpdatePosition/promotionInsightCategory`, payload);
};

export const useBulkUpdateInsightCategoryPositionData = () => {
  return useMutation(bulkUpdateInsightCategoryPositionData);
};

import { API } from 'api';
import { useQuery } from 'react-query';

export interface IEmployeeCityListResponse {
  data: {
    data: string[];
  };
}

const getEmployeeCityList = (query: string) => {
  return API.newGet(`/api/admin/pub/city-name?searchTerm=${query}`);
};

export const useEmployeeCityList = (query: string) => {
  return useQuery(['employeeCityList', query], () => getEmployeeCityList(query), {
    select: (response: IEmployeeCityListResponse) => {
      const filteredResp = Array.from(new Set(response?.data?.data));
      return filteredResp.sort();
    },
  });
};

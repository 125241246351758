/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';

export interface IEmployerRequestListData {
  payload: {
    page?: number;
    from?: number;
    to?: number;
    name?: string;
    eid?: string;
    companyNameEid?: string;
    status?: string;
  };
  data: {
    data: any;
    count: number;
  };
}

export const initEmployerRequestListPayload: IEmployerRequestListData['payload'] = {
  page: 1,
  status: 'pending',
};

const getEmployerRequestListData = (data: IEmployerRequestListData['payload']) => {
  return API.get()('/api/admin/employerRequest/all', data);
};

export const useEmployerRequestListData = (data: IEmployerRequestListData['payload']) => {
  return useQuery(['employerRequests', data], (): any => getEmployerRequestListData(data), {
    select: (response: IEmployerRequestListData['data']) => {
      return response.data;
    },
  });
};

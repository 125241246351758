import { useQuery } from 'react-query';
import { API } from 'api';
import { isUndefinedOrNullOrEmpty } from 'common';

export interface IEmployerStatisticsData {
  payload: {
    eid: string;
  };
  data: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    currentCycle: {
      tag: string;
      date: string;
    };
    upcomingCycle: {
      tag: string;
      date: string;
    };
    totalRegisterUserCount: number;
    daysRemaining: number;
  };
}

const getEmployerStatisticsData = () => {
  return API.get()('/api/employer/statistics/toDate');
};

export const useEmployerStatisticsData = (payload: IEmployerStatisticsData['payload']) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return useQuery(['employersStatistics'], (): any => getEmployerStatisticsData(), {
    enabled: !isUndefinedOrNullOrEmpty(payload.eid),
    select: (response: IEmployerStatisticsData) => {
      return response.data;
    },
  });
};

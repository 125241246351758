import { gql } from 'graphql-tag';

export const maintenanceWindowsQuery = gql`
  query maintenanceWindowsQuery(
    $offset: Float
    $limit: Float
    $filters: MaintenanceWindowFilterArgs
    $sorts: MaintenanceWindowSortArgs
  ) {
    maintenanceWindows(limit: $limit, offset: $offset, filters: $filters, sorts: $sorts) {
      edges {
        node {
          id
          provider {
            id
            name
            code
          }
          startDay
          startTime
          endDay
          endTime
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const providersBasicQuery = gql`
  query providersBasic($offset: Float, $limit: Float, $filters: ProviderFilterArgs, $sorts: ProviderSortArgs) {
    providers(limit: $limit, offset: $offset, filters: $filters, sorts: $sorts) {
      totalCount
      edges {
        node {
          id
          name
          code
        }
      }
    }
  }
`;

export const providersAdvanceQuery = gql`
  query providersAdvance($offset: Float, $limit: Float, $filters: ProviderFilterArgs, $sorts: ProviderSortArgs) {
    providers(limit: $limit, offset: $offset, filters: $filters, sorts: $sorts) {
      totalCount
      edges {
        node {
          id
          name
          code
          createdAt
          updatedAt
          maintenanceWindows {
            id
            startDay
            startTime
            endDay
            endTime
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const payoutsQuery = gql`
  query payouts($offset: Float, $filters: PayoutFilterArgs, $sorts: PayoutSortArgs) {
    payouts(limit: 10, offset: $offset, filters: $filters, sorts: $sorts) {
      edges {
        node {
          id
          createdAt
          metadata
          status
          paymentToBankId
          reconciliationId
          reconciliationRemark
          financeReconciliationId
          financeReconciliationRemark
          latestAuditLog {
            paywatchErrorCode
          }
          provider {
            code
          }
        }
      }
      totalCount
    }
  }
`;

export const reconciliationsQuery = gql`
  query Reconciliations(
    $limit: Float
    $offset: Float
    $sorts: ReconciliationSortArgs
    $filters: ReconciliationFilterArgs
  ) {
    reconciliations(limit: $limit, offset: $offset, sorts: $sorts, filters: $filters) {
      totalCount
      edges {
        node {
          id
          children {
            id
            endAt
            createdAt
            parentId
            payoutAmount
            provider {
              id
              name
              code
            }
            providerAmount
            providerChargesAmount
            providerId
            startAt
            updatedAt
          }
          createdAt
          endAt
          parentId
          payoutAmount
          provider {
            id
            code
            name
          }
          providerAmount
          providerChargesAmount
          providerId
          startAt
          updatedAt
        }
      }
    }
  }
`;

import { API } from 'api';
import { useMutation } from 'react-query';

interface ISubmitZendeskTicket {
  payload: {
    data: string;
  };
}

const submitZendeskTicket = (payload: ISubmitZendeskTicket['payload']) => {
  return API.newPost('/api/admin/pub/ticket/admin/unusual-activity', payload, { 'Z-Ticket': payload.data });
};

export const useSubmitZendeskTicketData = () => {
  return useMutation(submitZendeskTicket);
};

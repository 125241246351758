import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { isUndefinedOrNullOrEmpty } from 'common';
import { notificationListFetch, RootState, selectIsLoggedIn, setIsLoggedIn } from 'modules';
import { useCookies } from 'react-cookie';

export type ProtectedRouteProps = {
  isAuthenticated?: boolean;
  authenticationPath?: string;
  redirectPath?: string;
  setRedirectPath?: (path: string) => void;
  permission?: string[];
} & RouteProps;

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  let isLoggedIn = useSelector<RootState, boolean>((state) => selectIsLoggedIn(state));
  const users = useSelector((state: RootState) => state.user);
  const [cookies] = useCookies(['expiredOn']);
  const renderComponent = () => <Redirect to={{ pathname: '/login' }} />;
  const renderRedirectComponent = (pathname: string) => <Redirect to={{ pathname }} />;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    localStorage.removeItem('pathname');
    if (!isLoggedIn) {
      localStorage.setItem('pathname', location.pathname);
    }

    if (isLoggedIn && ['/employer-company', '/merchant/list'].indexOf(location.pathname) === -1) {
      const interval = setInterval(() => {
        const sessionStorageData = localStorage.getItem('persist:user');
        if (sessionStorageData != null) {
          const { user } = JSON.parse(sessionStorageData);
          const { isLoggedIn: isLoggedInData } = JSON.parse(user);
          // eslint-disable-next-line react-hooks/exhaustive-deps
          isLoggedIn = isLoggedInData;
        }
        if (isLoggedIn) dispatch(notificationListFetch());
        clearInterval(interval);
      }, 3000);
    }
  }, [isLoggedIn, cookies.expiredOn]);

  if (isLoggedIn) {
    if (isUndefinedOrNullOrEmpty(cookies.expiredOn)) {
      dispatch(setIsLoggedIn(false));
      return <Route {...props} component={renderComponent} />;
    }

    if (!isUndefinedOrNullOrEmpty(cookies.expiredOn)) {
      const timeNow = new Date().getTime() / 1000;
      if (timeNow >= Number(cookies.expiredOn)) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Route {...props} component={renderComponent} />;
      }
    }

    if (localStorage.getItem('pathname') !== null) {
      const pathname = localStorage.getItem('pathname');
      if (pathname?.startsWith('/employer-company') && isUndefinedOrNullOrEmpty(users?.employers)) {
        return <Route {...props} component={() => renderRedirectComponent('/dashboard')} />;
      }

      if (!isUndefinedOrNullOrEmpty(users.employers) && users.employers.length > 1) {
        return <Route {...props} component={() => renderRedirectComponent('/employer-company')} />;
      }

      return <Route {...props} component={() => renderRedirectComponent(pathname || '/')} />;
    }

    // if (!isUndefinedOrNullOrEmpty(users.eid)) {
    //   window.location.href = `${process.env.REACT_APP_EMPLOYER_PORTAL_URL}/dashboard`;
    // }

    return <Route {...props} />;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...props} component={renderComponent} />;
};

import { useQuery } from 'react-query';
import { API } from 'api';

export interface IEmployeeEkycInfoListData {
  payload: {
    uid: string;
  };
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: any[];
}

export const payload: IEmployeeEkycInfoListData['payload'] = {
  uid: '',
};

const getEmployeeEkycInfoList = (data: IEmployeeEkycInfoListData['payload']) => {
  return API.newGet(`/api/admin/ekyc/application`, data);
};

export const useEmployeeEkycInfoListData = (data: IEmployeeEkycInfoListData['payload']) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return useQuery(['ekycInfoList', data], (): any => getEmployeeEkycInfoList(data), {
    enabled: !!data.uid,
    select: (response: IEmployeeEkycInfoListData) => {
      return response;
    },
  });
};

import moment from 'moment';
import { useQuery } from 'react-query';
import { API } from 'api';
import { useSelector } from 'react-redux';
import { RootState } from 'modules';
import { PermissionId, checkAccessPermission } from 'common';

export interface ITransactionListData {
  payload: {
    page?: number;
    eid?: string;
    uid?: string;
    employeeId?: string;
    employmentName?: string;
    trxRef?: string;
    paymentsToBankId?: string;
    trxType?: string;
    mobile?: string;
    dateFrom?: number;
    dateTo?: number;
    download: boolean;
  };
  data: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    data: any;
    count: number;
  };
}

export const initTransactionListPayload: ITransactionListData['payload'] = {
  page: 1,
  dateFrom: moment().startOf('month').unix(),
  dateTo: moment().unix(),
  download: false,
};

const getTransactionList = (data: ITransactionListData['payload']) => {
  return API.get()('/api/admin/transactions/retrieve', data);
};

export const useTransactionListData = (data: ITransactionListData['payload']) => {
  const permissions = useSelector((state: RootState) => state.user.permissions);

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return useQuery(['transactions', data], (): any => getTransactionList(data), {
    enabled: checkAccessPermission(permissions, [
      PermissionId.EMPLOYEE_TRANSACTION_READ,
      PermissionId.PAYMENT_TRANSACTION_READ,
    ]),
    select: (response: ITransactionListData['data']) => {
      return response.data;
    },
  });
};

import { useMutation, useQueryClient } from 'react-query';
import { API } from 'api';
import { useDispatch } from 'react-redux';
import { toastPush } from 'modules';

export interface IUpdateServiceProviderData {
  payload: {
    id: string;
    withdrawalLimit: {
      min: number;
      max: number;
      twoFAPrompt: number;
      daily: number;
      monthly: number;
    };
    monthlyProductlLimit: number;
    overrideServiceProviderEnable: boolean;
    overrideServiceProvider: string | null;
  };
}

const updateServiceProviderData = (payload: IUpdateServiceProviderData['payload']) => {
  if (payload.overrideServiceProviderEnable === false) {
    payload.overrideServiceProvider = null;
  }

  return API.newPost('/api/admin/serviceProvider/update', payload);
};

export const useUpdateServiceProviderData = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(updateServiceProviderData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.updatedSuccessfulReflectNextDay',
        })
      );
      return queryClient.invalidateQueries(['serviceProvider']);
    },
  });
};

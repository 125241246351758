import { useMutation } from 'react-query';
import { API } from 'api';
import { useDispatch } from 'react-redux';
import { toastPush } from 'modules';

export interface IEmployeeEmploymentDownloadData {
  payload: {
    name?: string;
    mobile?: string;
    eid?: string;
    staffId?: string;
    userId?: string;
    download?: boolean;
  };
}

export const initEmployeeEmploymentDownloadPayload: IEmployeeEmploymentDownloadData['payload'] = {
  download: false,
};

const getEmployeeEmploymentDownload = (data: IEmployeeEmploymentDownloadData['payload']) => {
  return API.get()('/api/employer/employment/list', data);
};

export const useEmployeeEmploymentDownloadData = () => {
  const dispatch = useDispatch();
  return useMutation(getEmployeeEmploymentDownload, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.downloadReportSuccessPleaseCheckMailbox',
        })
      );
    },
  });
};

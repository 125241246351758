/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';

export interface IReconciliationListData {
  payload: {
    page?: number;
    createdDate?: string;
    type?: string;
    serviceProvider?: string;
  };
  data: {
    data: any;
    count: number;
  };
}

export const initReconciliationListPayload: IReconciliationListData['payload'] = {
  page: 1,
  type: 'reconciliation',
};

const getReconciliationListData = (data: IReconciliationListData['payload']) => {
  return API.get()('/api/admin/financeFiles/HLBB', data);
};

export const useReconciliationListData = (data: IReconciliationListData['payload']) => {
  return useQuery(['reconciliations', data], (): any => getReconciliationListData(data), {
    select: (response: IReconciliationListData['data']) => {
      return response.data;
    },
  });
};

import { useMutation, useQueryClient } from 'react-query';
import { API } from 'api';
import { getLatestQuery } from 'common';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';

export interface IAddEmployerOutletData {
  payload: {
    id?: string;
    eid: string;
    name: string;
    address: string;
    companyName: string;
    location: {
      lat: number;
      long: number;
    };
    qr: {
      qrImage: string;
      qrImageTemplate: string;
    };
    phoneNumber: string;
    employees: {
      id: string;
      name: string;
    }[];
    employerOutletId: string;
    status: string;
    employerOutletArea?: string;
  };
}

const addEmployerOutletData = (payload: IAddEmployerOutletData['payload']) => {
  return API.post()('/api/employer/outlet/create', payload);
};

export const useAddEmployerOutletData = () => {
  const queryClient = useQueryClient();
  const queryArr = queryClient.getQueriesData('employerOutlets');
  const res = getLatestQuery(queryArr, queryClient);
  const dispatch = useDispatch();

  return useMutation(addEmployerOutletData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.createdSucessful',
        })
      );
      return queryClient.invalidateQueries('employerOutlets', res.queryParams);
    },
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, useMutation } from 'react-query';
import { API } from 'api';

export interface IEmployerOrEmployerRequestFileImageData {
  payload: {
    objectId?: string;
    key: string;
  };
  response: {
    data: string;
  };
}

const getEmployerOrEmployerRequestFileImage = (payload: IEmployerOrEmployerRequestFileImageData['payload']) => {
  if (payload.key === 'onboardAgent' || payload.key === 'onboard')
    return API.newGetBlob(`/api/admin/download/file`, payload);

  return API.get()(`/api/admin/download/file`, payload);
};

export const useEmployerOrEmployerRequestFileImageData = (
  payload: IEmployerOrEmployerRequestFileImageData['payload']
) => {
  return useQuery(
    ['employerOrEmployerRequestFileImage', payload],
    (): any => getEmployerOrEmployerRequestFileImage(payload),
    {
      staleTime: Infinity,
      enabled: !!payload.objectId,
      select: (response: IEmployerOrEmployerRequestFileImageData['response']) => {
        return response.data;
      },
    }
  );
};

export const useMutateEmployerOrEmployerRequestFileImageData = () => {
  return useMutation(getEmployerOrEmployerRequestFileImage);
};

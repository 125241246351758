/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';

export interface IAdminUserListData {
  payload: {
    page?: number;
    name?: string;
    email?: string;
    role?: string;
  };
  data: {
    data: any;
    count: number;
  };
}

export const initAdminUserListPayload: IAdminUserListData['payload'] = {
  page: 1,
};

const getAdminUserListData = (data: IAdminUserListData['payload']) => {
  return API.get()('/api/admin/admUser/list', data);
};

export const useAdminUserListData = (data: IAdminUserListData['payload']) => {
  return useQuery(['adminUsers', data], (): any => getAdminUserListData(data), {
    select: (response: IAdminUserListData['data']) => {
      const result = response?.data?.data?.map((x: any) => {
        return {
          ...x,
          email: x.profile?.email,
          name: x.profile?.name,
        };
      });

      return {
        data: result,
        count: response?.data.count,
      };
    },
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQueryClient } from 'react-query';
import { API } from 'api';

export interface IUpdateEmployerOutletData {
  payload: {
    id?: string;
    eid: string;
    name: string;
    address: string;
    companyName: string;
    location: {
      lat: number;
      long: number;
    };
    qr: {
      qrImage: string;
      qrImageTemplate: string;
    };
    phoneNumber: string;
    employees: {
      id: string;
      name: string;
    }[];
    employerOutletId: string;
    status: string;
    employerOutletArea?: string;
  };
}

const updateEmployerOutletData = (payload: IUpdateEmployerOutletData['payload']) => {
  return API.put()(`/api/employer/outlet/${payload.id}`, payload);
};

export const useUpdateEmployerOutletData = () => {
  const queryClient = useQueryClient();

  return useMutation(updateEmployerOutletData, {
    onSuccess: (_: any, variables: IUpdateEmployerOutletData['payload']) => {
      return queryClient.invalidateQueries(['employerOutlet', variables.id]);
    },
  });
};

import { useMutation, useQueryClient } from 'react-query';
import { API } from 'api';
import { getLatestQuery } from 'common';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';

interface IResetAdminUserPassword {
  aid: string;
}

const resetAdminUserData = (payload: IResetAdminUserPassword) => {
  return API.post()('/api/admin/reset/pwd', payload);
};

export const useResetAdminUserPassword = () => {
  const queryClient = useQueryClient();
  const queryArr = queryClient.getQueriesData('adminUsers');
  const res = getLatestQuery(queryArr, queryClient);
  const dispatch = useDispatch();

  return useMutation(resetAdminUserData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.resetPwdSuccessful',
        })
      );
      return queryClient.invalidateQueries('adminUsers', res.queryParams);
    },
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from '../../../api';

export interface IPaymentMerchantMdrListData {
  payload: {
    id?: string;
    merchantId?: string;
    merchantNameId?: string;
    page: number;
  };
  data: {
    data: any;
    count: number;
  };
}

export const initPaymentMerchantMdrListPayload: IPaymentMerchantMdrListData['payload'] = {
  page: 1,
};

const getPaymentMerchantMdrList = (data: IPaymentMerchantMdrListData['payload']) => {
  return API.newGet('/api/admin/merchantProductMdr/all', data);
};

export const useGetPaymentMerchantMdrList = (data: IPaymentMerchantMdrListData['payload']) => {
  return useQuery(['paymentMerchantMdrList', data], (): any => getPaymentMerchantMdrList(data), {
    select: (res: IPaymentMerchantMdrListData['data']) => {
      return res.data;
    },
  });
};

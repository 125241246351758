import { useQuery } from 'react-query';
import { API } from 'api';

export interface IStatementOfAccountListData {
  payload: {
    page?: number;
    eid?: string;
    month?: number;
  };
  data: {
    data: {
      id: string;
      eid: string;
      employerName: string;
      month: string;
      statementFile: string;
      statementFileName: string;
      statementDetailsFile: string;
      statementDetailsFileName: string;
      type: string;
      startDate: number;
      endDate: number;
      createdOn: number;
      total: number;
    }[];
    count: number;
  };
}

export const initStatementOfAccountListPayload: IStatementOfAccountListData['payload'] = {
  page: 1,
};

const getStatementOfAccountListData = (payload: IStatementOfAccountListData['payload']) => {
  return API.get()('/api/admin/finance/b2bStatementDetails', payload).then((res) => res.data);
};

export const useStatementOfAccountListData = (data: IStatementOfAccountListData['payload']) => {
  return useQuery(['StatementOfAccountList', data], () => getStatementOfAccountListData(data), {
    select: (response: IStatementOfAccountListData['data']) => {
      return response;
    },
  });
};

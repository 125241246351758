/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQueryClient } from 'react-query';
import { API } from 'api';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';

interface IUpdateWorkStatusCheckerData {
  payload: {
    taskAssignedId: string;
    status?: 'approve' | 'reject';
    reason?: string;
    product?: {
      name: string;
      category: string;
      subCategory: string;
      price: number;
    }[];
  };
}

const updateWorkStatusCheckerData = (payload: IUpdateWorkStatusCheckerData['payload']) => {
  return API.newPost('/api/employer/scheduleTask/update', payload);
};

export const useUpdateWorkStatusCheckerData = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(updateWorkStatusCheckerData, {
    onSuccess: (_: any, variables: IUpdateWorkStatusCheckerData['payload']) => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.updatedSuccessful',
        })
      );
      queryClient.invalidateQueries(['workStatusInfo', variables.taskAssignedId]);
    },
  });
};

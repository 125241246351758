import { useMutation } from 'react-query';
import { API } from 'api';
import fileDownload from 'js-file-download';
import { blobToString, downloadFileByBase64Str, isBase64 } from 'common';

export interface IReconciliationFileDownload {
  payload: {
    id: string;
  };
}

const ReconciliationFileDownload = (payload: IReconciliationFileDownload['payload']) => {
  return API.newPostBlob(`/api/admin/v1/reconciliation/${payload.id}/download`);
};

export const useReconciliationFileDownload = () => {
  return useMutation(ReconciliationFileDownload, {
    onSuccess: async (data, payload: IReconciliationFileDownload['payload']) => {
      const result = await blobToString(data.data);
      if (isBase64(result) || result.includes('https://')) {
        return downloadFileByBase64Str(result, `Recon_${payload.id}.xlsx`);
      }
      return fileDownload(data.data, `Recon_${payload.id}.xlsx`);
    },
  });
};

import { useQuery } from 'react-query';
import { API } from '../../../api';

export interface IEmployeeAttendanceUploadListData {
  payload: {
    dateForm?: string;
    dateTo?: string;
    fileName?: string;
    page: number;
  };
  data: {
    data?: {
      id: string;
      eid: string;
      file: string;
      fileName: string;
      status: string;
      type: string;
      createdOn: number;
      createdBy: string;
    }[];
    count: number;
  };
}

export const initEmployeeAttendanceUploadListPayload: IEmployeeAttendanceUploadListData['payload'] = {
  page: 1,
};

const employeeAttendanceUploadList = async (payload: IEmployeeAttendanceUploadListData['payload']) => {
  return API.newGet(`/api/admin/employee/attendance/upload/all`, payload).then((res) => res.data);
};

export const useEmployeeAttendanceUploadListData = (payload: IEmployeeAttendanceUploadListData['payload']) => {
  return useQuery(['employeeAttendanceUploadList', payload], () => employeeAttendanceUploadList(payload), {
    select: (response: IEmployeeAttendanceUploadListData['data']) => {
      return response;
    },
  });
};

import { useMutation, useQueryClient } from 'react-query';
import { API } from 'api';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';
import { IInsightCategoryPayload } from './sharedInterface';

const updateInsightCategoryData = (payload: IInsightCategoryPayload) => {
  return API.newPut(`api/admin/promotionInsightCategory/${payload.pid}`, payload);
};

export const useUpdateInsightCategoryData = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(updateInsightCategoryData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.updatedSuccessful',
        })
      );

      queryClient.invalidateQueries(['promotionInsightCategories']);
    },
  });
};

import { useMutation } from 'react-query';
import { API } from 'api';

export type promotionTypeHook = 'banner' | 'insight' | 'ticker';

const verifyActivePromotionData = (type: promotionTypeHook) => {
  return API.newGet(`api/admin/promotion/${type}/verifyActive`);
};

export const useVerifyActivePromotionData = () => {
  return useMutation(verifyActivePromotionData);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from '../../../api';
import { isUndefinedOrNullOrEmpty } from '../../../common';

export interface IOutletEmployeesData {
  payload: {
    eid?: string;
    outletId?: string;
    page?: number;
  };
  data: {
    data: any;
    count: number;
  };
}

export const initOutletEmployeesPayload: IOutletEmployeesData['payload'] = {
  page: 1,
};

const getOutletEmployeesData = (data: IOutletEmployeesData['payload']) => {
  return API.get()('api/admin/outlets/employee', data);
};

export const useGetOutletEmployeesData = (data: IOutletEmployeesData['payload']) => {
  return useQuery(['outletEmployees', data], (): any => getOutletEmployeesData(data), {
    enabled: !isUndefinedOrNullOrEmpty(data.eid),
    select: (response: IOutletEmployeesData['data']) => {
      return response.data;
    },
  });
};

import { API } from 'api';
import { toastPush } from 'modules';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { IGetOtpInfo } from './useGetCountdownOtpData';

interface IResendOtp {
  payload: {
    aid: string;
    email: string;
  };
}

const resendOtp = (payload: IResendOtp['payload']) => {
  return API.newPost('/api/admin/pub/resend/otp', payload);
};

export const useResendOtpData = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(resendOtp, {
    onSuccess: (data: IGetOtpInfo['data']) => {
      queryClient.invalidateQueries(['otpInfo', data.data]);
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.resendOtpSuccessful',
        })
      );
    },
  });
};

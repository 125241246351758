import { useQuery } from 'react-query';
import { API } from 'api';

export interface IPaymentToBankListData {
  payload: {
    page?: number;
    dateFrom?: number;
    dateTo?: number;
    uid?: string;
    aid?: string;
    type?: string;
    status?: string;
    trxNum?: string;
    ptbId?: string;
    masterAccountNo?: string;
    repaymentFilename?: string;
  };
  data: {
    data: {
      id: string;
    }[];
    count: number;
  };
}

export const initPaymentToBankListPayload: IPaymentToBankListData['payload'] = {
  page: 1,
};

const getPaymentToBankListData = (payload: IPaymentToBankListData['payload']) => {
  return API.get()('/api/admin/ptb/retrieve/all', payload).then((res) => res.data);
};

export const usePaymentToBankListData = (data: IPaymentToBankListData['payload']) => {
  return useQuery(['paymentToBankList', data], () => getPaymentToBankListData(data), {
    select: (response: IPaymentToBankListData['data']) => {
      return response;
    },
  });
};

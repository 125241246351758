import { useMutation, useQueryClient } from 'react-query';
import { API } from 'api';
import { getLatestQuery } from 'common';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';

export interface IGenerateDailySummaryFileData {
  date: string;
}

const generateDailySummaryFile = (payload: IGenerateDailySummaryFileData) => {
  return API.newPost('/api/admin/recon/daily/summary/regenerate', payload);
};

export const useGenerateDailySummaryFileData = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const key = 'dailySummaryRegenerate';
  const queryArr = queryClient.getQueriesData(key);
  const res = getLatestQuery(queryArr, queryClient);

  return useMutation(generateDailySummaryFile, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.fileGeneratedSucessful',
        })
      );
      return queryClient.invalidateQueries(key, res.queryParams);
    },
  });
};

import { useMutation, useQueryClient } from 'react-query';
import { API } from 'api';
import { getLatestQuery } from 'common';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';

export interface IAddLapseRepaymentData {
  payload: {
    ebid: string;
    id: string;
    status: string;
    reason: string;
  };
}

const updateLapseRepaymentData = (payload: IAddLapseRepaymentData['payload']) => {
  return API.put()(`/api/employee/${payload.id}/company/${payload.ebid}`, payload);
};

export const useUpdateLapseRepaymentData = () => {
  const queryClient = useQueryClient();
  const queryArr = queryClient.getQueriesData('lapserepayments');
  const res = getLatestQuery(queryArr, queryClient);
  const dispatch = useDispatch();

  return useMutation(updateLapseRepaymentData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.updatedSuccessful',
        })
      );
      return queryClient.invalidateQueries(['lapseRepayments', res.queryParams]);
    },
  });
};

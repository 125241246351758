/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from '../../../api';
import { isUndefinedOrNullOrEmpty } from '../../../common';

interface IPaymentMerchantMdrInfoResponse {
  data: {
    id: string;
    mid: string;
    merchantName: string;
    name: string;
    productType: string;
    productName: string;
    chargeType: string;
    mdr: number;
  };
}

const getPaymentMerchantMdrInfo = (id: string) => {
  return API.newGet(`/api/admin/merchantProductMdr/${id}`);
};

export const useGetPaymentMerchantMdrInfo = (id: string) => {
  return useQuery(['paymentMerchantInfo', id], (): any => getPaymentMerchantMdrInfo(id), {
    enabled: !isUndefinedOrNullOrEmpty(id),
    select: (res: IPaymentMerchantMdrInfoResponse) => {
      return res.data;
    },
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';

export interface IMerchantSelectionData {
  data: {
    data: any;
  };
}

const getMerchantSelection = () => {
  return API.get()('/api/admin/merchant/select');
};

export const useMerchantSelectionData = () => {
  return useQuery(['merchantSelections'], (): any => getMerchantSelection(), {
    select: (response: IMerchantSelectionData['data']) => {
      return response.data;
    },
  });
};

import { useQuery } from 'react-query';
import { API } from '../../../api';

export interface IServiceProviderInfoResponse {
  data: {
    id: string;
    displayName: string;
    withdrawalLimit: {
      min: number;
      max: number;
      needOtp: number;
      daily: number;
      monthly: number;
      twoFAPrompt: number;
      quickWithdrawalAmount: number;
    };
    monthlyProductlLimit: number;
    withdrawalEnabled: boolean;
    overrideServiceProviderEnable: boolean;
    overrideServiceProvider: string | null;
  }[];
}

const getServiceProviderInfo = () => {
  return API.newGet('/api/admin/serviceProvider/retrieve').then((res) => res.data);
};

export const useServiceProviderInfo = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQuery(['serviceProvider'], (): any => getServiceProviderInfo(), {
    select: (res: IServiceProviderInfoResponse['data']) => {
      return res;
    },
  });
};

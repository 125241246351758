import { useQuery } from 'react-query';
import { API } from 'api';

export interface IEmployeeLapseRepaymentListData {
  payload: {
    id: string;
  };
  data: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    records: any[];
  };
}

export const initLapseRepaymentData: IEmployeeLapseRepaymentListData['payload'] = {
  id: '',
};

const getEmployeeLapseRepaymentList = (data: IEmployeeLapseRepaymentListData['payload']) => {
  return API.newGet(`/api/employee/${data.id}/lapsepayment`, data);
};

export const useEmployeeLapseRepaymentListData = (data: IEmployeeLapseRepaymentListData['payload']) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return useQuery(['lapseRepayments', data.id], (): any => getEmployeeLapseRepaymentList(data), {
    enabled: !!data.id,
    select: (response: IEmployeeLapseRepaymentListData) => {
      return response.data;
    },
  });
};

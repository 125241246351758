import { useMutation } from 'react-query';
import { API } from 'api';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateUpdateAppHomeBackground = (payload: any) => {
  const formData = new FormData();
  formData.append('fileData', payload.file || '');

  return API.newPut(`api/admin/appHomeBackground`, formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const useUpdateAppHomeBackgroundData = () => {
  const dispatch = useDispatch();

  return useMutation(updateUpdateAppHomeBackground, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.updatedSuccessful',
        })
      );
    },
  });
};

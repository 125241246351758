/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { API } from 'api';
import { getLatestQuery } from 'common';
import { toastPush } from 'modules';

export interface ICreateDeductionFileType {
  payload: {
    name: string;
    mappings: Array<{
      column: string;
      mapping?: string;
      value?: string;
    }>;
  };
  data: {
    data: any;
  };
}

const createDeductionFileType = (data: ICreateDeductionFileType['payload']) =>
  API.post()(`/api/admin/sys/deduction-file/config/create`, data);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// API.post()(`http://localhost:7101/setting/sys/deduction-file/config/create`, data);

export const useCreateDeductionFileType = () => {
  const queryClient = useQueryClient();
  const queryArr = queryClient.getQueriesData('getDeductionFileTypeList');
  const request = getLatestQuery(queryArr, queryClient);
  const dispatch = useDispatch();

  return useMutation(createDeductionFileType, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.createdSucessful',
        })
      );
      return queryClient.invalidateQueries('getDeductionFileTypeList', request.queryParams);
    },
  });
};

import { useMutation, useQueryClient } from 'react-query';
import { API } from 'api';
import { getLatestQuery } from 'common';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';

export interface IUpdateEmployerReportData {
  payload: {
    employerReportId: string;
    status: string;
    withdrawalCycleId: string;
    reason?: string;
    isSendToHRMS: boolean;
  };
}

const updateEmployerReportData = (payload: IUpdateEmployerReportData['payload']) => {
  return API.put()(`/api/admin/financeFiles/employerReport/update`, payload);
};

export const useUpdateEmployerReportData = () => {
  const queryClient = useQueryClient();
  const queryArr = queryClient.getQueriesData('employerReports');
  const res = getLatestQuery(queryArr, queryClient);
  const dispatch = useDispatch();

  return useMutation(updateEmployerReportData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.updatedSuccessful',
        })
      );
      return queryClient.invalidateQueries(['employerReports', res.queryParams]);
    },
  });
};

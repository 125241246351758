import { API } from 'api';
import { AppInfoType, camelCase } from 'common';
import { AllISOData } from 'modules';
import { useQuery } from 'react-query';

export interface IGetWithdrawalConfigResponse {
  data: {
    employer: {
      ltv: number;
      limit: {
        min: number;
        max: number;
      };
    };
    employee: {
      ltv: number;
      defaultEligibleEarning: number;
      limit: {
        min: number;
        max: number;
      };
    };
  };
}
export interface IGetFeeListConfigResponse {
  data: {
    withdrawalFee: number;
    withdrawalFeeType: string;
  };
}

export interface IGetCountryUtilResponse {
  data: {
    countryListWithAllISOData: AllISOData[];
    sanctionCountries: string[];
  };
}

export interface IGetBankListConfigResponse {
  data: {
    duitNow: any[];
  };
}

const getConfigSetting = async (id: AppInfoType) => {
  const res = await API.newGet(`/api/admin/app/info/${id}`);
  return res.data;
};

export const useGetConfigSettingData = (id: AppInfoType) => {
  return useQuery([id], () => getConfigSetting(id), {
    select: (
      response:
        | IGetWithdrawalConfigResponse['data']
        | IGetFeeListConfigResponse['data']
        | IGetCountryUtilResponse['data']
        | IGetBankListConfigResponse['data']
    ) => {
      return response;
    },
  });
};

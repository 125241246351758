import moment from 'moment';
import { useQuery } from 'react-query';
import { API } from 'api';

export interface IEmployeeTransactionHistoryListData {
  payload: {
    page: number;
    id: string;
    from?: number;
    to?: number;
  };
  data: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    data: any;
    count: number;
  };
}

export const initTransactionHistoryListPayload: IEmployeeTransactionHistoryListData['payload'] = {
  page: 1,
  id: '',
  from: moment().subtract(90, 'days').startOf('day').unix(),
  to: moment().endOf('day').unix(),
};

const getEmployeeTransactionHistoryList = (data: IEmployeeTransactionHistoryListData['payload']) => {
  return API.newGet(`/api/employee/${data.id}/transaction`, data);
};

export const useEmployeeTransactionHistoryListData = (data: IEmployeeTransactionHistoryListData['payload']) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return useQuery(['employeeTransactionHistories', data], (): any => getEmployeeTransactionHistoryList(data), {
    enabled: !!data.id,
    select: (response: IEmployeeTransactionHistoryListData) => {
      return response.data;
    },
  });
};

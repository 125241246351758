import { useQuery } from 'react-query';
import { API } from 'api';

interface IEmployeeCompanyRecordResponse {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: any;
}

const getEmployeeCompanyRecordList = (id: string) => {
  return API.newGet(`/api/employee/${id}/company/all`);
};

export const useEmployeeCompanyRecordListData = (id: string) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return useQuery(['employeeCompanyRecords', id], (): any => getEmployeeCompanyRecordList(id), {
    enabled: !!id,
    select: (response: IEmployeeCompanyRecordResponse) => {
      return response.data?.records;
    },
  });
};

import { useMutation, useQueryClient } from 'react-query';
import { API } from 'api';
import { getLatestQuery } from 'common';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';

export interface IUpdateInvoiceData {
  payload: {
    invoiceId?: string;
    status?: string;
    reason?: string;
  };
}

const updateInvoiceData = (payload: IUpdateInvoiceData['payload']) => {
  return API.put()('/api/admin/invoices/update', payload);
};

export const useUpdateInvoiceData = () => {
  const queryClient = useQueryClient();
  const queryArr = queryClient.getQueriesData('invoices');
  const res = getLatestQuery(queryArr, queryClient);
  const dispatch = useDispatch();

  return useMutation(updateInvoiceData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.updatedSuccessful',
        })
      );
      return queryClient.invalidateQueries(['invoices', res.queryParams]);
    },
  });
};

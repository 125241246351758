import { useQuery } from 'react-query';
import { API } from 'api';

interface ITransactionInfoResponse {
  data: {
    id: string;
    createdOn: string;
    name: string;
    mobile: string;
    type: string;
    description: string;
    merchantName?: string;
    externalRefNo?: string;
    uid: string;
    employeeId: string;
    quotationPayload?: {
      vehicleModel?: string;
      vehicleNo?: string;
      expiryDate?: string;
      effectiveDate?: string;
      ncdPercent?: string;
    };
    remark: string;
    status: string;
    amount: number;
    bankName: string;
    approvedAmount: number;
    approvedLimit: number;
    maturityDate: string;
    interestRate: number;
  };
}

const getTransactionInfo = (id: string) => {
  return API.get()(`/api/admin/transactions/retrieve/${id}`);
};

export const useTransactionInfoData = (id: string) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return useQuery(['transaction', id], (): any => getTransactionInfo(id), {
    select: (response: ITransactionInfoResponse) => {
      return response.data;
    },
  });
};

import { useQuery } from 'react-query';
import { API } from 'api';

interface IEmployeeInfoResponse {
  data: {
    profile: {
      email: string;
      mobile: string;
      name: string;
      idType: string;
      idNo: string;
      gender: string;
      nationality: string;
      countryOfBirth: string;
      dateOfBirth: string;
      monthlyRepaymentRent: string;
      maritalStatus: string;
      address: {
        line1: string;
        line2: string;
        postcode: string;
        city: string;
        state: string;
        country: string;
      };
    };
    createdOn: number;
    userProfile: string;
    status: string;
    emailVerified: boolean;
    ekycVerified: boolean;
    salaryBankInfo: {
      bankName: string;
      accountNumber: string;
      verified: string;
      accountName: string;
    };
    obaBankInfo: {
      ebid: string;
      eid: string;
      employerName: string;
      accountName: string;
      bankName: string;
      accountNumber: string;
    }[];
    walletSummary: {
      totalBalance: number;
      totalEarning: number;
      totalWithdrawn: number;
      totalFee: number;
    };
    reward: {
      bonusPoints: {
        currentBalance: number;
        expiredBalance: number;
        expiry: {
          totalExpiringPoints: number;
          expiredOn: string;
        };
        pendingBalance: number;
        spentBalance: number;
        tentativeCurrentBalance: number;
      };
      coupons: [];
      referrals: {
        code: string;
        referredCustomers: {
          total: number;
          success: number;
        };
      };
    };
    linkedCard: {
      cards: {
        last4: string;
        network: string;
        issuer: string;
      };
      isDefault: boolean;
      status: string;
    }[];
  };
}

const getEmployeeInfo = (id: string) => {
  return API.newGet(`/api/employee/${id}`);
};

export const useEmployeeInfoData = (id: string) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return useQuery(['employee', id], (): any => getEmployeeInfo(id), {
    select: (response: IEmployeeInfoResponse) => {
      return response.data;
    },
  });
};

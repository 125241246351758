import { useMutation } from 'react-query';
import { API } from 'api';
import { blobToString, downloadFileByBase64Str, isBase64 } from 'common';
import fileDownload from 'js-file-download';

export interface IDownloadInvoiceFile {
  payload: {
    eid: string;
    fileId: string;
    fileName: string;
    type: string;
  };
}

const downloadInvoiceFile = (payload: IDownloadInvoiceFile['payload']) => {
  const fileExtension = payload.fileName.split('.').pop() ?? '';
  payload.fileName = `${payload.fileName}${
    fileExtension !== 'pdf' && fileExtension !== 'csv' && fileExtension !== 'zip' ? '.pdf' : ''
  }`;
  return API.newGetBlob('/api/admin/files/retrieve', payload);
};

export const useDownloadInvoiceFile = () => {
  return useMutation(downloadInvoiceFile, {
    onSuccess: async (data, payload: IDownloadInvoiceFile['payload']) => {
      const result = await blobToString(data.data);
      const fileExtension = payload.fileName.split('.').pop() ?? '';
      if (isBase64(result) || result.includes('https://')) {
        return downloadFileByBase64Str(
          result,
          `${payload.fileName}${
            fileExtension !== 'pdf' && fileExtension !== 'csv' && fileExtension !== 'zip' ? '.pdf' : ''
          }`
        );
      }
      return fileDownload(
        data.data,
        `${payload.fileName}${
          fileExtension !== 'pdf' && fileExtension !== 'csv' && fileExtension !== 'zip' ? '.pdf' : ''
        }`
      );
    },
  });
};

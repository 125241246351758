/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';

export interface IPermissionListData {
  data: {
    data: any;
  };
}
const getPermissionListData = () => {
  return API.get()('/api/admin/permissions/list');
};

export const usePermissionListData = () => {
  return useQuery(['permissions'], (): any => getPermissionListData(), {
    select: (response: IPermissionListData['data']) => {
      return response.data;
    },
  });
};

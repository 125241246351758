/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';
import { isUndefinedOrNullOrEmpty } from '../../../common';

export interface IOutletFeeAndChargeListData {
  payload: {
    eid?: string;
    employerName?: string;
    outletId?: string;
    page: number;
  };
  data: {
    data: any;
    count: number;
  };
}

export const initOutletFeeAndChargeListPayload: IOutletFeeAndChargeListData['payload'] = {
  page: 1,
};

const getOutletFeeAndChargeList = (data: IOutletFeeAndChargeListData['payload']) => {
  return API.get()('/api/employer/outlets/list', data);
};

export const useGetOutletFeeAndChargeList = (data: IOutletFeeAndChargeListData['payload']) => {
  return useQuery(['feeAndChargeOutlets', data], (): any => getOutletFeeAndChargeList(data), {
    enabled: !isUndefinedOrNullOrEmpty(data.eid),
    select: (response: IOutletFeeAndChargeListData['data']) => {
      return response.data;
    },
  });
};

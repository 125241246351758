import { useMutation } from 'react-query';
import { API } from 'api';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';
import { IAddUpdateAdminUserData } from './shared/IAddUpdateAdminUserData';

const addAdminUserData = (payload: IAddUpdateAdminUserData['payload']) => {
  return API.post()('/api/admin/admUser/create', payload);
};

export const useAddAdminUserData = () => {
  const dispatch = useDispatch();

  return useMutation(addAdminUserData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.createdSucessful',
        })
      );
    },
  });
};

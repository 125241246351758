import { useQuery } from 'react-query';
import { API } from 'api';

export interface IEmploymentProfileListData {
  payload: {
    employeeName?: string;
    mobile?: string;
    eid?: string;
    employeeId?: string;
    idNumber?: string;
    status?: string;
    suspended?: string;
    page: number;
  };
  data: {
    data: {
      id: string;
      uid: string;
      status: string;
      employeeId: string;
      idNo: string;
      mobile: string;
      name: string;
      salary: string;
      suspendedDate?: number;
    }[];
    count: number;
  };
}

export const initEmploymentProfileListPayload: IEmploymentProfileListData['payload'] = {
  page: 1,
};

const getEmploymentProfileList = async (
  data: IEmploymentProfileListData['payload']
): Promise<IEmploymentProfileListData['data']> => {
  const res = await API.newGet('/api/admin/employment/profile/list', data);
  return res.data;
};

export const useEmploymentProfileListData = (payload: IEmploymentProfileListData['payload']) => {
  return useQuery(['employentProfiles', payload], () => getEmploymentProfileList(payload), {
    select: (data: IEmploymentProfileListData['data']) => {
      return data;
    },
  });
};

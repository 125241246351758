/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';

export interface IRoleListData {
  payload: {
    page?: number;
    from?: number;
    to?: number;
  };
  data: {
    data: any;
    count: number;
  };
}

export const initRoleListPayload: IRoleListData['payload'] = {
  page: 1,
};

const getRoleListData = (data: IRoleListData['payload']) => {
  return API.get()('/api/admin/roles/list', data);
};

export const useRoleListData = (data: IRoleListData['payload']) => {
  return useQuery(['roles', data], (): any => getRoleListData(data), {
    select: (response: IRoleListData['data']) => {
      return response.data;
    },
  });
};

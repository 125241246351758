import { useQuery } from 'react-query';
import { API } from '../../../api';

export interface IGetDeductionFileTypeList {
  payload: {
    search?: string;
    page?: number;
    pageSize?: number;
  };
  data: {
    data: Array<{
      id: string;
      name: string;
      mappings: Array<{
        index: number;
        column: string;
        mapping?: string;
        value?: string;
      }>;
      createdOn: number;
      createdBy: string;
      updatedOn: number;
      updatedBy: string;
    }>;
    totalCount: number;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDeductionFileTypeList = (data: IGetDeductionFileTypeList['payload']) =>
  API.newGet('/api/admin/sys/deduction-file/config/list', data);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// API.newGet('http://localhost:7101/setting/sys/deduction-file/config/list', data);

export const useGetDeductionFileTypeList = (data: IGetDeductionFileTypeList['payload']) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQuery(['getDeductionFileTypeList', data], (): any => getDeductionFileTypeList(data), {
    select: (response: IGetDeductionFileTypeList) => {
      return {
        data: response.data.data.map((item) => ({
          id: item.id,
          name: item.name,
          value: item.id,
          mappings: item.mappings,
          createdOn: item.createdOn,
          createdBy: item.createdBy,
          updatedOn: item.updatedOn,
          updatedBy: item.updatedBy,
        })),
        totalCount: response.data.totalCount,
      };
    },
  });
};

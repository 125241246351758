import { API } from 'api';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { toastPush } from 'modules';

export interface IEkycManualApprove {
  payload: {
    ekycId: string;
    address: {
      line1: string;
      line2: string;
      country: string;
      state: string;
      city: string;
      postcode: string;
    };
    name: string;
  };
}

const ekycManualApprove = (payload: IEkycManualApprove['payload']) => {
  return API.newPost('/api/admin/employee/ekyc/application/approve', payload);
};

export const useEkycManualApprove = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(ekycManualApprove, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (_: any, variables: IEkycManualApprove['payload']) => {
      queryClient.invalidateQueries(['employeeEkyc', variables.ekycId]);
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.approvedSucessful',
        })
      );
    },
  });
};

import { useQuery } from 'react-query';
import { API } from 'api';

const getAppInfo = () => {
  return API.newGet(`/api/admin/app/info/APP_INFO`);
};

export const useAppHomeBackgroundInfoData = () => {
  return useQuery(['appInfo'], (): any => getAppInfo(), {
    refetchOnWindowFocus: false,
    select: (response: any) => {
      return response.data;
    },
  });
};

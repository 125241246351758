import { RcFile } from 'antd/lib/upload/interface';
import { API } from 'api';
import { toastPush } from 'modules';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

export interface AddFileUpload {
  payload: {
    eid?: string;
    fileData?: RcFile;
    type?: string;
  };
}

const addFileUpload = (payload: AddFileUpload['payload']) => {
  const formData = new FormData();
  formData.append('eid', payload.eid ?? '');
  formData.append('fileData', payload.fileData ?? '');
  formData.append('type', payload.type ?? '');
  return API.newPost('/api/admin/fileUpload', formData, {
    'Content-Type': 'multipart/form-data',
  });
};

export const useAddFileUpload = () => {
  const dispatch = useDispatch();
  return useMutation(addFileUpload, {
    onSuccess: (response) => {
      return response.data;
    },
    onError: (error: { statusCode: number; message: string }) => {
      dispatch(
        toastPush({
          code: error.statusCode,
          type: 'error',
          title: 'ERROR',
          body: error?.message,
        })
      );
    },
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';

export interface IEmployeeCompanyRecordInfoResponse {
  data: {
    id: string;
    uid: string;
    bupId: string;
    eid: string;
    status: string;
    reason: string;
    companyName: string;
    scheduler: boolean;
    checkInEnabled: boolean;
    role: string;
    bindDate: number;
    employerBindDate: number;
    userBindDate: number;
    bindingMethod: string;
    createdOn: number;
    employeeId: string;
    resignationDate: string;
    declaredCommitment: number;
    commissions: any;
    leaves: any;
    employeeDetails: {
      employeeId: string;
      name: string;
      mobile: string;
      companyNameMetadata?: string;
      companyEmailAddress: string;
      role: string;
      idType: string;
      idNo: string;
    };
    salaryDetails: {
      salary: number;
      frequency: string;
      salaryDay: number;
      hourlyRate: number;
      withdrawalAmountUsed: 0;
      dailyEarnings: number;
      dailyWorkDuration: number;
      workingDays: number;
    };
    finance: {
      salary: {
        accountName: string;
        bankName: string;
        accountNumber: string;
      };
    };
    withdrawal: {
      ltv: number;
      eligibleEarning: number;
      limit: any;
    };
    outlets: {
      id: string;
      name: string;
      address: string;
      isHq: boolean;
      actId: string;
    }[];
    oba: {
      accountName: string;
      accountNumber: string;
      bankName: string;
    };
    suspendedDate?: number;
    suspensionReason?: string;
  };
  payload: {
    eid: string;
    id: string;
    ebid: string;
  };
}

const getEmployeeCompanyRecordInfo = (data: IEmployeeCompanyRecordInfoResponse['payload']) => {
  return API.newGet(`/api/employee/${data.id}/company/${data.ebid}`, {
    eid: data.eid,
  });
};

export const useEmployeeCompanyRecordInfoData = (data: IEmployeeCompanyRecordInfoResponse['payload']) => {
  return useQuery(
    [
      'employeeCompanyRecord',
      {
        id: data.id,
        eid: data.eid,
        ebid: data.ebid,
      },
    ],
    (): any => getEmployeeCompanyRecordInfo(data),
    {
      enabled: !!(data.id && data.ebid && data.eid),
      select: (response: IEmployeeCompanyRecordInfoResponse) => {
        return response.data;
      },
    }
  );
};

import { useMutation } from 'react-query';
import { API } from 'api';
import { ISharedCreateMerchantRequestData } from '../shared';

export type MerchantRequestType = 'new' | 'edit';

export interface ICreateMerchantRequestData {
  mid?: string;
  type: MerchantRequestType;
  data: ISharedCreateMerchantRequestData;
  fieldUpdated?: string[];
}

const createMerchantRequestData = (payload: ICreateMerchantRequestData) => {
  return API.post()('/api/admin/merchantRequest', payload);
};

export const useCreateMerchantRequestData = () => {
  return useMutation(createMerchantRequestData);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';

export interface IHlbbReportListData {
  payload: {
    page?: number;
    createdDate?: string;
    updatedDate?: string;
    type?: string;
  };
  data: {
    data: any;
    count: number;
  };
}

export const initHlbbReportListPayload: IHlbbReportListData['payload'] = {
  page: 1,
  type: 'oba&payment',
};

const getHlbbReportListData = (data: IHlbbReportListData['payload']) => {
  return API.get()('/api/admin/financeFiles/HLBB', data);
};

export const useHlbbReportListData = (data: IHlbbReportListData['payload']) => {
  return useQuery(['hlbbReports', data], (): any => getHlbbReportListData(data), {
    select: (response: IHlbbReportListData['data']) => {
      return response.data;
    },
  });
};

import { useQuery } from 'react-query';
import { API } from 'api';

export interface IEmployeeWorkHourListData {
  payload: {
    page: number;
    id: string;
  };
  data: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    data: any;
    count: number;
  };
}

export const initWorkHourListPayload: IEmployeeWorkHourListData['payload'] = {
  page: 1,
  id: '',
};

const getEmployeeWorkHourList = (data: IEmployeeWorkHourListData['payload']) => {
  return API.newGet(`/api/employee/${data.id}/work`, data);
};

export const useEmployeeWorkHourListData = (data: IEmployeeWorkHourListData['payload']) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return useQuery(['employeeWorkHours', data], (): any => getEmployeeWorkHourList(data), {
    enabled: !!data.id,
    select: (response: IEmployeeWorkHourListData) => {
      return response.data;
    },
  });
};

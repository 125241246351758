import { useQuery } from 'react-query';
import { API } from 'api';

export interface IEmployerOutletListData {
  payload: {
    name?: string;
    eid?: string;
    companyName?: string;
    page: number;
  };
  data: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    data: any;
    count: number;
  };
}

export const initEmployerOutletListPayload: IEmployerOutletListData['payload'] = {
  page: 1,
};

const getEmployerOutletList = (data: IEmployerOutletListData['payload']) => {
  return API.get()('/api/employer/outlets/list', data);
};

export const useEmployerOutletListData = (data: IEmployerOutletListData['payload']) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return useQuery(['employerOutlets', data], (): any => getEmployerOutletList(data), {
    select: (response: IEmployerOutletListData['data']) => {
      return response.data;
    },
  });
};

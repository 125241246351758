import { useMutation } from 'react-query';
import { API } from 'api';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';

export interface IRetryReversePtbData {
  payload: {
    ptbId?: string;
  };
}

const retryReversePtbData = (payload: IRetryReversePtbData['payload']) => {
  return API.post()('/api/admin/withdrawal/retry/reverse', payload);
};

export const useRetryReversePtbData = () => {
  const dispatch = useDispatch();

  return useMutation(retryReversePtbData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.retrySucessful',
        })
      );
    },
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ICreateOrUpdateEmployerState {
  type: 'new' | 'edit';
  erid: string;
  data: {
    id?: string;
    name: string;
    companyName: string;
    companyInformation: {
      companyRegistrationNo: {
        new: string;
        old: string;
      };
      businessLicenseExpiryDate: string;
      employerType: string;
      bnmCategory: string | undefined;
      bnmSubCategory: string | undefined;
      companyDescription: string;
      gstRegistrationNo: string;
      owner: {
        name: string;
        ic: string;
      }[];
      employerCode: string;
      inAppCompanyLogo: boolean | undefined;
    };
    contactInformation: {
      workPhone: string;
      mobile: string;
      email: string;
      secondaryEmail: string;
      website: string;
      mailing: {
        contactPerson: string;
        addressLine1: string;
        addressLine2: string;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    };
    attachments: {
      companyLogo: string;
      hlbApplicationForm: {
        file: string;
        name: string;
        source?: string;
        fileList?: any;
      };
      auditedFinancialStatements: {
        file: string;
        name: string;
        source?: string;
        fileList?: any;
      };
      businessRegistrationDocument: {
        file: string;
        name: string;
        source?: string;
        fileList?: any;
      };
      letterOfAuthorisation: {
        file: string;
        name: string;
        source?: string;
        fileList?: any;
      };
      signedAgreement: {
        file: string;
        name: string;
        source?: string;
        fileList?: any;
      };
      ctosReport: {
        name: string;
        file: string;
        source?: string;
        fileList?: any;
      };
      others: {
        name: string;
        file: string;
        source?: string;
        fileList?: any;
      };
    };
    finance: {
      bankName: string;
      bankAccountNo: string;
      bankAccountHolderName: string;
      masterAccountNo: string;
      masterBankName: string;
      masterAccountHolderName: string;
      masterSwiftCode: string;
      paymentDueDate: string;
      attentionTo: string;
      cifNo: string;
    };
    withdrawal: {
      employeeMonthlyLimitEnabled: boolean;
      ltv?: number;
      monthlyCreditLtv?: number;
      dailyCreditLtv?: number;
      monthlyWithdrawalLtv?: number;
      monthlyLimit?: number;
      limit: {
        min: number;
        max: number;
      };
      withdrawalProhibitedDayForNewlyBindedUser: number;
      withdrawalProhibitedDay: number;
    };
    location?: {
      lat: number;
      long: number;
    };
    locations?: {
      createdBy: string;
      createdOn: number;
      id: string;
      location: {
        lat: number;
        long: number;
      };
      name: string;
      qr: {
        qrImage: string;
        qrImageTemplate: string;
      };
    }[];
    admin: {
      aid?: string;
      loginId: string;
      password?: string;
      role?: string;
    }[];
    status?: string;
    createdOn?: number;
    checkInMethod: string[];
    scheduler: boolean;
    earning: string;
    setting: {
      customTemplate: {
        onboard: {
          file: string;
          name: string;
        };
        onboardAgent: {
          file: string;
          name: string;
        };
      };
      withdrawalCycleType: 'standard' | 'custom' | undefined;
      globalWithdrawalCycleId: string | undefined;
      lastWithdrawalDate: number | undefined;
      lastWithdrawalTime: string;
      autoWithdrawalEnabled: boolean;
      autoWithdrawalDayTime: string[];
      withdrawalDisabled: boolean;
      employeeMovementFile: string;
      paywatchEarningsDisabled: boolean;
      backDatedEarningEnabled: boolean;
      employeeMovementFileGenerationDays: string[];
      useOba: boolean;
      deductionFileGenerationDay: any[];
      requiresDeductionFile: boolean | null;
      deductionInvoiceGenerationDate: any[];
      invoiceFileRecipient: {
        to: string;
        cc: string;
      };
      employeeMovementFileRecipient: {
        to: string;
        cc: string;
      };
      deductionFileRecipient: {
        to: string;
        cc: string;
      };
      commissionRequiresAuthentication: boolean;
      withdrawalCycle: {
        tag: string;
        dateFrom: number | null;
        dateTo: number | null;
      }[];
      serviceProvider: string;
      tags: string;
      isFeeAbsorbed: boolean;
      isKickStartEarningEnabled: boolean;
      kickStartEarningAmount?: number;
      bulkImportApprovalFlag: boolean;
      twoFaEnable: boolean;
      manualGrantingFrequencyLimit: number | null | undefined;
      maximumManualGrantingAmount: number | null | undefined;
      ewaFeesChargedToUser: boolean | undefined;
      upfrontFeeDeductEnabled?: boolean;
      customGrantingTimeDayEnabled: boolean | undefined;
      customGrantingTime: string | undefined;
      customGrantingDay: string | undefined;
      customWithdrawalSettings: {
        customWithdrawalSettingsEnabled: boolean;
        txnMinWithdrawal: number;
        txnMaxWithdrawal: number;
        dailyMaxWithdrawal: number;
        monthlyMaxWithdrawal: number;
      };
      deductionFileTypeId: string | null | undefined;
      deductionSetting?: {
        customTemplate?: Record<string, any>;
        fileColumnNameOverrides?: Record<string, string>;
        exfileExtraColumnMapping?: Record<string, string>;
      } | null;
    };
  };
  eid?: string;
  fieldUpdated?: string[];
}

export interface IEmployerInfoState {
  eid: string;
  name: string;
  companyName: string;
  companyInformation: {
    bnmCategory: string;
    bnmSubCategory: string;
    businessLicenseExpiryDate: string;
    companyDescription: string;
    companyRegistrationNo: {
      new: string;
      old: string;
    };
    employerType: string;
    gstRegistrationNo: string;
    owner: {
      name: string;
      ic: string;
    }[];
    employerCode: string;
  };
  contactInformation: {
    email: string;
    mailing: {
      addressLine1: string;
      addressLine2: string;
      city: string;
      contactPerson: string;
      country: string;
      postalCode: string;
      state: string;
    };
    mobile: string;
    secondaryEmail: string;
    website: string;
    workPhone: string;
  };
  attachments: {
    companyLogo: string;
    hlbApplicationForm: {
      file: string;
      name: string;
    };
    auditedFinancialStatements: {
      file: string;
      name: string;
    };
    businessRegistrationDocument: {
      file: string;
      name: string;
    };
    letterOfAuthorisation: {
      file: string;
      name: string;
    };
    signedAgreement: {
      file: string;
      name: string;
    };
    ctosReport: {
      file: string;
      name: string;
    };
    others: {
      file: string;
      name: string;
    };
  };
  finance: {
    bankAccountHolderName: string;
    bankAccountNo: string;
    bankName: string;
    masterAccountNo: string;
    cifNo: string;
  };
  withdrawal: {
    employeeMonthlyLimitEnabled?: boolean;
    ltv?: number;
    monthlyLimit?: number;
    limit: {
      min: number;
      max: number;
    };
    withdrawalProhibitedDayForNewlyBindedUser: number;
    withdrawalProhibitedDay: number;
  };
  location: {
    lat: number;
    long: number;
    id: string;
  };
  locations: {
    createdBy: string;
    createdOn: number;
    id: string;
    location: {
      lat: number;
      long: number;
    };
    name: string;
    qr: {
      qrImage: string;
      qrImageTemplate: string;
    };
  }[];
  admin: {
    aid: string;
    loginId: string;
    role: string;
    actId: string;
    status: string;
  }[];
  checkInMethod: string[];
  scheduler?: boolean;
  earning?: string;
  status: string;
  createdOn: number;
  setting: {
    employeeMovementFile: string;
    lastWithdrawalDate?: number;
    lastWithdrawalTime?: string;
    paywatchEarningsDisabled?: boolean;
    backDatedEarningEnabled?: boolean;
    employeeMovementFileGenerationDays: string[];
    useOba: boolean;
    deductionFileGenerationDay: any[];
    requiresDeductionFile: boolean | null;
    deductionInvoiceGenerationDate?: any[];
    invoiceFileRecipient: {
      to: string;
      cc: string;
    };
    employeeMovementFileRecipient: {
      to: string;
      cc: string;
    };
    deductionFileRecipient: {
      to: string;
      cc: string;
    };
    commissionRequiresAuthentication?: boolean;
    customWithdrawalSettings: {
      customWithdrawalSettingsEnabled: boolean;
      txnMinWithdrawal: number;
      txnMaxWithdrawal: number;
      dailyMaxWithdrawal: number;
      monthlyMaxWithdrawal: number;
    };
  };
  cycles: { tag: string; dateFrom: number | null; dateTo: number | null }[];
}

export const initEmployerInfoState: IEmployerInfoState = {
  eid: '',
  name: '',
  companyName: '',
  companyInformation: {
    bnmCategory: '',
    bnmSubCategory: '',
    businessLicenseExpiryDate: '',
    companyDescription: '',
    companyRegistrationNo: {
      new: '',
      old: '',
    },
    employerType: '',
    gstRegistrationNo: '',
    owner: [
      {
        name: '',
        ic: '',
      },
    ],
    employerCode: '',
  },
  contactInformation: {
    email: '',
    mailing: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      contactPerson: '',
      country: '',
      postalCode: '',
      state: '',
    },
    mobile: '',
    secondaryEmail: '',
    website: '',
    workPhone: '',
  },
  attachments: {
    companyLogo: '',
    hlbApplicationForm: {
      file: '',
      name: '',
    },
    auditedFinancialStatements: {
      file: '',
      name: '',
    },
    businessRegistrationDocument: {
      file: '',
      name: '',
    },
    letterOfAuthorisation: {
      file: '',
      name: '',
    },
    signedAgreement: {
      file: '',
      name: '',
    },
    ctosReport: {
      file: '',
      name: '',
    },
    others: {
      file: '',
      name: '',
    },
  },
  finance: {
    bankAccountHolderName: '',
    bankAccountNo: '',
    bankName: '',
    masterAccountNo: '',
    cifNo: '',
  },
  withdrawal: {
    employeeMonthlyLimitEnabled: false,
    ltv: undefined,
    monthlyLimit: undefined,
    limit: {
      min: 0,
      max: 100000,
    },
    withdrawalProhibitedDayForNewlyBindedUser: 7,
    withdrawalProhibitedDay: 3,
  },
  location: {
    lat: 0,
    long: 0,
    id: '',
  },
  locations: [
    {
      createdBy: '',
      createdOn: 0,
      id: '',
      location: {
        lat: 0,
        long: 0,
      },
      name: '',
      qr: {
        qrImage: '',
        qrImageTemplate: '',
      },
    },
  ],
  admin: [
    {
      aid: '',
      loginId: '',
      role: '',
      actId: '',
      status: '',
    },
  ],
  status: '',
  scheduler: undefined,
  earning: undefined,
  checkInMethod: [],
  setting: {
    employeeMovementFile: '',
    lastWithdrawalDate: undefined,
    employeeMovementFileGenerationDays: [],
    useOba: true,
    deductionFileGenerationDay: [],
    requiresDeductionFile: false,
    deductionInvoiceGenerationDate: [],
    invoiceFileRecipient: {
      to: '',
      cc: '',
    },
    employeeMovementFileRecipient: {
      to: '',
      cc: '',
    },
    deductionFileRecipient: {
      to: '',
      cc: '',
    },
    customWithdrawalSettings: {
      customWithdrawalSettingsEnabled: false,
      txnMaxWithdrawal: 0,
      txnMinWithdrawal: 0,
      dailyMaxWithdrawal: 0,
      monthlyMaxWithdrawal: 0,
    },
  },
  cycles: [
    {
      tag: '',
      dateFrom: null,
      dateTo: null,
    },
  ],
  createdOn: 0,
};

export interface IEmployerRequestInfoState {
  createdBy: string;
  createdOn: number;
  id: string;
  status: string;
  type: string;
  data: {
    name: string;
    companyName: string;
    checkInMethod: string[];
    companyInformation: {
      companyRegistrationNo: {
        new: string;
        old: string;
      };
      businessLicenseExpiryDate: string;
      employerType: string;
      bnmCategory: string;
      bnmSubCategory: string;
      companyDescription: string;
      gstRegistrationNo: string;
      owner: {
        name: string;
        ic: string;
      }[];
      employerCode: string;
    };
    contactInformation: {
      workPhone: string;
      mobile: string;
      email: string;
      secondaryEmail: string;
      website: string;
      mailing: {
        contactPerson: string;
        addressLine1: string;
        addressLine2: string;
        city: string;
        state: string;
        postalCode: string;
        country: string;
      };
    };
    attachments: {
      companyRegistrationCert: {
        file: string;
        name: string;
      };
      hlbApplicationForm: {
        file: string;
        name: string;
      };
      auditedFinancialStatements: {
        file: string;
        name: string;
      };
      businessRegistrationDocument: {
        file: string;
        name: string;
      };
      letterOfAuthorisation: {
        file: string;
        name: string;
      };
      signedAgreement: {
        file: string;
        name: string;
      };
      companyLogo: string;
      employerAgreement: {
        file: string;
        name: string;
      };
      others: {
        file: string;
        name: string;
      };
    };
    finance: {
      bankName: string;
      bankAccountNo: string;
      bankAccountHolderName: string;
      masterAccountNo: string;
      cifNo: string;
    };
    withdrawal: {
      ltv: number;
      monthlyLimit: number;
    };
    location: {
      lat: number;
      long: number;
    };
    admin: {
      aid: string;
      loginId: string;
      password: string;
      permissions: string[];
      role: string;
      status: string;
    }[];
    scheduler?: boolean;
    earning?: string;
    setting: {
      employeeMovementFile: string;
      lastWithdrawalDate?: number;
      employeeMovementFileGenerationDays: string[];
      useOba: boolean;
      requiresDeductionFile: boolean;
      deductionFileGenerationDay: any[];
      deductionInvoiceGenerationDate?: any[];
      invoiceFileRecipient: {
        to: string;
        cc: string;
      };
      employeeMovementFileRecipient: {
        to: string;
        cc: string;
      };
      deductionFileRecipient: {
        to: string;
        cc: string;
      };
    };
  };
  fieldUpdated: string[];
  reason?: string;
  updatedBy: {
    aid: string;
    name: string;
    email: string;
    role: string;
    createdOn: number;
    status: string;
  }[];
}

export const initEmployerRequestInfoState: IEmployerRequestInfoState = {
  createdBy: '',
  createdOn: 0,
  id: '',
  status: '',
  type: '',
  data: {
    name: '',
    checkInMethod: [],
    companyName: '',
    companyInformation: {
      companyRegistrationNo: {
        new: '',
        old: '',
      },
      businessLicenseExpiryDate: '',
      employerType: '',
      bnmCategory: '',
      bnmSubCategory: '',
      companyDescription: '',
      gstRegistrationNo: '',
      owner: [
        {
          name: '',
          ic: '',
        },
      ],
      employerCode: '',
    },
    contactInformation: {
      workPhone: '',
      mobile: '',
      email: '',
      secondaryEmail: '',
      website: '',
      mailing: {
        contactPerson: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
    },
    attachments: {
      companyRegistrationCert: {
        file: '',
        name: '',
      },
      hlbApplicationForm: {
        file: '',
        name: '',
      },
      auditedFinancialStatements: {
        file: '',
        name: '',
      },
      businessRegistrationDocument: {
        file: '',
        name: '',
      },
      letterOfAuthorisation: {
        file: '',
        name: '',
      },
      signedAgreement: {
        file: '',
        name: '',
      },
      companyLogo: '',
      employerAgreement: {
        file: '',
        name: '',
      },
      others: {
        file: '',
        name: '',
      },
    },
    finance: {
      bankName: '',
      bankAccountNo: '',
      bankAccountHolderName: '',
      masterAccountNo: '',
      cifNo: '',
    },
    withdrawal: {
      ltv: 0,
      monthlyLimit: 0,
    },
    location: {
      lat: 0,
      long: 0,
    },
    admin: [
      {
        aid: '',
        loginId: '',
        password: '',
        permissions: [],
        role: '',
        status: '',
      },
    ],
    scheduler: undefined,
    earning: undefined,
    setting: {
      lastWithdrawalDate: undefined,
      employeeMovementFile: '',
      employeeMovementFileGenerationDays: [],
      useOba: true,
      deductionFileGenerationDay: [],
      requiresDeductionFile: false,
      deductionInvoiceGenerationDate: [],
      invoiceFileRecipient: {
        to: '',
        cc: '',
      },
      employeeMovementFileRecipient: {
        to: '',
        cc: '',
      },
      deductionFileRecipient: {
        to: '',
        cc: '',
      },
    },
  },
  fieldUpdated: [],
  updatedBy: [],
};

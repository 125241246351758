import fileDownload from 'js-file-download';
import { useMutation } from 'react-query';
import { API } from 'api';
import { blobToString, downloadFileByBase64Str, isBase64 } from 'common';

export interface IDownloadExceptionFile {
  payload: {
    bank: string;
    fileId: string;
    fileName: string;
    type: string;
  };
}

const downloadExceptionFile = (payload: IDownloadExceptionFile['payload']) => {
  return API.newGetBlob('/api/admin/files/retrieve', payload);
};

export const useDownloadExceptionFile = () => {
  return useMutation(downloadExceptionFile, {
    onSuccess: async (data, payload: IDownloadExceptionFile['payload']) => {
      const result = await blobToString(data.data);
      if (isBase64(result) || result.includes('https://')) {
        return downloadFileByBase64Str(result, payload.fileName);
      }
      return fileDownload(data.data, payload.fileName);
    },
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';

export interface IGlobalCutOffListData {
  payload: {
    page?: number;
    name?: string;
    // updatedBy?: string;
  };
  data: {
    data: any;
    count: number;
  };
}

export const initGlobalCutOffListPayload: IGlobalCutOffListData['payload'] = {
  page: 1,
};

const getGlobalCutOffListData = (data: IGlobalCutOffListData['payload']) => {
  return API.newGet('/api/admin/setting/globalWithdrawalCycle/all', data);
};

export const useGlobalCutOffListData = (data: IGlobalCutOffListData['payload']) => {
  return useQuery(['globalCutOffs', data], (): any => getGlobalCutOffListData(data), {
    select: (response: IGlobalCutOffListData['data']) => {
      const result = response?.data?.data;

      return {
        data: result,
        count: response?.data.count,
      };
    },
  });
};

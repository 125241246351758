import { API } from 'api';
import { isUndefinedOrNullOrEmpty, setCookie } from 'common';
import { changeCountryCode, changeLanguageCode, setIsLoggedIn, setUserCredential } from 'modules';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

interface IValidateOtpPayload {
  payload: {
    aid: string;
    email: string;
    otp: string;
    redirectUrl?: string;
  };
}

const validateOtp = (payload: IValidateOtpPayload['payload']) => {
  return API.newPost('/api/admin/pub/validate/otp', payload);
};

export const useValidateOtpData = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  return useMutation(validateOtp, {
    onSuccess: (data, payload: IValidateOtpPayload['payload']) => {
      const result = data.data;
      dispatch(setUserCredential(result));
      setCookie('accessToken', result.accessToken, result.expiredOn);
      setCookie('refreshToken', result.refreshToken, result.expiredOn);
      setCookie('expiredOn', result.expiredOn, result.expiredOn);
      setCookie('employerId', result.eid ?? '');
      setCookie('employerProfiles', JSON.stringify(result.employers || []));
      setCookie('accountId', result.aid);
      setCookie('permissions', JSON.stringify(result.permissions || []));
      setCookie('email', result.userProfile.email);

      const country = result.employers?.[0]?.country || 'my';
      const language = localStorage.getItem('i18nextLng') ?? 'en';

      const locale = `${language}-${country.toUpperCase()}`;

      setCookie('locale', locale);

      if (!isUndefinedOrNullOrEmpty(result.employers) && result.employers.length > 0) {
        dispatch(changeCountryCode({ code: result.employers[0]?.country || 'my' }));
      }
      dispatch(changeLanguageCode(localStorage.getItem('i18nextLng') ?? 'en'));
      dispatch(setIsLoggedIn(true));
      localStorage.removeItem('2fa');

      if (result.firstTimeLogin) {
        history.push('/setup-password');
        return;
      }

      if (!isUndefinedOrNullOrEmpty(result.employers) && result.employers.length > 1) {
        history.push(`/employer-company${history.location.search}`);
        return;
      }

      if (payload.redirectUrl) {
        history.push(payload.redirectUrl);
        return;
      }
      window.location.href = `${process.env.REACT_APP_EMPLOYER_PORTAL_URL}/dashboard`;
    },
  });
};

import { useQuery } from 'react-query';
import { API } from 'api';

export interface IEmployeeEkycInfoResponse {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: {
    id: string;
    uid: string;
    createdOn: number;
    createdDate: string;
    ekycStatus: string;
    sdkToken: string;
    facialSimilarityStatus: string;
    documentStatus: string;
    documentFailureReason: string;
    facialSimilarityFailureReason: string;
    failureReason: string;
    userSubmitInfo: {
      name: string;
      dateOfBirth: string;
      address: {
        line1: string;
        line2: string;
        postcode: string;
        city: string;
        state: string;
        country: string;
      };
      idType: string;
      idNo: string;
    };
    iso2CountryCode: string;
    ocrInformation: {
      name: string;
      dateOfBirth: string;
      address: {
        line1: string;
        line2: string;
        postcode: string;
        city: string;
        state: string;
        country: string;
      };
      idType: string;
      idNo: string;
    };
    registerInfo: {
      fullName: string;
      email: string;
      mobile: string;
      numberOfEkycAttempts: string;
    };
    frontDocument: string;
    backDocument: string;
    frontDocumentId: string;
    backDocumentId: string;
    videoId: string;
    video: string;
    loading: boolean;
    overrideBy?: string;
    overrideOn?: number;
  };
}

const getEmployeeEkycInfo = (id: string) => {
  return API.newGet(`/api/admin/ekyc/application/${id}`, { uid: id });
};

export const useEmployeeEkycInfoData = (id: string) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return useQuery(['employeeEkyc', id], (): any => getEmployeeEkycInfo(id), {
    enabled: !!id,
    select: (response: IEmployeeEkycInfoResponse) => {
      return response.data;
    },
  });
};

import { useMutation, useQueryClient } from 'react-query';
import { API } from 'api';
import { getLatestQuery } from 'common';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';

export interface IGenerateFileData {
  withdrawalCycleId: string;
  eid: string;
}

const generateFile = (payload: IGenerateFileData) => {
  return API.newPost('/api/admin/deduction/regenerate', payload);
};

export const useGenerateFileData = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const key = 'employerReports';
  const queryArr = queryClient.getQueriesData(key);
  const res = getLatestQuery(queryArr, queryClient);

  return useMutation(generateFile, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.fileGeneratedSucessful',
        })
      );
      return queryClient.invalidateQueries(key, res.queryParams);
    },
  });
};

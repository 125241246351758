/* eslint-disable @typescript-eslint/no-explicit-any, import/no-cycle */
import {
  CREATE_EMPLOYER_REQUEST_ERROR,
  CREATE_EMPLOYER_REQUEST_FETCH,
  CREATE_EMPLOYER_REQUEST_SUCCESS,
  EMPLOYER_INFO_FETCH,
  EMPLOYER_INFO_INIT,
  EMPLOYER_INFO_SUCCESS,
  EMPLOYER_LIST_FETCH,
  EMPLOYER_LIST_SUCCESS,
  EMPLOYER_PREVIEW_FILES_IMAGE_ERROR,
  EMPLOYER_PREVIEW_FILES_IMAGE_FETCH,
  EMPLOYER_PREVIEW_FILES_IMAGE_SUCCESS,
  EMPLOYER_REQUEST_INFO_RESET,
  EMPLOYER_REQUEST_INFO_FETCH,
  EMPLOYER_REQUEST_INFO_SUCCESS,
  EMPLOYER_REQUEST_LIST_FETCH,
  EMPLOYER_REQUEST_LIST_SUCCESS,
  EMPLOYER_REQUEST_UPLOAD_BLOB_ERROR,
  EMPLOYER_REQUEST_UPLOAD_BLOB_FETCH,
  EMPLOYER_REQUEST_UPLOAD_BLOB_SUCCESS,
  EMPLOYER_SELECT_FETCH,
  EMPLOYER_SELECT_SUCCESS,
  INIT_EDIT_EMPLOYER_REQUEST_SUCCESS,
  INIT_EMPLOYER_REQUEST_ERROR,
  INIT_EMPLOYER_REQUEST_FETCH,
  INIT_NEW_EMPLOYER_REQUEST_SUCCESS,
  UPDATE_EMPLOYER_REQUEST_ERROR,
  UPDATE_EMPLOYER_REQUEST_FETCH,
  UPDATE_EMPLOYER_REQUEST_SUCCESS,
  UPDATE_EMPLOYER_ADMIN_STATUS_FETCH,
  RESET_EMPLOYER_ADMIN_PASSWORD_FETCH,
  EMPLOYER_INFO_RESET,
  EMPLOYER_PREVIEW_FILES_IMAGE_RESET,
  EMPLOYER_INVENTORY_LIST_FETCH,
  EMPLOYER_INVENTORY_LIST_SUCCESS,
  EMPLOYER_FILE_RETRIEVE_SUCCESS,
  EMPLOYER_FILE_RETRIEVE_FETCH,
  EMPLOYER_FILE_RETRIEVE_RESET,
  CREATE_EMPLOYER_REQUEST_RESET,
} from './constants';
import { IEmployerState } from './reducer';
import { ICreateOrUpdateEmployerState, IEmployerRequestInfoState } from './types';

export interface IEmployerListFetch {
  type: typeof EMPLOYER_LIST_FETCH;
  payload: {
    employerName?: string;
    employerId?: string;
    eid?: string;
    companyName?: string;
    page: number;
  };
}

export interface IEmployerListSuccess {
  type: typeof EMPLOYER_LIST_SUCCESS;
  payload: {
    records: any;
    count: number;
  };
}

export interface IEmployerSelectFetch {
  type: typeof EMPLOYER_SELECT_FETCH;
}

export interface IEmployerSelectSuccess {
  type: typeof EMPLOYER_SELECT_SUCCESS;
  payload: {
    records: any;
  };
}

export interface IEmployerInfoInit {
  type: typeof EMPLOYER_INFO_INIT;
}

export interface IEmployerInfoReset {
  type: typeof EMPLOYER_INFO_RESET;
}

export interface IEmployerInfoFetch {
  type: typeof EMPLOYER_INFO_FETCH;
  payload: {
    eid: string;
  };
}

export interface IEmployerInfoSuccess {
  type: typeof EMPLOYER_INFO_SUCCESS;
  payload: IEmployerState;
}

export interface IEmployerRequestListFetch {
  type: typeof EMPLOYER_REQUEST_LIST_FETCH;
  payload: {
    erid?: string;
    eid?: string;
    from?: number;
    to?: number;
    employerId?: string;
    employerName?: string;
    status?: string;
    page: number;
  };
}

export interface IEmployerRequestListSuccess {
  type: typeof EMPLOYER_REQUEST_LIST_SUCCESS;
  payload: {
    records: any;
    count: number;
    // page: number
  };
}

export interface IEmployerRequestInfoReset {
  type: typeof EMPLOYER_REQUEST_INFO_RESET;
}

export interface IEmployerRequestInfoFetch {
  type: typeof EMPLOYER_REQUEST_INFO_FETCH;
  payload: {
    eid: string;
  };
}

export interface IEmployerRequestInfoSuccess {
  type: typeof EMPLOYER_REQUEST_INFO_SUCCESS;
  payload: IEmployerRequestInfoState;
}

export interface ICreateEmployerRequestReset {
  type: typeof CREATE_EMPLOYER_REQUEST_RESET;
}

export interface ICreateEmployerRequestFetch {
  type: typeof CREATE_EMPLOYER_REQUEST_FETCH;
  payload: ICreateOrUpdateEmployerState;
}

export interface ICreateEmployerRequestSuccess {
  type: typeof CREATE_EMPLOYER_REQUEST_SUCCESS;
}

export interface ICreateEmployerRequestError {
  type: typeof CREATE_EMPLOYER_REQUEST_ERROR;
  payload: {
    messageId?: string;
    message?: string;
  };
}

export interface IUpdateEmployerRequestFetch {
  type: typeof UPDATE_EMPLOYER_REQUEST_FETCH;
  payload: {
    eid: string;
    status: string;
    reason?: string;
  };
  onSuccess?: () => void;
  onError?: () => void;
}

export interface IUpdateEmployerRequestSuccess {
  type: typeof UPDATE_EMPLOYER_REQUEST_SUCCESS;
}

export interface IUpdateEmployerRequestError {
  type: typeof UPDATE_EMPLOYER_REQUEST_ERROR;
}

export interface IInitEmployerRequestFetch {
  type: typeof INIT_EMPLOYER_REQUEST_FETCH;
  payload: {
    type: string;
  };
}

export interface IInitNewEmployerRequestSuccess {
  type: typeof INIT_NEW_EMPLOYER_REQUEST_SUCCESS;
  payload: {
    erid: string;
  };
}

export interface IInitEditEmployerRequestSuccess {
  type: typeof INIT_EDIT_EMPLOYER_REQUEST_SUCCESS;
  payload: {
    erid: string;
  };
}

export interface IInitEmployerRequestError {
  type: typeof INIT_EMPLOYER_REQUEST_ERROR;
}

export interface IEmployerDownloadFileImageReset {
  type: typeof EMPLOYER_PREVIEW_FILES_IMAGE_RESET;
}

export interface IEmployerDownloadFileImageFetch {
  type: typeof EMPLOYER_PREVIEW_FILES_IMAGE_FETCH;
  payload: {
    objectId?: string;
    key: string;
  };
}

export interface IEmployerDownloadFileImageSuccess {
  type: typeof EMPLOYER_PREVIEW_FILES_IMAGE_SUCCESS;
  payload: {
    key: string;
    source: string;
  };
}

export interface IEmployerDownloadFileImageError {
  type: typeof EMPLOYER_PREVIEW_FILES_IMAGE_ERROR;
}

export interface IEmployerRequestUploadBlobFetch {
  type: typeof EMPLOYER_REQUEST_UPLOAD_BLOB_FETCH;
  payload: {
    data: string;
    prefix: string;
    erid: string;
    eid?: string;
    filename: string;
  };
  onSuccess?: (info: { prefix: string; objectId: string; filename: string }) => void;
  onError?: () => void;
}

export interface IEmployerRequestUploadBlobSuccess {
  type: typeof EMPLOYER_REQUEST_UPLOAD_BLOB_SUCCESS;
  payload: {
    objectId: string;
    filename: string;
    prefix: string;
  };
}

export interface IEmployerRequestUploadBlobError {
  type: typeof EMPLOYER_REQUEST_UPLOAD_BLOB_ERROR;
}

export interface IUpdateEmployerAdminStatusFetch {
  type: typeof UPDATE_EMPLOYER_ADMIN_STATUS_FETCH;
  payload: {
    aid: string;
    eid?: string;
    status: 'active' | 'suspend';
  };
  onSuccess?: (aid: string, status: string) => void;
  onError?: () => void;
}

export interface IResetEmployerAdminPasswordFetch {
  type: typeof RESET_EMPLOYER_ADMIN_PASSWORD_FETCH;
  payload: {
    aid: string;
  };
  onSuccess?: () => void;
  onError?: () => void;
}

export interface IEmployerInventoryListFetch {
  type: typeof EMPLOYER_INVENTORY_LIST_FETCH;
}

export interface IEmployerInventoryListSuccess {
  type: typeof EMPLOYER_INVENTORY_LIST_SUCCESS;
  payload: {
    data: any[];
  };
}

export interface IEmployerFileRetrieveReset {
  type: typeof EMPLOYER_FILE_RETRIEVE_RESET;
}

export interface IEmployerFileRetrieveFetch {
  type: typeof EMPLOYER_FILE_RETRIEVE_FETCH;
  payload: {
    objectId: string;
    ebid: string;
  };
}

export interface IEmployerFileRetrieveSuccess {
  type: typeof EMPLOYER_FILE_RETRIEVE_SUCCESS;
  payload: {
    data: any;
  };
}

export type employerAction =
  | IEmployerListFetch
  | IEmployerListSuccess
  | IEmployerSelectFetch
  | IEmployerSelectSuccess
  | IEmployerInfoInit
  | IEmployerInfoReset
  | IEmployerInfoFetch
  | IEmployerInfoSuccess
  | IEmployerRequestListFetch
  | IEmployerRequestListSuccess
  | IEmployerRequestInfoReset
  | IEmployerRequestInfoFetch
  | IEmployerRequestInfoSuccess
  | ICreateEmployerRequestReset
  | ICreateEmployerRequestFetch
  | ICreateEmployerRequestSuccess
  | ICreateEmployerRequestError
  | IInitEmployerRequestFetch
  | IInitNewEmployerRequestSuccess
  | IInitEditEmployerRequestSuccess
  | IInitEmployerRequestError
  | IEmployerDownloadFileImageReset
  | IEmployerDownloadFileImageFetch
  | IEmployerDownloadFileImageSuccess
  | IEmployerDownloadFileImageError
  | IEmployerRequestUploadBlobFetch
  | IEmployerRequestUploadBlobSuccess
  | IEmployerRequestUploadBlobError
  | IUpdateEmployerAdminStatusFetch
  | IEmployerInventoryListFetch
  | IEmployerInventoryListSuccess
  | IEmployerFileRetrieveReset
  | IEmployerFileRetrieveFetch
  | IEmployerFileRetrieveSuccess;

export const employerList = (payload: IEmployerListFetch['payload']): IEmployerListFetch => ({
  type: EMPLOYER_LIST_FETCH,
  payload,
});

export const successEmployerList = (payload: IEmployerListSuccess['payload']): IEmployerListSuccess => ({
  type: EMPLOYER_LIST_SUCCESS,
  payload,
});

export const employerSelect = (): IEmployerSelectFetch => ({
  type: EMPLOYER_SELECT_FETCH,
});

export const successEmployerSelect = (payload: IEmployerSelectSuccess['payload']): IEmployerSelectSuccess => ({
  type: EMPLOYER_SELECT_SUCCESS,
  payload,
});

export const employerInfoInit = (): IEmployerInfoInit => ({
  type: EMPLOYER_INFO_INIT,
});

export const employerInfoReset = (): IEmployerInfoReset => ({
  type: EMPLOYER_INFO_RESET,
});

export const employerInfoFetch = (payload: IEmployerInfoFetch['payload']): IEmployerInfoFetch => ({
  type: EMPLOYER_INFO_FETCH,
  payload,
});

export const successEmployerInfo = (payload: IEmployerInfoSuccess['payload']): IEmployerInfoSuccess => ({
  type: EMPLOYER_INFO_SUCCESS,
  payload,
});

export const employerRequestList = (payload: IEmployerRequestListFetch['payload']): IEmployerRequestListFetch => ({
  type: EMPLOYER_REQUEST_LIST_FETCH,
  payload,
});

export const successEmployerRequestList = (
  payload: IEmployerRequestListSuccess['payload']
): IEmployerRequestListSuccess => ({
  type: EMPLOYER_REQUEST_LIST_SUCCESS,
  payload,
});

export const employerRequestInfoReset = (): IEmployerRequestInfoReset => ({
  type: EMPLOYER_REQUEST_INFO_RESET,
});

export const employerRequestInfoFetch = (payload: IEmployerRequestInfoFetch['payload']): IEmployerRequestInfoFetch => ({
  type: EMPLOYER_REQUEST_INFO_FETCH,
  payload,
});

export const successEmployerRequestInfo = (
  payload: IEmployerRequestInfoSuccess['payload']
): IEmployerRequestInfoSuccess => ({
  type: EMPLOYER_REQUEST_INFO_SUCCESS,
  payload,
});

export const createEmployerRequestReset = (): ICreateEmployerRequestReset => ({
  type: CREATE_EMPLOYER_REQUEST_RESET,
});

export const createEmployerRequestFetch = (
  payload: ICreateEmployerRequestFetch['payload']
): ICreateEmployerRequestFetch => ({
  type: CREATE_EMPLOYER_REQUEST_FETCH,
  payload,
});

export const successCreateEmployerRequest = (): ICreateEmployerRequestSuccess => ({
  type: CREATE_EMPLOYER_REQUEST_SUCCESS,
});

export const errorCreateEmployerRequest = (
  payload: ICreateEmployerRequestError['payload']
): ICreateEmployerRequestError => ({
  type: CREATE_EMPLOYER_REQUEST_ERROR,
  payload,
});

export const updateEmployerRequestFetch = (
  payload: IUpdateEmployerRequestFetch['payload'],
  onSuccess?: IUpdateEmployerRequestFetch['onSuccess'],
  onError?: IUpdateEmployerRequestFetch['onError']
): IUpdateEmployerRequestFetch => ({
  type: UPDATE_EMPLOYER_REQUEST_FETCH,
  payload,
  onSuccess,
  onError,
});

export const successUpdateEmployerRequest = (): IUpdateEmployerRequestSuccess => ({
  type: UPDATE_EMPLOYER_REQUEST_SUCCESS,
});

export const errorUpdateEmployerRequest = (): IUpdateEmployerRequestError => ({
  type: UPDATE_EMPLOYER_REQUEST_ERROR,
});

export const initEmployerRequestFetch = (payload: IInitEmployerRequestFetch['payload']): IInitEmployerRequestFetch => ({
  type: INIT_EMPLOYER_REQUEST_FETCH,
  payload,
});

export const successInitNewEmployerRequest = (
  payload: IInitNewEmployerRequestSuccess['payload']
): IInitNewEmployerRequestSuccess => ({
  type: INIT_NEW_EMPLOYER_REQUEST_SUCCESS,
  payload,
});

export const successInitEditEmployerRequest = (
  payload: IInitEditEmployerRequestSuccess['payload']
): IInitEditEmployerRequestSuccess => ({
  type: INIT_EDIT_EMPLOYER_REQUEST_SUCCESS,
  payload,
});

export const errorInitEmployerRequest = (): IInitEmployerRequestError => ({
  type: INIT_EMPLOYER_REQUEST_ERROR,
});

export const employerDownloadFileImageReset = (): IEmployerDownloadFileImageReset => ({
  type: EMPLOYER_PREVIEW_FILES_IMAGE_RESET,
});

export const employerDownloadFileImageFetch = (
  payload: IEmployerDownloadFileImageFetch['payload']
): IEmployerDownloadFileImageFetch => ({
  type: EMPLOYER_PREVIEW_FILES_IMAGE_FETCH,
  payload,
});

export const employerDownloadFileImageSuccess = (
  payload: IEmployerDownloadFileImageSuccess['payload']
): IEmployerDownloadFileImageSuccess => ({
  type: EMPLOYER_PREVIEW_FILES_IMAGE_SUCCESS,
  payload,
});

export const employerDownloadFileImageError = (): IEmployerDownloadFileImageError => ({
  type: EMPLOYER_PREVIEW_FILES_IMAGE_ERROR,
});

export const employerRequestUploadBlobFetch = (
  payload: IEmployerRequestUploadBlobFetch['payload'],
  onSuccess?: IEmployerRequestUploadBlobFetch['onSuccess'],
  onError?: IEmployerRequestUploadBlobFetch['onError']
): IEmployerRequestUploadBlobFetch => ({
  type: EMPLOYER_REQUEST_UPLOAD_BLOB_FETCH,
  payload,
  onSuccess,
  onError,
});

export const employerRequestUploadBlobSuccess = (
  payload: IEmployerRequestUploadBlobSuccess['payload']
): IEmployerRequestUploadBlobSuccess => ({
  type: EMPLOYER_REQUEST_UPLOAD_BLOB_SUCCESS,
  payload,
});

export const employerRequestUploadBlobError = (): IEmployerRequestUploadBlobError => ({
  type: EMPLOYER_REQUEST_UPLOAD_BLOB_ERROR,
});

export const updateEmployerAdminStatusFetch = (
  payload: IUpdateEmployerAdminStatusFetch['payload'],
  onSuccess?: IUpdateEmployerAdminStatusFetch['onSuccess'],
  onError?: IUpdateEmployerAdminStatusFetch['onError']
): IUpdateEmployerAdminStatusFetch => ({
  type: UPDATE_EMPLOYER_ADMIN_STATUS_FETCH,
  payload,
  onSuccess,
  onError,
});

export const resetEmployerAdminPasswordFetch = (
  payload: IResetEmployerAdminPasswordFetch['payload'],
  onSuccess?: IResetEmployerAdminPasswordFetch['onSuccess'],
  onError?: IResetEmployerAdminPasswordFetch['onError']
): IResetEmployerAdminPasswordFetch => ({
  type: RESET_EMPLOYER_ADMIN_PASSWORD_FETCH,
  payload,
  onSuccess,
  onError,
});

export const employerInventoryListFetch = (): IEmployerInventoryListFetch => ({
  type: EMPLOYER_INVENTORY_LIST_FETCH,
});

export const employerInventoryListSuccess = (
  payload: IEmployerInventoryListSuccess['payload']
): IEmployerInventoryListSuccess => ({
  type: EMPLOYER_INVENTORY_LIST_SUCCESS,
  payload,
});

export const employerFileRetrieveReset = (): IEmployerFileRetrieveReset => ({
  type: EMPLOYER_FILE_RETRIEVE_RESET,
});

export const employerFileRetrieveFetch = (
  payload: IEmployerFileRetrieveFetch['payload']
): IEmployerFileRetrieveFetch => ({
  type: EMPLOYER_FILE_RETRIEVE_FETCH,
  payload,
});

export const employerFileRetrieveSuccess = (
  payload: IEmployerFileRetrieveSuccess['payload']
): IEmployerFileRetrieveSuccess => ({
  type: EMPLOYER_FILE_RETRIEVE_SUCCESS,
  payload,
});

import { useMutation } from 'react-query';
import { API } from 'api';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';
import { IGlobalCutOffPayload } from './shared';

const createGlobalCutOffData = (payload: IGlobalCutOffPayload) => {
  return API.newPost(`api/admin/setting/globalWithdrawalCycle`, payload);
};

export const useCreateGlobalCutOffData = () => {
  const dispatch = useDispatch();

  return useMutation(createGlobalCutOffData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.createdSucessful',
        })
      );
    },
  });
};

import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { API } from 'api';
import { toastPush } from 'modules';

export interface IUpdateSettingConfigurationData {
  payload: {
    otpLimit: number;
    ekycLimit: number;
    otpBypassNumber: string;
    fee: number;
    minWithdrawalPerTransaction: number;
    maxWithdrawalPerTransaction: number;
    maxWithdrawalPerDay: number;
    maxWithdrawalPerMonth: number;
    twoFaWithdrawalPrompt: number;
    globalProductLimit: number;
    suspension: number;
    publicHolidayList: {
      date: string;
      name: string;
    }[];
    blacklistCountryList: string[];
  };
}

const updateSettingConfigurationData = (payload: IUpdateSettingConfigurationData['payload']) => {
  return API.put()('/api/admin/sys/setting/config', payload);
};

export const useUpdateSettingConfigurationData = () => {
  const queryClient = useQueryClient();
  // const dispatch = useDispatch();

  return useMutation(updateSettingConfigurationData, {
    onSuccess: () => {
      // dispatch(
      //   toastPush({
      //     code: 200,
      //     type: 'success',
      //     title: 'SUCCESS',
      //     body: 'toastPush.updatedSuccessfulReflectNextDay',
      //   })
      // );
      return queryClient.invalidateQueries(['settingConfiguration']);
    },
  });
};

import { useMutation } from 'react-query';
import { API } from 'api';
import { useDispatch } from 'react-redux';
import { toastPush } from 'modules';

export interface IEmployeeTransactionHistoryDownloadData {
  payload: {
    page: number;
    id: string;
    download: boolean;
  };
}

const getEmployeeTransactionHistoryDownload = (data: IEmployeeTransactionHistoryDownloadData['payload']) => {
  return API.newGet(`/api/employee/${data.id}/transaction`, data);
};

export const useEmployeeTransactionHistoryDownloadData = () => {
  const dispatch = useDispatch();
  return useMutation(getEmployeeTransactionHistoryDownload, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.downloadReportSuccessPleaseCheckMailbox',
        })
      );
    },
  });
};

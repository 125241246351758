/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { useQuery } from 'react-query';
import { API } from 'api';

export interface IRewardListData {
  payload: {
    page?: number;
    eid?: string;
    uid?: string;
    trxRef?: string;
    trxType?: string;
    mobile?: string;
    from?: number;
    to?: number;
  };
  data: {
    data: any;
    count: number;
  };
}

export const initRewardListPayload: IRewardListData['payload'] = {
  page: 1,
  from: moment().subtract(90, 'days').unix(),
  to: moment().unix(),
};

const getRewardList = (data: IRewardListData['payload']) => {
  return API.get()(`/api/employee/${data.uid}/rewards`, data);
};

export const useRewardListData = (data: IRewardListData['payload']) => {
  return useQuery(['rewards', data], (): any => getRewardList(data), {
    select: (response: IRewardListData['data']) => {
      return response.data;
    },
  });
};

import fileDownload from 'js-file-download';
import { useMutation } from 'react-query';
import { API } from 'api';
import { blobToString, downloadFileByBase64Str, isBase64 } from 'common';

export interface IDownloadFileUpload {
  payload: {
    fileId: string;
    fileName: string;
  };
}

const downloadFileUpload = (payload: IDownloadFileUpload['payload']) => {
  return API.newGetBlob('/api/admin/files/retrieve', {
    ...payload,
    type: 'fileUpload',
  });
};

export const useDownloadFileUpload = () => {
  return useMutation(downloadFileUpload, {
    onSuccess: async (data, payload: IDownloadFileUpload['payload']) => {
      const result = await blobToString(data.data);
      if (isBase64(result) || result.includes('https://')) {
        return downloadFileByBase64Str(result, payload.fileName);
      }
      return fileDownload(data.data, payload.fileName);
    },
  });
};

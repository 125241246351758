/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';
import { ISharedEmployerInfoOrEmployerRequestInfoDataOutput } from '../shared';

interface IEmployerInfoResponse {
  data: {
    eid: string;
    name?: string;
    companyName: string;
    companyLogo: string;
    companyDescription: string;
    companyInformation: {
      bnmCategory: string;
      bnmSubCategory: string;
      businessLicenseExpiryDate: string;
      companyDescription: string;
      companyRegistrationNo: {
        new: string;
        old: string;
      };
      employerType: string;
      gstRegistrationNo: string;
      owner: {
        name: string;
        ic: string;
      }[];
      inAppCompanyLogo?: boolean;
    };
    contactInformation: {
      email: string;
      mailing: {
        addressLine1: string;
        addressLine2: string;
        city: string;
        contactPerson: string;
        country: string;
        postalCode: string;
        state: string;
      };
      mobile: string;
      secondaryEmail: string;
      website: string;
      workPhone: string;
    };
    attachments: {
      companyLogo: string;
      hlbApplicationForm: {
        file: string;
        name: string;
      };
      auditedFinancialStatements: {
        file: string;
        name: string;
      };
      businessRegistrationDocument: {
        file: string;
        name: string;
      };
      letterOfAuthorisation: {
        file: string;
        name: string;
      };
      signedAgreement: {
        file: string;
        name: string;
      };
      ctosReport: {
        file: string;
        name: string;
      };
      others: {
        file: string;
        name: string;
      };
    };
    finance: {
      bankAccountHolderName: string;
      bankAccountNo: string;
      bankName: string;
      masterAccountNo: string;
      masterBankName: string;
      masterAccountHolderName: string;
      masterSwiftCode: string;
      paymentDueDate: string;
      attentionTo: string;
      cifNo: string;
    };
    withdrawal: {
      employeeMonthlyLimitEnabled: boolean;
      ltv?: number;
      dailyCreditLtv?: number;
      monthlyCreditLtv?: number;
      monthlyWithdrawalLtv?: number;
      monthlyLimit?: number;
      limit: {
        min: number;
        max: number;
      };
      withdrawalProhibitedDayForNewlyBindedUser: number;
      withdrawalProhibitedDay: number;
    };
    locations: {
      createdBy: string;
      createdOn: number;
      id: string;
      location: {
        lat: number;
        long: number;
      };
      name: string;
      qr: {
        qrImage: string;
        qrImageTemplate: string;
      };
    }[];
    admin: {
      aid: string;
      loginId: string;
      role: string;
      actId: string;
      status: string;
    }[];
    checkInMethod: string[];
    scheduler?: boolean;
    earning?: string;
    status: string;
    createdOn: number;
    setting: {
      withdrawalCycleType: 'standard' | 'custom' | undefined;
      globalWithdrawalCycleId?: string;
      type?: string;
      actType?: string;
      employeeMovementFile: string;
      lastWithdrawalDate?: number;
      autoWithdrawalEnabled?: boolean;
      autoWithdrawalDayTime?: string[];
      withdrawalDisabled?: boolean;
      employeeMovementFileGenerationDays: string[];
      useOba: boolean;
      customTemplate: {
        onboard: {
          file: string;
          source: string;
          fileList: { name: string; status: string; url: string }[];
          name: string;
        };
        onboardAgent: {
          file: string;
          source: string;
          fileList: { name: string; status: string; url: string }[];
          name: string;
        };
      };
      commissionRequiresAuthentication: boolean;
      deductionFileGenerationDay: any[];
      requiresDeductionFile: boolean | null;
      deductionInvoiceGenerationDate?: any[];
      invoiceFileRecipient: {
        to: string;
        cc: string;
      };
      employeeMovementFileRecipient: {
        to: string;
        cc: string;
      };
      deductionFileRecipient: {
        to: string;
        cc: string;
      };
      withdrawalCycle: {
        tag: string;
        dateFrom: number;
        dateTo: number;
      }[];
      serviceProvider?: string;
      isFeeAbsorbed: boolean;
      isKickStartEarningEnabled: boolean;
      kickStartEarningAmount?: number;
      bulkImportApprovalFlag: boolean;
      customWithdrawalSettings: {
        customWithdrawalSettingsEnabled: boolean;
        txnMaxWithdrawal: number;
        txnMinWithdrawal: number;
        dailyMaxWithdrawal: number;
        monthlyMaxWithdrawal: number;
      };
      upfrontFeeDeductEnabled?: boolean;
    };
    cycleHistoryData?: { id: string; tag: any; dateFrom: number; dateTo: number }[];
    cycles?: { tag: any; dateFrom: any; dateTo: any }[];
    cycle1?: { tag?: string; date?: string };
    cycle2?: { tag?: string; date?: string };
    location: {
      id: string;
      lat: number;
      long: number;
    };
    ableToImmediateCutOff?: boolean;
    cutoffCycleDateTo?: number;
  };
}

export const getEmployerInfo = (eid: string) => {
  return API.get()(`/api/employer/${eid}`);
};

export const useEmployerInfoData = (eid: string) => {
  return useQuery(['employer', eid], (): any => getEmployerInfo(eid), {
    select: (response: IEmployerInfoResponse) => {
      const output = response.data;
      const res: ISharedEmployerInfoOrEmployerRequestInfoDataOutput = {
        ...output,
        companyInformation: {
          ...output.companyInformation,
          status: output.status,
          name: output.name,
          createdOn: output.createdOn,
          companyName: output.companyName,
          website: output.contactInformation?.website,
        },
        contactInformation: {
          ...output.contactInformation,
        },
        additionalInformation: {
          bankName: output.finance?.bankName,
          bankAccountNo: output.finance?.bankAccountNo,
          bankAccountHolderName: output.finance?.bankAccountHolderName,
          masterAccountNo: output.finance?.masterAccountNo,
          masterBankName: output.finance?.masterBankName,
          masterAccountHolderName: output.finance?.masterAccountHolderName,
          masterSwiftCode: output.finance?.masterSwiftCode,
          paymentDueDate: output.finance?.paymentDueDate,
          attentionTo: output.finance?.attentionTo,
          cifNo: output.finance.cifNo,
          gstRegistrationNo: output.companyInformation?.gstRegistrationNo,
          workPhone: output.contactInformation?.workPhone,
          mobile: output.contactInformation?.mobile,
          email: output.contactInformation?.email,
          secondaryEmail: output.contactInformation?.secondaryEmail,
          mailingContactPerson: output.contactInformation?.mailing?.contactPerson,
          mailingAddressLine1: output.contactInformation?.mailing?.addressLine1,
          mailingAddressLine2: output.contactInformation?.mailing?.addressLine2,
          mailingCity: output.contactInformation?.mailing?.city,
          mailingState: output.contactInformation?.mailing?.state,
          mailingPostalCode: output.contactInformation?.mailing?.postalCode,
          mailingCountry: output.contactInformation?.mailing?.country,
          useOba: output.setting?.useOba,
        },
        attachments: {
          ...output.attachments,
          owners: output.companyInformation?.owner,
        },
        setting: {
          ...output.setting,
          earning: output.earning,
          ltv: output.withdrawal?.ltv,
          customTemplate: {
            onboard: output.setting.customTemplate?.onboard,
            onboardAgent: output.setting.customTemplate?.onboardAgent,
          },
          dailyCreditLtv: output.withdrawal?.dailyCreditLtv,
          monthlyCreditLtv: output.withdrawal?.monthlyCreditLtv,
          monthlyWithdrawalLtv: output.withdrawal?.monthlyWithdrawalLtv,
          employeeMonthlyLimitEnabled: output.withdrawal?.employeeMonthlyLimitEnabled,
          withdrawalMaxLimit: output.withdrawal?.limit?.max,
          monthlyLimit: output.withdrawal?.monthlyLimit,
          autoWithdrawalDayTime: output.setting.autoWithdrawalDayTime,
          autoWithdrawalEnabled: output.setting.autoWithdrawalEnabled,
          withdrawalDisabled: output.setting.withdrawalDisabled,
          checkInMethod: output.checkInMethod,
          scheduler: output.scheduler,
          useCheckInOutFlag: output.checkInMethod && output.checkInMethod.length > 0 ? '1' : '0',
          admin: output.admin,
          cycles: output.cycles ?? [],
          cycle1: output.cycle1,
          cycle2: output.cycle2,
          historyWithdrawalCycle: output.cycleHistoryData,
          withdrawalProhibitedDayForNewlyBindedUser: output.withdrawal.withdrawalProhibitedDayForNewlyBindedUser,
          withdrawalProhibitedDay: output.withdrawal.withdrawalProhibitedDay,
          bulkImportApprovalFlag: output.setting?.bulkImportApprovalFlag ?? true,
          ableToImmediateCutOff: output.ableToImmediateCutOff,
          cutoffCycleDateTo: output.cutoffCycleDateTo,
          customWithdrawalSettingsEnabled: output.setting?.customWithdrawalSettings?.customWithdrawalSettingsEnabled,
          txnMaxWithdrawal: output.setting?.customWithdrawalSettings?.txnMaxWithdrawal,
          txnMinWithdrawal: output.setting?.customWithdrawalSettings?.txnMinWithdrawal,
          monthlyMaxWithdrawal: output.setting?.customWithdrawalSettings?.monthlyMaxWithdrawal,
          dailyMaxWithdrawal: output.setting?.customWithdrawalSettings?.dailyMaxWithdrawal,
          upfrontFeeDeductEnabled: output.setting?.upfrontFeeDeductEnabled ?? false,
        },
      };

      return res;
    },
  });
};

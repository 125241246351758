import { isUndefinedOrNullOrEmpty } from 'common';
import { useQuery } from 'react-query';
import { API } from '../../api';

export interface IEmployerSelectData {
  payload: {
    paywatchEarningsDisabled?: boolean;
    commissionEnabled?: string;
    employerId?: string;
  };
  data: {
    data: [];
  };
}

const getEmployerSelectData = (payload: IEmployerSelectData['payload']) => {
  return API.get()('/api/employer/select', {
    paywatchEarningsDisabled: payload.paywatchEarningsDisabled,
    commissionEnabled: payload.commissionEnabled,
  });
};

export const useEmployerSelectData = (payload: IEmployerSelectData['payload']) => {
  return useQuery(['employerSelect'], () => getEmployerSelectData(payload), {
    enabled: isUndefinedOrNullOrEmpty(payload.employerId),
    select: (response: IEmployerSelectData['data']) => {
      return response.data;
    },
  });
};

import { useQuery } from 'react-query';
import { API } from '../../../api';
import { isUndefinedOrNullOrEmpty } from '../../../common';

export interface IEmployeeOutletData {
  payload: {
    eid?: string;
    ebid?: string;
    page?: number;
  };
  data: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    data: any;
    count: number;
  };
}

export const initEmployeeOutletPayload: IEmployeeOutletData['payload'] = {
  page: 1,
};

const getEmployeeOutletsData = (data: IEmployeeOutletData['payload']) => {
  return API.get()('/api/admin/employee/outlets', data);
};

export const useGetEmployeeOutletData = (data: IEmployeeOutletData['payload']) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return useQuery(['employeeOutlet', data], (): any => getEmployeeOutletsData(data), {
    enabled: !isUndefinedOrNullOrEmpty(data.eid) && !isUndefinedOrNullOrEmpty(data.ebid),
    select: (response: IEmployeeOutletData['data']) => {
      return response.data;
    },
  });
};

import { useMutation } from 'react-query';
import { API } from 'api';
import { useDispatch } from 'react-redux';
import { toastPush } from 'modules';

export interface IEmploymentProfileListDownloadData {
  payload: {
    employeeName?: string;
    mobile?: string;
    eid?: string;
    employeeId?: string;
    idNumber?: string;
    page: number;
    download?: boolean;
  };
}

const getEmploymentProfileListDownload = (data: IEmploymentProfileListDownloadData['payload']) => {
  return API.newGet('/api/admin/employment/profile/list', data);
};

export const useEmploymentProfileListDownloadData = () => {
  const dispatch = useDispatch();
  return useMutation(getEmploymentProfileListDownload, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.downloadReportSuccessPleaseCheckMailbox',
        })
      );
    },
  });
};

import { useMutation } from 'react-query';
import { API } from 'api';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';
import moment from 'moment';

export enum AutoReconciliationProviderCode {
  XNDT = 'XNDT',
  XNDTID = 'XNDTID',
  XNDTMY = 'XNDTMY',
  PMTI = 'PMTI',
  BUKOID = 'BUKOID',
  UOBI = 'UOBI',
  FINANCEMY = 'FINANCEMY',
  FINANCEID = 'FINANCEID',
  FINANCEPH = 'FINANCEPH',
}

export interface AddAutoReconciliationData {
  payload: {
    providerCode: AutoReconciliationProviderCode;
    date: moment.Moment | null;
    parentId?: string;
  };
}

const addAutoReconciliationData = (payload: AddAutoReconciliationData['payload']) => {
  const { providerCode, date, ...rest } = payload;
  switch (providerCode) {
    case AutoReconciliationProviderCode.XNDT:
      return API.newPost('/api/admin/v1/scheduler/xendit/auto-reconciliation', {
        date: moment(date).format('YYYY-MM-DD'),
        ...rest,
      });
    case AutoReconciliationProviderCode.XNDTID:
      return API.newPost('/api/admin/v1/scheduler/xendit-id/auto-reconciliation', {
        date: moment(date).format('YYYY-MM-DD'),
        ...rest,
      });
    case AutoReconciliationProviderCode.XNDTMY:
      return API.newPost('/api/admin/v1/scheduler/xendit-my/auto-reconciliation', {
        date: moment(date).format('YYYY-MM-DD'),
        ...rest,
      });
    case AutoReconciliationProviderCode.PMTI:
      return API.newPost('/api/admin/v1/scheduler/permata/auto-reconciliation', {
        date: moment(date).format('YYYY-MM-DD'),
        ...rest,
      });
    case AutoReconciliationProviderCode.BUKOID:
      return API.newPost('/api/admin/v1/scheduler/bukopin/auto-reconciliation', {
        date: moment(date).format('YYYY-MM-DD'),
        ...rest,
      });
    case AutoReconciliationProviderCode.UOBI:
      return API.newPost('/api/admin/v1/scheduler/uob/auto-reconciliation', {
        date: moment(date).format('YYYY-MM-DD'),
        ...rest,
      });
    case AutoReconciliationProviderCode.FINANCEMY:
      return API.newPost('/api/admin/v1/scheduler/finance/auto-reconciliation', {
        date: moment(date).format('YYYY-MM-DD'),
        ...rest,
      });
    case AutoReconciliationProviderCode.FINANCEID:
      return API.newPost('/api/admin/v1/scheduler/finance/auto-reconciliation', {
        date: moment(date).format('YYYY-MM-DD'),
        ...rest,
      });
    case AutoReconciliationProviderCode.FINANCEPH:
      return API.newPost('/api/admin/v1/scheduler/finance/auto-reconciliation', {
        date: moment(date).format('YYYY-MM-DD'),
        ...rest,
      });
    default:
      throw new Error('Invalid provider code');
  }
};

export const useAddAutoReconciliationData = () => {
  const dispatch = useDispatch();

  return useMutation(addAutoReconciliationData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.retryReconciliationSuccess',
        })
      );
    },
  });
};

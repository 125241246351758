/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';
import { SettlementStatus } from 'common';

export interface IInvoiceListData {
  payload: {
    page?: number;
    from?: number;
    to?: number;
    eid?: string;
    status?: SettlementStatus;
  };
  data: {
    data: any;
    count: number;
  };
}

export const initInvoiceListPayload: IInvoiceListData['payload'] = {
  page: 1,
};

const getInvoiceListData = (data: IInvoiceListData['payload']) => {
  return API.get()('/api/admin/invoices/retrieve', data);
};

export const useInvoiceListData = (data: IInvoiceListData['payload']) => {
  return useQuery(['invoices', data], (): any => getInvoiceListData(data), {
    select: (response: IInvoiceListData['data']) => {
      return response.data;
    },
  });
};

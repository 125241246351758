import { useQuery } from 'react-query';
import { API } from 'api';
import { isUndefinedOrNullOrEmpty } from 'common';

export interface IEmployeeListData {
  payload: {
    name?: string;
    mobile?: string;
    idNo?: string;
    eid?: string;
    staffId?: string;
    userId?: string;
    page: number;
    permission: string;
  };
  data: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    data: any;
    count: number;
  };
}

export const initEmployeeListPayload: IEmployeeListData['payload'] = {
  page: 1,
  permission: '',
};

const getEmployeeList = (data: IEmployeeListData['payload']) => {
  if (isUndefinedOrNullOrEmpty(data.permission)) return undefined;

  const url = data.permission === 'EMPLOYEE_LIST_READ' ? '/api/employee' : '/api/employer/employment/list';

  return API.newGet(url, data);
};

export const useEmployeeListData = (data: IEmployeeListData['payload']) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return useQuery(['employees', data], (): any => getEmployeeList(data), {
    select: (response: IEmployeeListData['data']) => {
      return response.data;
    },
  });
};

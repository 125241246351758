import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { API } from 'api';
import { getLatestQuery } from 'common';
import { toastPush } from 'modules';

export interface IUpdatePaymentMerchantMdrData {
  payload: {
    id: string;
    chargeType: 'amount' | 'percentage';
    mdr: number;
  };
}

const updatePaymentMerchantMdrData = (payload: IUpdatePaymentMerchantMdrData['payload']) => {
  return API.put()(`/api/admin/merchantProductMdr/${payload.id}`, payload);
};

export const useUpdatePaymentMerchantMdr = () => {
  const queryClient = useQueryClient();
  const queryArr = queryClient.getQueriesData('paymentMerchantList');
  const res = getLatestQuery(queryArr, queryClient);
  const dispatch = useDispatch();

  return useMutation(updatePaymentMerchantMdrData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.updatedSuccessfulReflectNextDay',
        })
      );
      return queryClient.invalidateQueries('paymentMerchantList', res.queryParams);
    },
  });
};

import fileDownload from 'js-file-download';
import { useMutation } from 'react-query';
import { API } from 'api';
import { blobToString, isBase64, downloadFileByBase64Str } from 'common';

export interface IDownloadEmployeeInfoUpdateFile {
  payload: {
    // bank: string;
    fileId: string;
    fileName: string;
    type: string;
  };
}

const downloadEmployeeInfoUpdateFile = (payload: IDownloadEmployeeInfoUpdateFile['payload']) => {
  return API.newGetBlob('/api/admin/files/retrieve', payload);
};

export const useDownloadEmployeeInfoUpdateFile = () => {
  return useMutation(downloadEmployeeInfoUpdateFile, {
    onSuccess: async (data, payload: IDownloadEmployeeInfoUpdateFile['payload']) => {
      const result = await blobToString(data.data);
      if (isBase64(result) || result.includes('https://')) {
        return downloadFileByBase64Str(result, payload.fileName);
      }
      return fileDownload(data.data, payload.fileName);
    },
  });
};

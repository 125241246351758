import { useMutation } from 'react-query';
import { API } from 'api';
import { useDispatch } from 'react-redux';
import { toastPush } from 'modules';

export interface ISingleConsentDownloadData {
  payload: {
    eid?: string;
    uid: string;
    category?: string;
  };
}

const getSingleConsentDownload = (data: ISingleConsentDownloadData['payload']) => {
  return API.post()('/api/employee/download/consent/single', data);
};

export const useSingleConsentDownloadData = () => {
  const dispatch = useDispatch();
  return useMutation(getSingleConsentDownload, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.downloadReportSuccessPleaseCheckMailbox',
        })
      );
    },
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';
import { useSelector } from 'react-redux';
import { RootState } from 'modules';
import { isUndefinedOrNullOrEmpty } from 'common';

export interface IRoleSelectData {
  payload: {
    isEmployerRole: boolean;
  };
  data: {
    data: any;
  };
}

const getRoleSelectData = (data: IRoleSelectData['payload']) => {
  return API.newGet('/api/admin/roles/list', data);
};

export const useRoleSelectData = (data: IRoleSelectData['payload']) => {
  // const { eid } = useSelector((userState: RootState) => userState.user);
  return useQuery(['roleSelect', data], (): any => getRoleSelectData(data), {
    // enabled: isUndefinedOrNullOrEmpty(eid),
    select: (response: IRoleSelectData['data']) => {
      return response.data.data;
    },
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQueryClient } from 'react-query';
import { API } from 'api';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';

interface IUpdateBankConfigurationData {
  payload: {
    bankStatus: any;
  };
}

const updateBankConfigurationData = (payload: IUpdateBankConfigurationData['payload']) => {
  return API.newPut(`/api/admin/sys/bank/config`, payload);
};

export const useUpdateBankConfigurationData = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(updateBankConfigurationData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.updatedSuccessful',
        })
      );
      return queryClient.invalidateQueries(['bankConfigurations']);
    },
  });
};

import { useMutation } from 'react-query';
import { API } from 'api';
import { toastPush } from 'modules';
import { useDispatch } from 'react-redux';
import { IUpdateGlobalCutOffPayload } from './shared';

const updateGlobalCutOffData = (payload: IUpdateGlobalCutOffPayload) => {
  return API.newPut(`api/admin/setting/globalWithdrawalCycle/${payload.id}`, payload);
};

export const useUpdateGlobalCutOffData = () => {
  const dispatch = useDispatch();

  return useMutation(updateGlobalCutOffData, {
    onSuccess: () => {
      dispatch(
        toastPush({
          code: 200,
          type: 'success',
          title: 'SUCCESS',
          body: 'toastPush.updatedSuccessful',
        })
      );
    },
  });
};

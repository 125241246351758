import { useQuery } from 'react-query';
import { API } from 'api';

export interface IBulkImportHistoryListData {
  payload: {
    page?: number;
    eid?: string;
    buqid?: string;
    fileType?: string;
    actionType?: string;
    employeeId?: string;
    idNumber?: string;
    status?: string;
    dateFrom?: number;
    dateTo?: number;
  };
  data: {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    data: any;
    count: number;
  };
}

export const initBulkImportHistoryListPayload: IBulkImportHistoryListData['payload'] = {
  page: 1,
};

const getBulkImportHistoryList = (data: IBulkImportHistoryListData['payload']) => {
  return API.get()('/api/admin/bulkImportHistory/retrieve', data);
};

export const useBulkImportHistoryListData = (data: IBulkImportHistoryListData['payload']) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return useQuery(['bulkImportHistory', data], (): any => getBulkImportHistoryList(data), {
    select: (response: IBulkImportHistoryListData['data']) => {
      return response.data;
    },
  });
};

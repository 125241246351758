/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';
import { ISharedEmployerInfoOrEmployerRequestInfoDataOutput } from '../shared';

interface IEmployerRequestInfoResponse {
  data: {
    id: string;
    createdBy: string;
    createdOn: number;
    status: string;
    type: string;
    fieldUpdated: string[];
    reason: string;
    updatedBy?: {
      aid: string;
      name: string;
      email: string;
      role: string;
      createdOn: number;
      status: string;
    }[];
    data: {
      eid: string;
      name?: string;
      companyName: string;
      companyLogo: string;
      companyInformation: {
        bnmCategory: string;
        bnmSubCategory: string;
        businessLicenseExpiryDate: string;
        companyDescription: string;
        companyRegistrationNo: {
          new: string;
          old: string;
        };
        employerType: string;
        gstRegistrationNo: string;
        owner: {
          name: string;
          ic: string;
        }[];
        inAppCompanyLogo: boolean;
      };
      contactInformation: {
        email: string;
        mailing: {
          addressLine1: string;
          addressLine2: string;
          city: string;
          contactPerson: string;
          country: string;
          postalCode: string;
          state: string;
        };
        mobile: string;
        secondaryEmail: string;
        website: string;
        workPhone: string;
      };
      attachments: {
        companyLogo: string;
        hlbApplicationForm: {
          file: string;
          name: string;
        };
        auditedFinancialStatements: {
          file: string;
          name: string;
        };
        businessRegistrationDocument: {
          file: string;
          name: string;
        };
        letterOfAuthorisation: {
          file: string;
          name: string;
        };
        signedAgreement: {
          file: string;
          name: string;
        };
        ctosReport: {
          file: string;
          name: string;
        };
        others: {
          file: string;
          name: string;
        };
      };
      finance: {
        attentionTo: string;
        bankAccountHolderName: string;
        bankAccountNo: string;
        bankName: string;
        masterAccountNo: string;
        masterBankName: string;
        masterAccountHolderName: string;
        masterSwiftCode: string;
        paymentDueDate: string;
        cifNo: string;
      };
      withdrawal: {
        employeeMonthlyLimitEnabled: boolean;
        ltv?: number;
        dailyCreditLtv?: number;
        monthlyCreditLtv?: number;
        monthlyWithdrawalLtv?: number;
        monthlyLimit?: number;
        limit: {
          min: number;
          max: number;
        };
        withdrawalProhibitedDayForNewlyBindedUser: number;
        withdrawalProhibitedDay: number;
      };
      locations: {
        createdBy: string;
        createdOn: number;
        id: string;
        location: {
          lat: number;
          long: number;
        };
        name: string;
        qr: {
          qrImage: string;
          qrImageTemplate: string;
        };
      }[];
      admin: {
        aid: string;
        loginId: string;
        role: string;
        actId: string;
        status: string;
      }[];
      checkInMethod: string[];
      scheduler?: boolean;
      earning?: string;
      status: string;
      createdOn: number;
      setting: {
        isFeeAbsorbed: boolean;
        isKickStartEarningEnabled: boolean;
        kickStartEarningAmount?: number;
        withdrawalCycleType: 'standard' | 'custom' | undefined;
        globalWithdrawalCycleId?: string;
        type?: string;
        actType?: string;
        employeeMovementFile: string;
        lastWithdrawalDate?: number;
        employeeMovementFileGenerationDays: string[];
        commissionRequiresAuthentication: boolean;
        useOba: boolean;
        customTemplate: {
          onboard: {
            file: string;
            name: string;
            fileList: { name: string; status: string; url: string }[];
            source: string;
          };
          onboardAgent: {
            file: string;
            name: string;
            fileList: { name: string; status: string; url: string }[];
            source: string;
          };
        };
        deductionFileGenerationDay: any[];
        requiresDeductionFile: boolean | null;
        deductionInvoiceGenerationDate?: any[];
        invoiceFileRecipient: {
          to: string;
          cc: string;
        };
        employeeMovementFileRecipient: {
          to: string;
          cc: string;
        };
        deductionFileRecipient: {
          to: string;
          cc: string;
        };
        withdrawalCycle: {
          tag: string;
          dateFrom: number;
          dateTo: number;
        }[];
        bulkImportApprovalFlag: boolean;
        customGrantingTimeDayEnabled?: boolean;
        customGrantingTime?: string;
        customGrantingDay?: string;
        customWithdrawalSettings: {
          customWithdrawalSettingsEnabled: boolean;
          txnMinWithdrawal: number;
          txnMaxWithdrawal: number;
          dailyMaxWithdrawal: number;
          monthlyMaxWithdrawal: number;
        };
        deductionFileType: string | null | undefined;
      };
    };
  };
}

const getEmployerRequestInfo = (eid: string) => {
  return API.get()(`/api/admin/employerRequest/${eid}`);
};

export const useEmployerRequestInfoData = (eid: string) => {
  return useQuery(['employerRequest', eid], (): any => getEmployerRequestInfo(eid), {
    select: (response: IEmployerRequestInfoResponse) => {
      const outputObj = response.data;
      const output = outputObj.data;
      const res: ISharedEmployerInfoOrEmployerRequestInfoDataOutput = {
        ...output,
        type: outputObj.type,
        id: outputObj.id,
        companyInformation: {
          status: outputObj.status,
          name: output.name,
          createdOn: outputObj.createdOn,
          companyName: output.companyName,
          website: output.contactInformation?.website,
          reason: outputObj.reason,
          ...output.companyInformation,
        },
        contactInformation: {
          ...output.contactInformation,
        },
        additionalInformation: {
          bankName: output.finance?.bankName,
          bankAccountNo: output.finance?.bankAccountNo,
          bankAccountHolderName: output.finance?.bankAccountHolderName,
          masterAccountNo: output.finance?.masterAccountNo,
          masterBankName: output.finance?.masterBankName,
          masterAccountHolderName: output.finance?.masterAccountHolderName,
          masterSwiftCode: output.finance?.masterSwiftCode,
          paymentDueDate: output.finance?.paymentDueDate,
          attentionTo: output.finance?.attentionTo,
          cifNo: output.finance.cifNo,
          gstRegistrationNo: output.companyInformation?.gstRegistrationNo,
          workPhone: output.contactInformation?.workPhone,
          mobile: output.contactInformation?.mobile,
          email: output.contactInformation?.email,
          secondaryEmail: output.contactInformation?.secondaryEmail,
          mailingContactPerson: output.contactInformation?.mailing?.contactPerson,
          mailingAddressLine1: output.contactInformation?.mailing?.addressLine1,
          mailingAddressLine2: output.contactInformation?.mailing?.addressLine2,
          mailingCity: output.contactInformation?.mailing?.city,
          mailingState: output.contactInformation?.mailing?.state,
          mailingPostalCode: output.contactInformation?.mailing?.postalCode,
          mailingCountry: output.contactInformation?.mailing?.country,
          useOba: output.setting?.useOba,
        },
        attachments: {
          ...output.attachments,
          owners: output.companyInformation?.owner,
        },
        setting: {
          ...output.setting,
          customTemplate: {
            onboard: output.setting.customTemplate?.onboard,
            onboardAgent: output.setting.customTemplate?.onboardAgent,
          },
          earning: output.earning,
          ltv: output.withdrawal?.ltv,
          dailyCreditLtv: output.withdrawal?.dailyCreditLtv,
          monthlyCreditLtv: output.withdrawal?.monthlyCreditLtv,
          monthlyWithdrawalLtv: output.withdrawal?.monthlyWithdrawalLtv,
          monthlyLimit: output.withdrawal?.monthlyLimit,
          checkInMethod: output.checkInMethod,
          employeeMonthlyLimitEnabled: output.withdrawal?.employeeMonthlyLimitEnabled,
          withdrawalMaxLimit: output.withdrawal?.limit?.max,
          // useCheckInOutFlag: output.checkInMethod && output.checkInMethod.length.toString(),
          scheduler: output.scheduler,
          useCheckInOutFlag: output.checkInMethod && output.checkInMethod.length > 0 ? '1' : '0',
          admin: output.admin,
          cycles: output.setting.withdrawalCycle,
          customWithdrawalSettingsEnabled: output.setting.customWithdrawalSettings?.customWithdrawalSettingsEnabled,
          txnMaxWithdrawal: output.setting.customWithdrawalSettings?.txnMaxWithdrawal,
          txnMinWithdrawal: output.setting.customWithdrawalSettings?.txnMinWithdrawal,
          dailyMaxWithdrawal: output.setting.customWithdrawalSettings?.dailyMaxWithdrawal,
          monthlyMaxWithdrawal: output.setting.customWithdrawalSettings?.monthlyMaxWithdrawal,
        },
        remarks: {
          companyDescription: output.companyInformation?.companyDescription,
        },
        fieldUpdated: outputObj.fieldUpdated,
        updatedBy: outputObj.updatedBy,
      };

      return res;
    },
  });
};

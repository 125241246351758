/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';

export interface IEmploymentSalaryCreditInfoResponse {
  data: {
    min: number;
    max: number;
    txnLimit: number;
    creditBalance: number;
    limitUsed: {
      userMonthly: number;
      userDaily: number;
      employerMonthly: number;
      app: number;
    };
    limits: {
      userMonthly: number;
      employer: number;
      appMonthly: number;
      userDaily: number;
      userPerTrans: number;
      employmentMonthly: number;
    };
    creditDetails: {
      creditEarned: number;
      creditLimit: number;
    };
    withdrawalFee: number;
    needOtpLimit: number;
    lastWithdrawalDate: string;
    isBlackOutPeriod: boolean;
    isBankEnabled: boolean;
    appWihdrawalLimitData: {
      id: string;
      displayName: string;
      withdrawalLimit: {
        min: number;
        max: number;
        twoFAPrompt: number;
        daily: number;
        monthly: number;
      };
      withdrawalSelectableAmounts: number[];
      monthlyProductlLimitUsed: number;
      monthlyProductlLimit: number;
      checkPendingWithdrawal: boolean;
      withdrawalEnabled: boolean;
    };
    isFeesAbsorbed: boolean;
    reward: {
      bonusPoints: number;
    };
    rewardOption: string[];
    bankInAppDisplayName: string;
    withdrawalCycleId: string;
  };
  payload: {
    eid: string;
    id: string;
    ebid: string;
  };
}

const getEmploymentSalaryCreditInfo = (data: IEmploymentSalaryCreditInfoResponse['payload']) => {
  return API.newGet(`/api/admin/withdrawal/employee/limit`, {
    eid: data.eid,
    ebid: data.ebid,
  });
};

export const useEmploymentSalaryCreditInfoData = (data: IEmploymentSalaryCreditInfoResponse['payload']) => {
  return useQuery(
    [
      'employmentSalaryCreditRecord',
      {
        id: data.id,
        eid: data.eid,
        ebid: data.ebid,
      },
    ],
    (): any => getEmploymentSalaryCreditInfo(data),
    {
      // enabled: !!(data.id && data.ebid && data.eid),
      select: (response: IEmploymentSalaryCreditInfoResponse) => {
        return response.data;
      },
    }
  );
};

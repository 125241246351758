export * from './useFileUploadList';
export * from './useAddFileUpload';
export * from './useDownloadFileUpload';
export * from './useUpdateFileUpload';

export enum tempFileUploadPermissionMapping {
  'TEMP_FILE_UPLOAD_OFFBOARDING_WRITE' = 'offboarding',
  'TEMP_FILE_UPLOAD_ONBOARDING_WRITE' = 'onboarding',
  'TEMP_FILE_UPLOAD_LEAVEMANGEMENT_WRITE' = 'leavemanagement',
  'TEMP_FILE_UPLOAD_ATTENDENCE_WRITE' = 'attendance',
  'TEMP_FILE_UPLOAD_SUSPENSION_WRITE' = 'suspension',
  'TEMP_FILE_UPLOAD_ONBOARDINGAGENTS_WRITE' = 'onboardingagents',
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';
import { isUndefinedOrNullOrEmpty } from 'common';
import { ISharedMerchantInfoOutput } from '../shared';

interface IMerchantInfoResponse {
  data: ISharedMerchantInfoOutput;
}

const getMerchantInfo = (mid: string) => {
  return API.get()(`/api/admin/merchant/${mid}`);
};

export const useMerchantInfoData = (mid: string) => {
  return useQuery(['merchant', mid], (): any => getMerchantInfo(mid), {
    enabled: !isUndefinedOrNullOrEmpty(mid),
    select: (response: IMerchantInfoResponse) => {
      return response.data;
    },
  });
};

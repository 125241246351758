import { useQuery } from 'react-query';
import { isUndefinedOrNullOrEmpty } from 'common';
import { API } from '../../api';

export interface IWorkRecordListData {
  payload: {
    remark?: string;
    id?: string;
    page: number;
  };
  data: {
    data?: {
      id: string;
      eid: string;
      file: string;
      fileName: string;
      status: string;
      type: string;
      createdOn: number;
      createdBy: string;
    }[];
    count: number;
  };
}

const workRecordListData = (payload: IWorkRecordListData['payload']) => {
  return API.newGet(`/api/admin/workrecord/retrieve`, payload).then((res) => res.data);
};

export const useWorkRecordListData = (payload: IWorkRecordListData['payload']) => {
  return useQuery(['workRecordList', payload], () => workRecordListData(payload), {
    enabled: !isUndefinedOrNullOrEmpty(payload.page),
    select: (response: IWorkRecordListData['data']) => {
      return response;
    },
  });
};

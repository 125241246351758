/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { API } from 'api';
import moment from 'moment';
import { isUndefinedOrNullOrEmpty } from 'common';

export interface IWithdrawalStatisticsData {
  payload: {
    type: string;
    dateFrom: number;
    dateTo: number;
    eid: string;
  };
  data: {
    data: any;
    currentCycle: {
      tag: string;
      date: string;
    };
    upcomingCycle: {
      tag: string;
      date: string;
    };
    totalRegisterUserCount: number;
    daysRemaining: number;
  };
}

export const initWithdrawalStatisticsPayload: IWithdrawalStatisticsData['payload'] = {
  type: 'daily',
  dateFrom: moment().subtract(7, 'days').startOf('day').unix(),
  dateTo: moment().startOf('day').endOf('day').unix(),
  eid: '',
};

const getWithdrawalStatisticsData = (payload: IWithdrawalStatisticsData['payload']) => {
  return API.get()('/api/admin/statistics/employee', payload);
};

export const useWithdrawalStatisticsData = (payload: IWithdrawalStatisticsData['payload']) => {
  return useQuery(['withdrawalStatistics'], (): any => getWithdrawalStatisticsData(payload), {
    enabled: isUndefinedOrNullOrEmpty(payload.eid),
    select: (response: IWithdrawalStatisticsData['data']) => {
      return response.data;
    },
  });
};

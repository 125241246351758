import { useQuery } from 'react-query';
import { API } from '../../../api';

export interface ISettingConfigurationInfoResponse {
  data: {
    otpLimit: number;
    ekycLimit: number;
    fee: number;
    minWithdrawalPerTransaction: number;
    maxWithdrawalPerTransaction: number;
    maxWithdrawalPerDay: number;
    maxWithdrawalPerMonth: number;
    twoFaWithdrawalPrompt: number;
    globalProductLimit: number;
    suspension: number;
    otpBypassNumber?: string;
    publicHolidayList: {
      date: string;
      name: string;
    }[];
    blacklistCountryList: {
      code: string;
      code3: string;
      name: string;
      number: string;
    }[];
  };
}

const getSettingConfigurationInfo = () => {
  return API.newGet(`/api/admin/sys/setting/config`);
};

export const useSettingConfigurationInfo = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQuery(['settingConfiguration'], (): any => getSettingConfigurationInfo(), {
    select: (res: ISettingConfigurationInfoResponse) => {
      return res.data;
    },
  });
};

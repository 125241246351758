import { useQuery } from 'react-query';
import { API } from 'api';
import { payoutsQuery } from './graphql';

export interface IPayoutStatusListData {
  payload: {
    page?: number;
    paymentToBankId?: string;
    status?: string;
    uid?: string;
    serviceProviderCode?: string;
    startFrom?: string;
    startTo?: string;
    id?: string;
  };
  response: {
    data: {
      payouts: {
        edges: {
          node: {
            id: string;
            metadata?: { uid: string };
            reconciliationRemark: string;
            reconciliationId: string;
            provider: { code: string };
            latestAuditLog: { paywatchErrorCode: string };
            paymentToBankId: string;
            financeReconciliationId: string;
            financeReconciliationRemark: string;
          };
        }[];
        totalCount: number;
      };
    };
  };
}

export const initPayoutStatusListPayload: IPayoutStatusListData['payload'] = {
  page: 1,
};

const getPayoutStatusListData = async (payload: IPayoutStatusListData['payload']) => {
  const { uid, paymentToBankId, id, status, startFrom, startTo, serviceProviderCode } = payload;
  const limit = 10;
  const page = Number(payload.page) ? Number(payload.page) : 1;
  const variables = {
    filters: {
      paymentToBankId,
      id,
      status,
      createdAt: startFrom && startTo ? { between: [startFrom, startTo] } : null,
      provider: serviceProviderCode ? { code: serviceProviderCode } : null,
      metadata: uid ? { uid } : null,
    },
    sorts: { createdAt: 'DESC' },
  };

  const input = {
    query: payoutsQuery.loc?.source.body,
    variables: { ...variables, offset: page === 1 ? 0 : (page - 1) * limit },
  };

  return API.newPost('/api/admin/graphql', input).then((res) => res.data);
};

export const usePayoutStatusListData = (data: IPayoutStatusListData['payload']) => {
  return useQuery(['payoutStatusList', data], () => getPayoutStatusListData(data), {
    select: (response: IPayoutStatusListData['response']) => {
      const payouts = response?.data?.payouts;
      return {
        data: payouts?.edges?.map(
          (edge: IPayoutStatusListData['response']['data']['payouts']['edges'][0]) => edge.node
        ),
        count: payouts?.totalCount,
      };
    },
  });
};

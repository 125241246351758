import { useQuery } from 'react-query';
import { API } from 'api';
import { isUndefinedOrNullOrEmpty } from '../../../common';

export interface IEmployeeEkycImageResponse {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: any;
}

const getEmployeeEkycImage = (id: string) => {
  return API.newGetBlob('/api/admin/files/retrieve', {
    fileId: id,
    type: 'ekyc',
  });
};

export const useEmployeeEkycImageData = (id: string) => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return useQuery(['employeeEkycImage', id], (): any => getEmployeeEkycImage(id), {
    enabled: !isUndefinedOrNullOrEmpty(id),
    select: (response: IEmployeeEkycImageResponse) => {
      return URL.createObjectURL(response.data);
    },
  });
};
